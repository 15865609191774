import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SkillMapDialogWithButton, { SkillMapDialogWithButtonProps } from "../SkillMapDialogWithButton/SkillMapDialogWithButton";
import SkillTag, { SkillTagProps } from "../SkillTag/SkillTag";
import Subsection from "../Subsection/Subsection";

const StyledTagWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  width: "100%",
  padding: theme.spacing(0.75, 2),
}));

export type ExtractedSectionProps = {
  matchedSkillTags: Omit<SkillTagProps, "kind">[];
  unmatchedSkillTags: Omit<SkillTagProps, "kind">[];
  skillMap: SkillMapDialogWithButtonProps;
};

const ExtractedSection: React.FC<ExtractedSectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Subsection title={t("抽出情報")}>
      <Stack direction="row" alignItems="center">
        <Box width="150px">
          <Typography
            fontSize={14}
            color="textSecondary"
            sx={{ wordBreak: "keep-all" }}
          >{`${t("スキルタグ")}(${props.matchedSkillTags.length + props.unmatchedSkillTags.length})`}</Typography>
        </Box>
        <StyledTagWrapper>
          {props.matchedSkillTags.map(skillTag => (
            <SkillTag key={`${skillTag.label}`} kind="CHECKED" {...skillTag} />
          ))}
          {props.unmatchedSkillTags.map(skillTag => (
            <SkillTag key={`${skillTag.label}`} kind="DEFAULT" {...skillTag} />
          ))}
        </StyledTagWrapper>
        <SkillMapDialogWithButton {...props.skillMap} />
      </Stack>
    </Subsection>
  );
};

ExtractedSection.displayName = "ExtractedSection";

export default ExtractedSection;
