import { useTranslation } from "@hireroo/i18n";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TreeView, { TreeViewProps } from "@mui/lab/TreeView";
import Box from "@mui/material/Box";
import { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import RenderTreeV3, { RenderTreeV3Props } from "../../modules/RenderTreeV3/RenderTreeV3";
import { NavigationItemProps } from "../../primitive/NavigationItem/NavigationItem";
import ConnectingPanel, { ConnectingPanelProps } from "./parts/ConnectingPanel/ConnectingPanel";

type Status = "CONNECTED_SERVER" | "CONNECTING";

export type ProjectFileNavigationV3Props = {
  status: Status;
  fileTreeStatus: Status;
  focused?: string;
  resetButton?: Pick<ButtonProps, "onClick" | "disabled">;
  renderTree: Omit<RenderTreeV3Props, "label">;
  createFileButton?: Omit<NavigationItemProps, "title" | "kind" | "node">;
  createDirectoryButton?: Omit<NavigationItemProps, "title" | "kind" | "node">;
  deleteNodeButton?: Omit<NavigationItemProps, "kind" | "node">;
  connectingPanel: ConnectingPanelProps;
};

const ProjectFileNavigationV3: React.FC<ProjectFileNavigationV3Props> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [focused, setFocused] = React.useState(".");
  React.useEffect(() => {
    if (props.focused) {
      setFocused(props.focused);
    }
  }, [props.focused]);

  const renderTreeProps: RenderTreeV3Props = {
    ...props.renderTree,
    onSelectFile: sourceFile => {
      props.renderTree.onSelectFile(sourceFile);
      setFocused(sourceFile);
    },
    onSelectDirectory: sourceDirectory => {
      props.renderTree.onSelectDirectory(sourceDirectory);
      setFocused(sourceDirectory);
    },
    label: {
      createFileButton: props.createFileButton,
      createDirectoryButton: props.createDirectoryButton,
      deleteNodeButton: props.deleteNodeButton,
    },
  };
  const treeViewProps: TreeViewProps = {
    selected: focused,
    defaultCollapseIcon: <ExpandMoreIcon />,
    defaultExpandIcon: <ChevronRightIcon />,
  };
  const statusDisplayText: Record<Status, string> = {
    CONNECTED_SERVER: t("サーバー接続済み"),
    CONNECTING: t("サーバー起動中"),
  };
  const statusColor: Record<Status, string> = {
    CONNECTED_SERVER: theme.palette.success.light,
    CONNECTING: theme.palette.error.light,
  };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection={"column"} bgcolor={theme.palette["Secondary/Shades"].p12}>
      <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} height={36}>
        <Box px={1} display="flex" justifyContent="space-between" flexDirection="row" alignItems={"center"} overflow="hidden" height="100%">
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography noWrap sx={{ color: statusColor[props.status], fontSize: 6, mr: 0.5 }}>
              ●
            </Typography>
            <Typography sx={{ color: statusColor[props.status], wordBreak: "keep-all", whiteSpace: "nowrap" }} variant="caption">
              {statusDisplayText[props.status]}
            </Typography>
          </Box>
          <Tooltip title={t("再起動")} placement="top">
            {/*id is used in Tutorial.*/}
            <span>
              <IconButton size="small" {...props.resetButton} id={"project-reset-button"}>
                <RestartAltIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
      <Divider />
      <Box id="project-file-nav-tree" width="100%" height={"100%"} sx={{ overflowY: "auto" }}>
        {props.fileTreeStatus === "CONNECTING" && <ConnectingPanel {...props.connectingPanel} />}
        {props.fileTreeStatus === "CONNECTED_SERVER" && (
          <TreeView {...treeViewProps}>
            <RenderTreeV3 {...renderTreeProps} />
          </TreeView>
        )}
      </Box>
    </Box>
  );
};

ProjectFileNavigationV3.displayName = "ProjectFileNavigation";

export default ProjectFileNavigationV3;
