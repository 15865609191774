import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledStack = styled(Stack)(() => ({
  flexWrap: "wrap",
  gap: "8px",
  overflow: "auto",
  width: "100%",
  maxHeight: "400px",
}));

type Tag = Pick<ChipProps, "label" | "onClick" | "color"> & { tooltip: string };

type Size = "SMALL";

const sizeMap: Record<Size, StackProps["sx"]> = {
  SMALL: {
    maxHeight: "300px",
  },
};

export type ScrollableTagListProps = {
  selectableTags: Tag[];
  onReachedEnd: () => void;
  loading: boolean;
  size?: Size;
};

const ScrollableTagList: React.FC<ScrollableTagListProps> = props => {
  const { t } = useTranslation();
  const { onReachedEnd } = props;
  const scrollRootRef = React.useRef<HTMLDivElement>(null);
  const targetRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => entry.isIntersecting && onReachedEnd());
      },
      {
        root: scrollRootRef.current,
        rootMargin: "0px",
        threshold: 0.25,
      },
    );
    const targetElement = targetRef.current;

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [onReachedEnd]);

  return (
    <FormGroup>
      {props.selectableTags.length > 0 && (
        <StyledStack direction="row" ref={scrollRootRef} sx={props.size ? sizeMap[props.size] : undefined}>
          {props.selectableTags.map((tag, index) => {
            const { tooltip, ...restChipProps } = tag;
            const chipProps: ChipProps = {
              ...restChipProps,
            };
            return (
              <Tooltip key={`tooltip-${tag.label}-${index}`} title={tooltip}>
                <Chip {...chipProps} />
              </Tooltip>
            );
          })}
          {/*This is the element which is reached to the end by scrolling*/}
          <Box display="flex" ref={targetRef} />
          {props.loading && (
            <Box display="flex" justifyContent="center" alignItems="center" mt={1.5}>
              <CircularProgress size={24} color="secondary" />
            </Box>
          )}
        </StyledStack>
      )}
      {props.selectableTags.length === 0 && <Typography fontSize={14}>{t("検索条件に該当するタグがありません。")}</Typography>}
    </FormGroup>
  );
};

ScrollableTagList.displayName = "ScrollableTagList";

export default ScrollableTagList;
