import { useTranslation } from "@hireroo/i18n";
import { ResumeListForm } from "@hireroo/validator";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import Link, { LinkProps } from "../../../../primitive/Link/Link";
import ActionMenu, { ActionMenuProps } from "../ActionMenu/ActionMenu";
import Evaluation, { EvaluationProps } from "../Evaluation/Evaluation";
import Meta, { MetaProps } from "../Meta/Meta";
import Status, { StatusProps } from "../Status/Status";

const SELECTED_CLASS_NAME = "__selected__";
const EllipsisText = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  fontSize: "14px",
  maxWidth: "250px",
  display: "inline-block",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
  [`&.${SELECTED_CLASS_NAME}`]: {
    backgroundColor: theme.palette["Secondary/Shades"].p8,
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  width: "38px",
  height: "38px",
  padding: "9px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  align: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
}));

const CustomTableRow: React.FC<LinkProps> = props => {
  // FIXME I would like to ask the mui team how to define this type definition accurately.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ButtonBase as={Link} {...props} disableRipple />;
};

type Mode = "DEFAULT" | "SELECTABLE";
export type ResumeTableRowProps = {
  id: string;
  name: string;
  disabled?: boolean;
  mode: Mode;
  href: string;
  onClick: () => void;
  actionMenu: ActionMenuProps;
  meta: MetaProps;
  status: StatusProps;
  matchingScore: string;
  evaluation: EvaluationProps;
  skillTags: string[];
};

const ResumeTableRow: React.FC<ResumeTableRowProps> = props => {
  const { mode } = props;
  const { t } = useTranslation();
  const { field } = useController<Record<string, ResumeListForm.ResumeListItem>>({
    name: props.name,
  });

  const checkboxProps: CheckboxProps = {
    checked: field.value.selected,
    color: "secondary",
    size: "small",
    onClick: event => {
      event.stopPropagation();
    },
    onChange: (_event, checked) => {
      const newFieldValue: ResumeListForm.ResumeListItem = {
        ...field.value,
        selected: checked,
      };
      field.onChange(newFieldValue);
    },
  };

  return (
    <StyledTableRow
      as={!props.disabled ? CustomTableRow : undefined}
      // FIXME I would like to ask the mui team how to define this type definition accurately.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={"a"}
      href={mode === "SELECTABLE" ? "" : props.href}
      className={field.value.selected ? SELECTED_CLASS_NAME : undefined}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        if (mode === "SELECTABLE") {
          const newFieldValue: ResumeListForm.ResumeListItem = {
            ...field.value,
            selected: !field.value.selected,
          };
          field.onChange(newFieldValue);
        } else {
          props.onClick?.();
        }
      }}
    >
      <StyledTableCell component="div" align="center" sx={{ width: "38px" }}>
        <StyledCheckbox {...checkboxProps} />
      </StyledTableCell>
      <StyledTableCell component="div" width="20%">
        <Meta {...props.meta} />
      </StyledTableCell>
      <StyledTableCell component="div" sx={{ maxWidth: "25%" }}>
        <Tooltip title={props.skillTags.join(", ")}>
          <EllipsisText color="textSecondary">{props.skillTags.join(" ")}</EllipsisText>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell component="div" align="left" sx={{ width: "100px" }}>
        <Status {...props.status} />
      </StyledTableCell>
      <StyledTableCell component="div" sx={{ width: "100px" }}>
        <StyledTypography>{props.matchingScore}</StyledTypography>
      </StyledTableCell>
      <StyledTableCell component="div" align="left" sx={{ width: "60px" }}>
        <Evaluation {...props.evaluation} />
      </StyledTableCell>
      <StyledTableCell component="div" align="right" sx={{ width: "50px" }}>
        <ActionMenu {...props.actionMenu} disabled={mode === "SELECTABLE"} tooltipText={t("複数選択中はこの操作はできません。")} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

ResumeTableRow.displayName = "ScreeningTableRow";

export default ResumeTableRow;
