import { styled } from "@mui/material/styles";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;

export type ResumeDetailProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const ResumeDetail: React.FC<ResumeDetailProps> = props => {
  return <StyledEmployeeLayout {...props.layout}>{props.children}</StyledEmployeeLayout>;
};

ResumeDetail.displayName = "ResumeDetail";

export default ResumeDetail;
