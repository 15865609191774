import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { useEnabledLeakScore } from "@hireroo/app-helper/feature";
import { FeedbackLinkFactory } from "@hireroo/app-helper/question";
import { Credential } from "@hireroo/app-store/essential/shared";
import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { useAnswerLabelMap } from "@hireroo/challenge/definition";
import { formateRateToIntegerPercent } from "@hireroo/formatter/rate";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generatePath } from "@hireroo/router/api";
import * as React from "react";

export type GenerateQuestionDetailPropsArgs = {
  challengeId: number;
  showAnswer?: boolean;
  showArchivedMark: boolean;
};

type Answer = Widget.ChallengeTestReportProps["questionDetail"]["answers"][0];
type LeakScoreChip = Widget.ChallengeTestReportProps["questionDetail"]["info"]["detailInfo"]["leakScoreChip"];

export const useGenerateQuestionDetailProps = (args: GenerateQuestionDetailPropsArgs): Widget.ChallengeTestReportProps["questionDetail"] => {
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(args.challengeId);
  const challenge = challengeHooks.useChallenge();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const answerLabelMap = useAnswerLabelMap();
  const elapseTimeObject = React.useMemo(() => {
    if (!challenge.challengeQuestion?.averageElapsedTimeSeconds) return undefined;
    return Time.secondsToTimeObject(challenge.challengeQuestion?.averageElapsedTimeSeconds, "MINUTES");
  }, [challenge.challengeQuestion?.averageElapsedTimeSeconds]);
  // Database does not support similar codes

  const enabledLeakScore = useEnabledLeakScore();

  const leakScoreChip: LeakScoreChip = React.useMemo(() => {
    if (!enabledLeakScore) {
      return undefined;
    }
    if (!challenge.challengeQuestion || !challenge.challengeQuestion.isSupportedLeakScore) {
      return undefined;
    }
    if (!challenge.challengeQuestion.leakScore) {
      return { level: "NONE", reason: "NO_DATA" };
    }
    return { level: challenge.challengeQuestion.leakScore.level, score: formatScore(challenge.challengeQuestion.leakScore.score) };
  }, [enabledLeakScore, challenge.challengeQuestion]);

  return {
    title: challenge.challengeQuestion ? resolveLanguage(challenge.challengeQuestion, lang, "title") : "",
    openButton:
      (args.showAnswer && {
        href: generatePath("/e/questions/challenge/:id", {
          pathParams: { id: challenge?.challengeQuestion?.questionId.toString() ?? "" },
          queryParams: { version: challenge?.challengeQuestion?.version ?? INITIAL_VERSION },
        }),
      }) ||
      undefined,
    isArchived: ((): boolean => {
      if (!args.showArchivedMark) {
        return false;
      }
      return challenge.challengeQuestion?.algorithmQuestionStatus === "ARCHIVED";
    })(),
    description: challenge.challengeQuestion ? resolveLanguage(challenge.challengeQuestion, lang, "description") : "",
    difficulty: challenge.challengeQuestion?.difficulty ?? "UNKNOWN",
    info: {
      feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
        mailAddress: currentUserMailAddress,
        targetUrl: generateCurrentOriginUrl("/e/questions/challenge/:id", {
          pathParams: { id: challenge?.challengeQuestion?.questionId.toString() ?? "" },
          queryParams: { version: challenge?.challengeQuestion?.version ?? INITIAL_VERSION },
        }),
      }),
      stats: {
        avgCorrectIntegerRate:
          challenge.challengeQuestion && challenge.challengeQuestion.numUses > 0
            ? {
                kind: "VALUE",
                value: formateRateToIntegerPercent(challenge.challengeQuestion.accuracyRate),
              }
            : { kind: "NONE" },
        numOfUsage: challenge.challengeQuestion?.numUses?.toLocaleString() ?? "-",
        avgElapseTime:
          challenge.challengeQuestion && elapseTimeObject && challenge.challengeQuestion.numUses > 0
            ? {
                kind: "VALUE",
                minutes: elapseTimeObject.minutes,
                seconds: elapseTimeObject.seconds,
              }
            : { kind: "NONE" },
      },
      detailInfo: {
        difficultyStars: {
          difficulty: challenge.challengeQuestion?.difficulty ?? "UNKNOWN",
        },
        numOfLanguages: {
          value: challenge.challengeQuestion?.supportedLanguages.length ?? 0,
        },
        timelimitMinutes: 0,
        createdBy: challenge.challengeQuestion?.isOfficial
          ? { kind: "OFFICIAL" }
          : {
              kind: "PRIVATE",
              displayName: challenge.challengeQuestion?.employee.displayName ?? t("不明"),
            },
        leakScoreChip: leakScoreChip,
      },
    },
    answers: args.showAnswer
      ? (challenge.challengeQuestion?.answers || []).map((answer, index): Answer => {
          return {
            name: `${t("解答")}${index + 1}-${answerLabelMap[answer.label]}`,
            title: resolveLanguage(answer, lang, "title"),
            description: resolveLanguage(answer, lang, "description"),
          };
        })
      : [],
    hintStack: args.showAnswer
      ? {
          items: (challenge.challengeQuestion?.hints || []).map((hint, index) => {
            return {
              mode: "unlocked",
              value: {
                disabled: false,
                title: t2("ConfirmHint", { num: index + 1 }),
                description: resolveLanguage(hint, lang, "description"),
              },
            };
          }),
        }
      : undefined,
  };
};
