import * as uuid from "uuid";

export type FileNode = {
  id: string;
  name: string;
  value: string;
  isDir: boolean;
  isReadOnly?: boolean;
  children?: {
    [key: string]: FileNode;
  };
  isRoot: boolean;
  // for old version
  isRead?: boolean;
  isLoading?: boolean;
  cwd?: boolean;
};

export const findNode = (root: FileNode, path: string): FileNode | null => {
  if (path === ".") return root;
  let current: FileNode | null = root;
  path.split("/").forEach(x => {
    if (current?.children && x in current.children) {
      current = current.children[x];
    } else {
      current = null;
    }
  });
  return current;
};

export const findParent = (root: FileNode, path: string): FileNode | null => {
  let parent: FileNode | null = null;
  let current: FileNode | null = root;

  path.split("/").forEach(x => {
    if (current?.children && x in current.children) {
      parent = current;
      current = current.children[x];
    }
  });

  return parent;
};

export const listChildPaths = (parent: FileNode): string[] => {
  const res: string[] = [];

  res.push(parent.id);
  if (parent.children) {
    Object.values(parent.children).forEach(child => {
      res.push(child.id);
      res.concat(listChildPaths(child));
    });
  }

  return res;
};

export const listAllFilePaths = (parent: FileNode, filePathSet = new Set<string>()): string[] => {
  if (!parent.children) {
    // = not directory
    filePathSet.add(parent.id);
  }
  if (parent.children) {
    for (const child of Object.values(parent.children)) {
      filePathSet.add(child.id);
      listAllFilePaths(child, filePathSet);
    }
  }
  return [...filePathSet];
};

// This function needs to be called where root can be updated in-place i.e. inside immer function
export const createNode = (root: FileNode, path: string, isDir: boolean, isReadOnly: boolean, data?: string): void => {
  let current = root;
  const separated = path.split("/");
  separated.forEach((x, i) => {
    if (!current.children) {
      current.children = {};
    }

    if (!(x in current.children)) {
      // If current path is not the last one i.e. not c in a/b/c, then a and b must be a directory
      // id will be a in the first loop, a/b in the second loop
      if (i + 1 < separated.length) {
        current.children[x] = {
          id: separated.slice(0, i + 1).join("/"),
          name: x,
          isDir: true,
          isReadOnly: false,
          value: "",
          children: {},
          isRoot: false,
        };
      } else {
        current.children[x] = {
          id: path,
          name: x,
          isDir: isDir,
          isReadOnly: isReadOnly,
          value: data || "",
          children: isDir ? {} : undefined,
          isRoot: false,
        };
      }
    }

    current = current.children[x];
  });
};

// This function needs to be called where root can be updated in-place i.e. inside immer function
export const removeNode = (root: FileNode, path: string): void => {
  const parent = findParent(root, path);
  const split = path.split("/");
  const key = split[split.length - 1];

  if (parent && parent.children && key in parent.children) {
    delete parent.children[key];
    if (Object.keys(parent.children).length === 0) {
      parent.children = undefined;
    }
  }
};

export const generateHashString = (n: number): string => {
  return uuid.v4().replace(/-/g, "").slice(0, n);
};
