import { useTitle } from "@hireroo/app-helper/react-use";
import { Company, Payment } from "@hireroo/app-store/essential/employee";
import { AssessmentsIdDetailStore } from "@hireroo/app-store/page/e/assessments_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import AssessmentFeedbackFetchContainer from "../../../../../../widget/v2/e/AssessmentFeedback/FetchContainer";
import AssessmentOverviewFetchContainer from "../../../../../../widget/v2/e/AssessmentOverview/FetchContainer";
import AssessmentReportFetchContainer from "../../../../../../widget/v2/e/AssessmentReport/FetchContainer";
import TechnicalCommentPopperForExamContainer from "../../../../../../widget/v2/e/TechnicalCommentPopperForExam/Container";
import { useGenerateDetailHeaderProps } from "./useGenerateDetailHeaderProps";
import { useGenerateRetriedSelectorProps } from "./useGenerateRetriedSelectorProps";
import { useGenerateSummaryProps } from "./useGenerateSummaryProps";

export type GenerateAssessmentDetailForEmployeePropsArgs = {
  assessmentId: string;
};

export const useGenerateProps = (args: GenerateAssessmentDetailForEmployeePropsArgs): Widget.AssessmentDetailForEmployeeProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const assessment = AssessmentsIdDetailStore.useAssessment();
  const navigate = useTransitionNavigate();
  const currentTab = AssessmentsIdDetailStore.useCurrentTab();
  const [, setParams] = useSearchParams();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const selectedExamId = AssessmentsIdDetailStore.useSelectedExamId();
  const popperStatus = AssessmentsIdDetailStore.usePopperStatus();

  const detailHeaderProps = useGenerateDetailHeaderProps(args);
  const summaryProps = useGenerateSummaryProps();
  const retriedSelector = useGenerateRetriedSelectorProps();
  const dialogStatus = AssessmentsIdDetailStore.useDialogStatus();
  const [resultAssessment, updateAssessment] = Graphql.useUpdateAssessmentForAssessmentsIdDetailMutation();
  const [resultDeleteAssessment, deleteAssessment] = Graphql.useDeleteAssessmentForAssessmentsIdDetailMutation();
  const currentExam = AssessmentsIdDetailStore.useCurrentExam();

  const canReview = currentExam?.status === "COMPLETED" || currentExam?.status === "FINALIZED";

  useTitle(assessment.name);

  return {
    detailHeaderPage: detailHeaderProps,
    defaultTabId: currentTab || undefined,
    items: [
      {
        id: "summary",
        Component:
          selectedExamId === null ? (
            <Widget.TestReportSectionMessage message={t("テストが発行されていません。次の試験作成予定日時までお待ち下さい。")} />
          ) : (
            activeCompanyId &&
            selectedExamId && (
              <AssessmentOverviewFetchContainer assessmentId={assessment.id} companyId={activeCompanyId} examId={selectedExamId} />
            )
          ),
        label: {
          name: t("テスト概要"),
          onClick: () => {
            AssessmentsIdDetailStore.updateCurrentTab("summary");
            setParams(prev => {
              return {
                ...Object.fromEntries(prev),
                tab: "summary",
              };
            });
          },
          icon: "DESCRIPTION" as const,
        },
      },
      {
        id: "report",
        Component: selectedExamId && <AssessmentReportFetchContainer examId={selectedExamId} />,
        label: {
          name: t("レポート"),
          onClick: () => {
            AssessmentsIdDetailStore.updateCurrentTab("report");
            setParams(prev => {
              return {
                ...Object.fromEntries(prev),
                tab: "report",
              };
            });
          },
          icon: "TRENDING_UP" as const,
          disabled: !canReview,
          disabledTooltip: t("試験が終了すると利用可能になります。"),
        },
      },
      isAvailableFeature("exam.technical-comment.create") && {
        id: "review",
        Component: selectedExamId && <AssessmentFeedbackFetchContainer examId={selectedExamId} />,
        label: {
          name: t("技術レビュー"),
          onClick: () => {
            AssessmentsIdDetailStore.updateCurrentTab("review");
            setParams(prev => {
              return {
                ...Object.fromEntries(prev),
                tab: "review",
              };
            });
          },
          disabled: !canReview,
          disabledTooltip: t("試験が終了すると利用可能になります。"),
          icon: "COMMENT" as const,
        },
      },
    ].flatMap(v => (v ? [v] : [])),
    Prompt: activeCompanyId && selectedExamId && popperStatus === "OPEN" && (
      <TechnicalCommentPopperForExamContainer
        companyId={activeCompanyId}
        examId={selectedExamId}
        onClose={() => AssessmentsIdDetailStore.updatePopperStatus("CLOSE")}
      />
    ),
    status: assessment.status === "RUNNING" ? "ACTIVATED" : "DEACTIVATED",
    title: assessment.name,
    summary: summaryProps,
    retriedSelector: retriedSelector,
    announcementMessage: currentExam?.status === "COMPLETED" ? t("テストの実施が終了しました。ただいま、採点中です。") : undefined,
    settingDialog: {
      open: dialogStatus === "OPEN_SHARE_SETTING",
      title: t2("reportPublicScopeSettings", { name: assessment.name }),
      onSubmit: fields => {
        updateAssessment({
          input: {
            assessmentId: assessment.assessmentId,
            reportSettings: {
              ...fields,
            },
          },
        }).then(res => {
          if (res.error) {
            Sentry.captureException(res.error);
            Snackbar.notify({
              message: t("タレントスコアの更新に失敗しました。"),
              severity: "error",
            });
          } else {
            Snackbar.notify({
              message: t("タレントスコアの更新に成功しました。"),
              severity: "success",
            });
            AssessmentsIdDetailStore.updateDialogStatus("CLOSE");
          }
        });
      },
      onClose: () => {
        AssessmentsIdDetailStore.updateDialogStatus("CLOSE");
      },
      disabled: resultAssessment.fetching,
      defaultValues: {
        showAnswer: assessment.reportSettings?.showAnswer ?? true,
        showPlayback: assessment.reportSettings?.showPlayback ?? true,
        showRelativeEvaluation: assessment.reportSettings?.showRelativeEvaluation ?? true,
      },
    },
    deleteDialog: {
      open: dialogStatus === "OPEN_DELETION",
      title: t2("deleteDialog", { filesState: assessment.name }),
      yesButton: {
        onClick: () => {
          deleteAssessment({
            input: {
              assessmentId: assessment.assessmentId,
            },
          }).then(res => {
            if (res.error) {
              Sentry.captureException(res.error);
              Snackbar.notify({
                message: t("タレントスコアの削除に失敗しました。しばらくしてから再度お試し下さい。"),
                severity: "error",
              });
            } else {
              Snackbar.notify({
                message: t("タレントスコアの削除に成功しました。"),
                severity: "success",
              });
              navigate("/e/assessments");
            }
          });
        },
        disabled: resultDeleteAssessment.fetching,
      },
      noButton: {
        onClick: () => {
          AssessmentsIdDetailStore.updateDialogStatus("CLOSE");
        },
      },
    },
    activateDialog: {
      open: dialogStatus === "OPEN_ACTIVATE",
      title: assessment.status === "RUNNING" ? t2("stopTarget", { name: assessment.name }) : t2("resumeTarget", { name: assessment.name }),
      yesButton: {
        onClick: () => {
          updateAssessment({
            input: {
              assessmentId: assessment.assessmentId,
              assessmentStatus: assessment.status === "RUNNING" ? "SUSPENDED" : "RUNNING",
            },
          }).then(res => {
            if (res.error) {
              Sentry.captureException(res.error);
              Snackbar.notify({
                message: t("タレントスコアの更新に失敗しました。"),
                severity: "error",
              });
            } else {
              Snackbar.notify({
                message: t("タレントスコアの更新に成功しました。"),
                severity: "success",
              });
              AssessmentsIdDetailStore.updateDialogStatus("CLOSE");
            }
          });
        },
        children: assessment.status === "RUNNING" ? t("停止", { context: "e/assessment_id_detail" }) : t("再開"),
        disabled: resultAssessment.fetching,
      },
      noButton: {
        onClick: () => {
          AssessmentsIdDetailStore.updateDialogStatus("CLOSE");
        },
      },
    },
  };
};
