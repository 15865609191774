import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Link, { LinkProps } from "../../../../primitive/Link/Link";

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
}));

export type LinkItemProps = Pick<LinkProps, "href">;

const LinkItem: React.FC<LinkItemProps> = props => {
  const theme = useTheme();
  const link: LinkProps = {
    ...props,
    target: "_blank",
    color: theme.palette.text.primary,
  };
  return (
    <Wrapper>
      <Link {...link}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography display="flex" fontSize={14}>
            {props.href}
          </Typography>
          <OpenInNewIcon fontSize="small" htmlColor={theme.palette.text.secondary} />
        </Stack>
      </Link>
    </Wrapper>
  );
};

LinkItem.displayName = "LinkItem";

export default LinkItem;
