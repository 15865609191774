import CancelIcon from "@mui/icons-material/Cancel";
import Chip, { ChipProps } from "@mui/material/Chip";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const StyledChip = styled(Chip)(() => ({
  fontSize: "13px",
  lineHeight: "18px",
  height: "24px",
}));

type Status = "SELECTED" | "NOT_SELECTED";

export type SkillTagButtonProps = {
  status: Status;
  label: string;
  onClick: ChipProps["onClick"];
};

const SkillTagButton: React.FC<SkillTagButtonProps> = props => {
  const theme = useTheme();
  const colorMap: Record<Status, string> = {
    SELECTED: theme.palette.primary.dark,
    NOT_SELECTED: theme.palette["Gray/Shades"].p30,
  };
  const hoverColorMap: Record<Status, string | undefined> = {
    SELECTED: theme.palette.primary.dark,
    NOT_SELECTED: undefined,
  };
  const DeleteIconMap: Record<Status, React.ReactElement | undefined> = {
    SELECTED: <CancelIcon htmlColor={theme.palette.text.secondary} />,
    NOT_SELECTED: undefined,
  };
  const chip: ChipProps = {
    clickable: true,
    onClick: props.onClick,
    sx: {
      backgroundColor: colorMap[props.status],
      "&:hover": {
        backgroundColor: hoverColorMap[props.status],
      },
      alignItems: "center",
    },
    deleteIcon: DeleteIconMap[props.status],
    onDelete: props.status === "SELECTED" ? props.onClick : undefined,
    label: props.label,
  };
  return <StyledChip {...chip} />;
};

SkillTagButton.displayName = "SkillTagButton";

export default SkillTagButton;
