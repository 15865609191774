import "@fontsource/noto-sans-jp/400.css";
import "@fontsource/noto-sans-jp/500.css";
import "@fontsource/noto-sans-jp/700.css";

import { alpha, createTheme } from "@mui/material/styles";

const lighten = (hex: string, value: number): string => alpha(hex, 1 - value);
const darken = (hex: string, value: number): string => alpha(hex, 1 - value);

const fontFamily: string = ['"Noto Sans JP"', "sans-serif"].join(",");

export const Light = createTheme({
  palette: {
    mode: "light",
    text: {
      primary: "#212121",
      secondary: "#666666",
      disabled: "#9E9E9E",
    },
    primary: {
      main: "#EF6972",
      dark: "#A7494F",
      light: "#F2878E",
    },
    secondary: {
      main: "#4B51C1",
      dark: "#3D3EAA",
      light: "#656CCB",
    },
    error: {
      main: "#FF1744",
      dark: "#B2102F",
      light: "#FF4569",
    },
    warning: {
      main: "#ED6C02",
      dark: "#E65100",
      light: "#FF9800",
    },
    success: {
      main: "#2E7D32",
      dark: "#1B5E20",
      light: "#4CAF50",
    },
    info: {
      main: "#0288D1",
      dark: "#01579B",
      light: "#03A9F4",
    },
    divider: "#e0e0e0",
    background: {
      default: "#F4F4F6",
    },
    "Gray/Shades": {
      p8: darken("#000", 0.92),
      p12: darken("#000", 0.88),
      p16: darken("#000", 0.84),
      p30: darken("#000", 0.7),
      p56: darken("#000", 0.44),
    },
    "Primary/Shades": {
      p4: lighten("#EF6972", 0.96),
      p8: lighten("#EF6972", 0.92),
      p12: lighten("#EF6972", 0.88),
      p16: lighten("#EF6972", 0.84),
      p30: lighten("#EF6972", 0.7),
      p50: lighten("#EF6972", 0.5),
    },
    "Secondary/Shades": {
      p4: lighten("#4B51C1", 0.96),
      p8: lighten("#4B51C1", 0.92),
      p12: lighten("#4B51C1", 0.88),
      p16: lighten("#4B51C1", 0.84),
      p30: lighten("#4B51C1", 0.7),
      p50: lighten("#4B51C1", 0.5),
    },
    "Error/Shades": {
      p4: lighten("#FF1744", 0.96),
      p8: lighten("#FF1744", 0.92),
      p12: lighten("#FF1744", 0.88),
      p16: lighten("#FF1744", 0.84),
      p30: lighten("#FF1744", 0.7),
      p50: lighten("#FF1744", 0.5),
    },
    "Warning/Shades": {
      p4: lighten("#ED6C02", 0.96),
      p8: lighten("#ED6C02", 0.92),
      p12: lighten("#ED6C02", 0.88),
      p16: lighten("#ED6C02", 0.84),
      p30: lighten("#ED6C02", 0.7),
      p50: lighten("#ED6C02", 0.5),
    },
    "Success/Shades": {
      p4: lighten("#2E7D32", 0.96),
      p8: lighten("#2E7D32", 0.92),
      p12: lighten("#2E7D32", 0.88),
      p16: lighten("#2E7D32", 0.84),
      p30: lighten("#2E7D32", 0.7),
      p50: lighten("#2E7D32", 0.5),
    },
    "Info/Shades": {
      p4: lighten("#0288D1", 0.96),
      p8: lighten("#0288D1", 0.92),
      p12: lighten("#0288D1", 0.88),
      p16: lighten("#0288D1", 0.84),
      p30: lighten("#0288D1", 0.7),
      p50: lighten("#0288D1", 0.5),
    },
    "outline-only": {
      main: lighten("#000000", 0.13),
      dark: lighten("#000000", 0.4),
      light: lighten("#000000", 0.62),
    },
    Other: {
      FilledInputBG: lighten("#000000", 0.94),
      Divider: lighten("#000000", 0.88),
      WeakDivider: "#F0F0F0",
      OutlinedBorder: lighten("#000000", 0.77),
      BackdropOverlay: lighten("#000000", 0.5),
      SnackbarBG: "#323232",
      Rating: "#FFB400",
    },
    Rank: {
      S: "#E53935",
      A: "#FB8C00",
      B: "#E3BC10",
      C: "#5485CE",
      D: "#4CAF50",
    },
    HeaderBG: lighten("#FFF", 0.2),
    "Background/Paper": {
      // All same when light mode
      p2: "#FFFFFF",
      p4: "#FFFFFF",
      p6: "#FFFFFF",
      p8: "#FFFFFF",
      p12: "#FFFFFF",
      p16: "#FFFFFF",
      p24: "#FFFFFF",
    },
    Action: {
      "Disabled Background": {
        p12: lighten("#FFFFFF", 0.12),
      },
    },
    /** TODO Check Color  */
    Green: { 400: "#F50057" },
    LightBlue: { 400: "#03A9F4" },
    Pink: { 400: "#66BB6A" },
  },
  typography: {
    fontFamily: fontFamily,
  },
});

export const Dark = createTheme({
  palette: {
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: "#b8b8b8",
      disabled: "#888888",
    },
    primary: {
      main: "#EF9A9A",
      dark: "#BA6B6C",
      light: "#FFCCCB",
    },
    secondary: {
      main: "#838DFF",
      dark: "#5056F1",
      light: "#A9AEFF",
    },
    error: {
      main: "#F44336",
      dark: "#D32F2F",
      light: "#E57373",
    },
    warning: {
      main: "#FFA726",
      dark: "#F57C00",
      light: "#FFB74D",
    },
    success: {
      main: "#66BB6A",
      dark: "#388E3C",
      light: "#81C784",
    },
    info: {
      main: "#29B6F6",
      dark: "#0288D1",
      light: "#4FC3F7",
    },
    divider: "#4e4d4d",
    background: {
      default: "#121212",
    },
    action: {
      active: darken("#FFF", 0.42),
    },
    "Gray/Shades": {
      p8: darken("#FFF", 0.92),
      p12: darken("#FFF", 0.88),
      p16: darken("#FFF", 0.84),
      p30: darken("#FFF", 0.7),
      p56: darken("#FFF", 0.44),
    },
    "Primary/Shades": {
      p4: darken("#EF9A9A", 0.96),
      p8: darken("#EF9A9A", 0.92),
      p12: darken("#EF9A9A", 0.88),
      p16: darken("#EF9A9A", 0.84),
      p30: darken("#EF9A9A", 0.7),
      p50: darken("#EF9A9A", 0.5),
    },
    "Secondary/Shades": {
      p4: darken("#838DFF", 0.96),
      p8: darken("#838DFF", 0.92),
      p12: darken("#838DFF", 0.88),
      p16: darken("#838DFF", 0.84),
      p30: darken("#838DFF", 0.7),
      p50: darken("#838DFF", 0.5),
    },
    "Error/Shades": {
      p4: darken("#FF1744", 0.96),
      p8: darken("#FF1744", 0.92),
      p12: darken("#FF1744", 0.88),
      p16: darken("#FF1744", 0.84),
      p30: darken("#FF1744", 0.7),
      p50: darken("#FF1744", 0.5),
    },
    "Warning/Shades": {
      p4: darken("#FFA726", 0.96),
      p8: darken("#FFA726", 0.92),
      p12: darken("#FFA726", 0.88),
      p16: darken("#FFA726", 0.84),
      p30: darken("#FFA726", 0.7),
      p50: darken("#FFA726", 0.5),
    },
    "Success/Shades": {
      p4: darken("#66BB6A", 0.96),
      p8: darken("#66BB6A", 0.92),
      p12: darken("#66BB6A", 0.88),
      p16: darken("#66BB6A", 0.84),
      p30: darken("#66BB6A", 0.7),
      p50: darken("#66BB6A", 0.5),
    },
    "Info/Shades": {
      p4: darken("#0288D1", 0.96),
      p8: darken("#0288D1", 0.92),
      p12: darken("#0288D1", 0.88),
      p16: darken("#0288D1", 0.84),
      p30: darken("#0288D1", 0.7),
      p50: darken("#0288D1", 0.5),
    },
    "outline-only": {
      main: darken("#FFFFFF", 0.13),
      dark: darken("#FFFFFF", 0.4),
      light: darken("#FFFFFF", 0.62),
    },
    HeaderBG: darken("#232323", 0.2),
    Other: {
      FilledInputBG: darken("#FFFFFF", 0.91),
      Divider: lighten("#FFFFFF", 0.88),
      WeakDivider: "#303030",
      OutlinedBorder: lighten("#FFFFFF", 0.77),
      BackdropOverlay: lighten("#000000", 0.5),
      SnackbarBG: "#323232",
      Rating: "#FFB400",
    },
    Rank: {
      S: "#E53935",
      A: "#FB8C00",
      B: "#E3BC10",
      C: "#5485CE",
      D: "#4CAF50",
    },
    "Background/Paper": {
      p2: "#232323",
      p4: "#272727",
      p6: "#2C2C2C",
      p8: "#2E2E2E",
      p12: "#333333",
      p16: "#363636",
      p24: "#383838",
    },
    Action: {
      "Disabled Background": {
        p12: lighten("#FFFFFF", 0.88),
      },
    },
    /** TODO Check Color  */
    Green: { 400: "#F50057" },
    LightBlue: { 400: "#03A9F4" },
    Pink: { 400: "#66BB6A" },
  },
  typography: {
    fontFamily: fontFamily,
  },
});
