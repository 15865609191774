import * as z from "zod";

export const useResumeListItem = () => {
  return z.object({
    itemId: z.string(),
    selected: z.boolean(),
  });
};

export type ResumeListItem = z.infer<ReturnType<typeof useResumeListItem>>;

export const useResumeListForm = () => {
  const resumeListItem = useResumeListItem();
  return z.object({
    items: resumeListItem.array(),
  });
};

export type ResumeListForm = z.infer<ReturnType<typeof useResumeListForm>>;
