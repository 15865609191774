import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../primitive/BaseDialog/BaseDialog";

export type ResetConfirmDialogProps = {
  open: boolean;
  resetOption: "withoutSourceCode" | "withSourceCode" | null;
  onSelectResetOption: (option: "withoutSourceCode" | "withSourceCode") => void;
  onAccept: () => void;
  onClose: () => void;
};

const ResetConfirmDialog: React.FC<ResetConfirmDialogProps> = props => {
  const { t } = useTranslation();
  const handleOnClickYesButton = React.useCallback(() => {
    props.onAccept();
  }, [props]);
  const handleOnClickNoButton = React.useCallback(() => {
    props.onClose();
  }, [props]);
  const handleOnSelectResetOption = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      if (value === "withSourceCode" || value === "withoutSourceCode") {
        props.onSelectResetOption(value);
      }
    },
    [props],
  );
  const baseDialogProps: BaseDialogProps = {
    title: t("開発環境を再起動します。よろしいですか？"),
    open: props.open,
    yesButton: {
      color: "error",
      children: t("開発環境を再起動する"),
      onClick: handleOnClickYesButton,
      disabled: props.resetOption === null,
    },
    noButton: {
      children: t("閉じる"),
      onClick: handleOnClickNoButton,
    },
    disableEnter: true,
  };
  return (
    <BaseDialog {...baseDialogProps}>
      <DialogContentText>
        <Typography component={"span"}>
          {t(
            "現在の開発環境を破棄して新しい開発環境を作成します。ソースコードの変更を引き継ぐかどうかを選択してから、「開発環境を再起動する」ボタンを押してください。",
          )}
        </Typography>
        <br />
        <br />
        <Typography component={"span"} variant={"caption"}>
          {t("※再起動には最大5分ほど時間がかかります。")}
        </Typography>
        <br />
        <Typography component={"span"} variant={"caption"}>
          {t("※ソースコードを初期化した場合、初期化前の状態に戻すことはできませんのでご注意ください。")}
        </Typography>
      </DialogContentText>
      <Box mt={2}>
        <FormControl>
          <RadioGroup aria-labelledby="reset-option" name="reset-option" value={props.resetOption} onChange={handleOnSelectResetOption}>
            <FormControlLabel
              value="withoutSourceCode"
              control={<Radio color={"secondary"} />}
              label={t("ソースコードの変更を引き継いで再起動する")}
            />
            <FormControlLabel value="withSourceCode" control={<Radio color={"secondary"} />} label={t("ソースコードを初期化して再起動する")} />
          </RadioGroup>
        </FormControl>
      </Box>
    </BaseDialog>
  );
};

ResetConfirmDialog.displayName = "ResetConfirmDialog";

export default ResetConfirmDialog;
