import { ResumeDetailDocumentForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

export type ContextValue = {};

export const ResumeDocumentAddDialogContext = React.createContext<ContextValue>({});

export const useResumeDocumentAddDialogContext = () => React.useContext(ResumeDocumentAddDialogContext);

export type ResumeDocumentAddDialogProviderProps = {};
export const ResumeDocumentAddDialogProvider: React.FC<React.PropsWithChildren<ResumeDocumentAddDialogProviderProps>> = props => {
  const validateSchema = ResumeDetailDocumentForm.useResumeDocumentForm();
  const methods = useForm<ResumeDetailDocumentForm.ResumeDocumentFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      resumeDocuments: [],
    },
  });

  const contextValue: ContextValue = {};
  return (
    <FormProvider {...methods}>
      <ResumeDocumentAddDialogContext.Provider value={contextValue} children={props.children} />
    </FormProvider>
  );
};
