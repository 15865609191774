import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateResumeDocumentAddDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ResumeDocumentAddDialogContainerProps = GenerateResumeDocumentAddDialogPropsArgs;

const ResumeDocumentAddDialogContainer: React.FC<ResumeDocumentAddDialogContainerProps> = props => {
  const resumeDocumentAddDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ResumeDocumentAddDialog {...resumeDocumentAddDialogProps} />
    </ErrorBoundary>
  );
};

ResumeDocumentAddDialogContainer.displayName = "ResumeDocumentAddDialogContainer";

export default withErrorBoundary(ResumeDocumentAddDialogContainer, {});
