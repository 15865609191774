import { FETCH_SIZE } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (companySkillTags: Types.CompanySkillTag[]): void => {
  state.companySkillTags = companySkillTags;
  state.initialized = true;
};

export const clear = (): void => {
  state.res = null;
  state.skillTagMap.clear();
  state.companySkillTags = [];
  state.initialized = false;
  state.nextOffset = 0;
};

export const updateCompanySkillTags = (companySkillTags: Types.CompanySkillTag[]) => {
  state.companySkillTags = companySkillTags;
};

export const updateRes = (res: Types.Res) => {
  state.res = res;
  res.skillTagNodes.forEach(node => {
    state.skillTagMap.set(node.skillTagNodeId, node);
  });
};

export const clearSkillTags = () => {
  state.skillTagMap.clear();
};

export const updateTextFilter = (textFilter: string) => {
  state.textFilter = textFilter;
  /**
   * clear offset when text filter is updated
   */
  clearNextOffset();
};

export const updateNextOffset = () => {
  state.nextOffset += FETCH_SIZE;
};

export const clearNextOffset = () => {
  state.nextOffset = 0;
};
