import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";
import * as path from "path";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";
import { useGenerateJupyterNotebookViewerProps } from "./useGenerateJupyterNotebookProps";
import { useGenerateProjectDiffViewerProps } from "./useGenerateProjectDiffViewerProps";

const { ProjectContentsViewerV3 } = ProjectTestReport;

export type GenerateProjectContentsViewerV3PropsArgs = {
  projectId: number;
  questionId: number;
  mode: "HISTORY" | "PLAYBACK";
};

export const useGenerateProps = (args: GenerateProjectContentsViewerV3PropsArgs): Widget.ProjectContentsViewerV3Props => {
  const action = ProjectContentsViewerV3.createAction(args.projectId);
  const hooks = ProjectContentsViewerV3.createHooks(args.projectId);
  const diffMap = hooks.useDiffMap();
  const selectFileName = hooks.useCurrentSelectFilename();

  const projectDiffViewerProps = useGenerateProjectDiffViewerProps({
    projectId: args.projectId,
  });
  const jupyterNotebookViewerProps = useGenerateJupyterNotebookViewerProps({
    projectId: args.projectId,
  });

  const tabProps = Array.from(diffMap.keys()).map((filename): Widget.ProjectContentsViewerV3Props["tabs"][0] => {
    const diff = diffMap.get(filename);
    return {
      value: filename,
      op: diff ? PrivateHelper.OperationMap[diff.operation] : "UNKNOWN",
      displayText: path.basename(filename),
      disabled: false,
      tooltipText: filename,
    };
  });

  const baseProps: Omit<Widget.ProjectContentsViewerV3Props, "viewer"> = {
    value: selectFileName || (tabProps[0] ? tabProps[0].value : ""),
    onChange: (_, filename) => {
      if (typeof filename === "string") {
        action.setCurrentSelectFileName(filename);
      }
    },
    tabs: tabProps,
  };

  const viewerProps = React.useMemo((): Widget.ProjectContentsViewerV3Props["viewer"] => {
    if (selectFileName.match(/.ipynb$/)) {
      return {
        mode: "JUPYTER_NOTEBOOK_VIEWER",
        content: jupyterNotebookViewerProps,
      };
    }
    return {
      mode: "DIFF",
      content: projectDiffViewerProps,
    };
  }, [selectFileName, jupyterNotebookViewerProps, projectDiffViewerProps]);

  return {
    ...baseProps,
    viewer: viewerProps,
  };
};
