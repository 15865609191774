import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const SubsectionPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  boxShadow: "none",
}));

const SubsectionTitle = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 700,
}));

export type SubsectionProps = {
  title: string;
};

const Subsection: React.FC<React.PropsWithChildren<SubsectionProps>> = props => {
  return (
    <SubsectionPaper>
      <Stack spacing={2}>
        <SubsectionTitle>{props.title}</SubsectionTitle>
        {props.children}
      </Stack>
    </SubsectionPaper>
  );
};

Subsection.displayName = "Subsection";

export default Subsection;
