import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { ContentApi } from "@hireroo/rest-api/client";

export const useResumeEmploymentMap = (): Record<Graphql.ResumeEmploymentType, string> => {
  const { t } = useTranslation();
  return {
    [Graphql.ResumeEmploymentType.FullTime]: t("正社員"),
    [Graphql.ResumeEmploymentType.Contract]: t("業務委託"),
    [Graphql.ResumeEmploymentType.PartTime]: t("パート・アルバイト"),
    [Graphql.ResumeEmploymentType.Internship]: t("インターン"),
  };
};

export const useResumeDegreeMap = (): Record<Graphql.ResumeDegree, string> => {
  const { t } = useTranslation();
  return {
    [Graphql.ResumeDegree.Bachelor]: t("学士"),
    [Graphql.ResumeDegree.Master]: t("修士"),
    [Graphql.ResumeDegree.Phd]: t("博士"),
  };
};

export const useFailureReasonMap = (): Record<
  Exclude<ContentApi.Schemas.UploadResumesResponse["results"][number]["failureReason"], undefined>,
  string
> => {
  const { t } = useTranslation();
  return {
    INVALID_FILE_FORMAT: t("不正なPDFファイルです。"),
    FILE_COUNT_EXCEEDED: t("ファイル数が上限を超えています。"),
    FILE_TOO_LARGE: t("ファイルサイズが大きすぎます。"),
    SYSTEM_ERROR: t("エラーが発生しました。しばらくしてからもう一度お試しください。"),
    UNKNOWN: t("エラーが発生しました。しばらくしてからもう一度お試しください。"),
  };
};

export const useInvalidMulterUploadStatusMap = (): Record<ContentApi.Schemas.MulterUploadInvalidResponse["errorStatus"], string> => {
  const { t } = useTranslation();

  return {
    LIMIT_FILE_COUNT: t("一度にアップロードできる書類数が上限を超えています。"),
    LIMIT_FILE_SIZE: t("ファイルサイズが大きすぎます。"),
    INVALID_REQUEST: t("しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
  };
};
