import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/v2/CodeEditor";
import DiffEditor, { DiffEditorProps } from "@hireroo/code-editor/react/v2/DiffEditor";
import type * as Interface from "@hireroo/evaluation-result-interface";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const CaptionLabel = styled(Typography)`
  flex-basis: 48%;
`;

export type TestCaseResultProps = Interface.TestCaseResult["value"];

const TestCaseResult: React.FC<TestCaseResultProps> = props => {
  const { t } = useTranslation();

  const codeEditorProps: CodeEditorProps = {
    path: "testcase-code-editor",
    value: props.input,
    readOnly: true,
    minimap: false,
    lineNumbers: "off",
    style: {
      height: "150px",
    },
  };

  const diffEditorProps: DiffEditorProps = {
    original: props.output,
    originalModelPath: "testcase-original",
    modified: props.expected,
    modifiedModelPath: "testcase-modified",
    style: {
      height: 150,
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <CaptionLabel variant="caption" mb={2} display="block">
          {t("入力")}
        </CaptionLabel>
        <React.Suspense>
          <CodeEditor key="code-editor" {...codeEditorProps} />
        </React.Suspense>
      </Grid>
      <Grid item xs={8}>
        <Box display="flex" mb={2}>
          <CaptionLabel variant="caption" sx={{ flexBasis: "50%" }}>
            {t("出力")}
          </CaptionLabel>
          <CaptionLabel variant="caption" sx={{ flexBasis: "50%" }}>
            {t("期待値")}
          </CaptionLabel>
        </Box>
        <React.Suspense>
          <DiffEditor key="diff-editor" {...diffEditorProps} />
        </React.Suspense>
      </Grid>
    </Grid>
  );
};

TestCaseResult.displayName = "TestCaseResult";

export default TestCaseResult;
