import { useDroppable } from "@dnd-kit/core";
import { useTranslation } from "@hireroo/i18n";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Button from "@mui/material/Button";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "4px",
  padding: theme.spacing(4),
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  "&:focus": {
    backgroundColor: theme.palette["Secondary/Shades"].p12,
    border: "2px dashed",
    borderColor: theme.palette.secondary.main,
  },
}));

export type DragAndDropInputProps = {
  onSelectedFiles: (files: File[]) => void;
};

const DragAndDropInput: React.FC<DragAndDropInputProps> = props => {
  const { t } = useTranslation();
  const { setNodeRef } = useDroppable({
    id: "droppable-area",
  });
  const [draggableInArea, setDraggableInArea] = React.useState(false);
  const stackProps: StackProps = {
    onDrop: event => {
      event.preventDefault();
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        const filesArray = Array.from(event.dataTransfer.files);
        props.onSelectedFiles(filesArray);
      }
    },
    onDragOver: event => {
      setDraggableInArea(true);
      event.preventDefault();
    },
    onDragLeave: event => {
      setDraggableInArea(false);
      event.preventDefault();
    },
    sx: theme => ({
      backgroundColor: draggableInArea ? theme.palette["Secondary/Shades"].p12 : theme.palette["Gray/Shades"].p8,
      border: "2px dashed",
      borderColor: draggableInArea ? theme.palette.secondary.main : theme.palette["Other"]["Divider"],
    }),
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      props.onSelectedFiles(filesArray);
    }
  };
  return (
    <StyledStack {...stackProps} ref={setNodeRef} spacing={1}>
      <Stack direction="row" spacing={1}>
        <FileUploadIcon />
        <Typography>{t("ファイルをここにドロップ")}</Typography>
      </Stack>
      <Typography color="textSecondary" fontSize={12}>
        {t("もしくは")}
      </Typography>
      <Button component="label" variant="outlined" size="small" color="outline-only">
        {t("ファイルを選択する")}
        <VisuallyHiddenInput name="upload" type="file" multiple accept="application/pdf" onChange={handleInputChange} />
      </Button>
    </StyledStack>
  );
};

DragAndDropInput.displayName = "DragAndDropInput";

export default DragAndDropInput;
