import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateResumeDocumentUploadDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ResumeDocumentUploadDialogContainerProps = GenerateResumeDocumentUploadDialogPropsArgs;

const ResumeDocumentUploadDialogContainer: React.FC<ResumeDocumentUploadDialogContainerProps> = props => {
  const resumeDocumentUploadDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ResumeDocumentUploadDialog {...resumeDocumentUploadDialogProps} />
    </ErrorBoundary>
  );
};

ResumeDocumentUploadDialogContainer.displayName = "ResumeDocumentUploadDialogContainer";

export default withErrorBoundary(ResumeDocumentUploadDialogContainer, {});
