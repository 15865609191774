import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  resume: null,
  selectedResumeItem: null,
  dialogStatus: "CLOSED",
  currentTab: "ANALYZED_REPORT",
  refresh: null,
});
