import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

const EllipsisText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  max-width: 400px;
`;

export type MetaProps = {
  title: string;
};

const Meta: React.FC<MetaProps> = props => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Tooltip title={props.title}>
        <EllipsisText fontWeight={400} fontSize="16px">
          {props.title}
        </EllipsisText>
      </Tooltip>
    </Stack>
  );
};

Meta.displayName = "Meta";

export default Meta;
