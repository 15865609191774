import { Notebook, NotebookProps } from "@hireroo/jupyter-ui/react/Notebook";
import { NotebookViewer, NotebookViewerProps } from "@hireroo/jupyter-ui/react/NotebookViewer";
import { Box } from "@mui/material";
import React from "react";
import { useResizeDetector } from "react-resize-detector";

import IdlePanel, { IdlePanelProps } from "../../modules/IdlePanel/IdlePanel";
import CodeEditor, { CodeEditorProps } from "./parts/CodeEditor/CodeEditor";

type MonacoEditor = {
  mode: "MONACO";
  codeEditor: CodeEditorProps;
};

type InjectComponent = {
  mode: "INJECTION";
  children: React.ReactNode;
};

type JupyterNotebook = {
  mode: "JUPYTER_NOTEBOOK";
  notebook: NotebookProps;
};

type JupyterNotebookViewer = {
  mode: "JUPYTER_NOTEBOOK_VIEWER";
  notebookViewer: NotebookViewerProps;
};

type IdleViewer = {
  mode: "IDLE";
  idlePanel: IdlePanelProps;
};

export type ProjectWorkspaceProps = MonacoEditor | JupyterNotebook | JupyterNotebookViewer | IdleViewer | InjectComponent;

const ProjectWorkspace: React.FC<ProjectWorkspaceProps> = props => {
  const isObserve = props.mode === "MONACO";
  const resizeDetector = useResizeDetector({
    handleHeight: isObserve,
    handleWidth: isObserve,
  });

  return (
    <Box width={"100%"} height={"100%"} ref={resizeDetector.ref} zIndex={2000}>
      {props.mode === "IDLE" && <IdlePanel {...props.idlePanel} />}
      <Box sx={{ display: props.mode !== "IDLE" ? "block" : "none" }} height={"100%"}>
        {props.mode === "INJECTION" && props.children}
        {props.mode === "MONACO" && props.codeEditor && (
          <CodeEditor {...props.codeEditor} height={resizeDetector.height} width={resizeDetector.width} />
        )}
        {props.mode === "JUPYTER_NOTEBOOK_VIEWER" && props.notebookViewer && <NotebookViewer {...props.notebookViewer} />}
        {props.mode === "JUPYTER_NOTEBOOK" && <Notebook {...props.notebook} />}
      </Box>
    </Box>
  );
};

ProjectWorkspace.displayName = "ProjectWorkspace";

export default ProjectWorkspace;
