import { useTranslation } from "@hireroo/i18n";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import Alert, { AlertProps } from "@mui/material/Alert";
import Button, { type ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import StatusInfo, { StatusInfoProps } from "../../../../modules/StatusInfo/StatusInfo";

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: "8px",
  alignContent: "center",
  mx: "auto",
  alignItems: "center",
  backgroundColor: theme.palette["Gray/Shades"].p8,
  color: theme.palette.text.secondary,
  width: "100%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.Other.Divider,
}));

type Content =
  | {
      kind: "ANALYZING";
      actionButton: Pick<ButtonProps, "onClick">;
    }
  | {
      kind: "ANALYZED";
    }
  | {
      kind: "ERROR";
      actionButton: Pick<ButtonProps, "onClick">;
    }
  | {
      kind: "PASSED";
      props: Omit<StatusInfoProps, "severity">;
    }
  | {
      kind: "NOT_PASSED";
      props: Omit<StatusInfoProps, "severity">;
    };

export type StatusMessageProps = {
  content: Content;
};

const StatusMessage: React.FC<StatusMessageProps> = props => {
  const { t } = useTranslation();
  const commonAlert: AlertProps = {
    variant: "filled",
  };
  const confirmButton: ButtonProps = {
    children: t("確認"),
    size: "small",
    variant: "outlined",
    color: "outline-only",
  };
  return (
    <>
      {props.content.kind === "ANALYZING" && (
        <StyledAlert
          {...commonAlert}
          severity="info"
          icon={<InfoIcon />}
          action={<StyledButton {...confirmButton} {...props.content.actionButton} />}
        >
          <Typography>{t("解析中の書類があります。")}</Typography>
        </StyledAlert>
      )}
      {props.content.kind === "ANALYZED" && (
        <StyledAlert {...commonAlert} icon={<InfoIcon />}>
          <Typography>{t("書類解析が完了しました。評価を進めましょう。")}</Typography>
        </StyledAlert>
      )}
      {props.content.kind === "ERROR" && (
        <StyledAlert {...commonAlert} icon={<ErrorIcon color="error" />} action={<Button {...confirmButton} {...props.content.actionButton} />}>
          <Typography>{t("書類解析に失敗しました。")}</Typography>
        </StyledAlert>
      )}
      {props.content.kind === "PASSED" && <StatusInfo {...props.content.props} severity="success" />}
      {props.content.kind === "NOT_PASSED" && <StatusInfo {...props.content.props} severity="error" />}
    </>
  );
};

StatusMessage.displayName = "StatusMessage";

export default StatusMessage;
