import * as React from "react";

export type BackgroundBoxProps = {
  className?: string;
};

const BackgroundBox: React.FC<BackgroundBoxProps> = props => {
  return (
    <svg className={props.className} width="180" height="200" viewBox="0 0 180 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_13998_62132)">
        <rect width="180" height="200" fill="#191C6B" />
      </g>
      <rect x="-74" y="-75" width="257" height="257" fill="url(#paint0_radial_13998_62132)" />
      <rect x="37" y="76" width="213" height="213" fill="url(#paint1_radial_13998_62132)" />
      <rect x="87" y="-45" width="141" height="141" fill="url(#paint2_radial_13998_62132)" />
      <g filter="url(#filter1_b_13998_62132)">
        <rect width="180" height="200" fill="black" fillOpacity="0.2" />
      </g>
      <defs>
        <filter
          id="filter0_b_13998_62132"
          x="-64"
          y="-64"
          width="308"
          height="328"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="32" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_13998_62132" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_13998_62132" result="shape" />
        </filter>
        <filter
          id="filter1_b_13998_62132"
          x="-64"
          y="-64"
          width="308"
          height="328"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="32" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_13998_62132" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_13998_62132" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_13998_62132"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(54.6637 58.7414) rotate(90) scale(123.259)"
        >
          <stop stopColor="#686EFF" stopOpacity="0.5" />
          <stop offset="1" stopColor="#686EFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_13998_62132"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(143.636 186.844) rotate(90) scale(102.156)"
        >
          <stop offset="0.0104167" stopColor="#45CEFF" stopOpacity="0.5" />
          <stop offset="1" stopColor="#45CEFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_13998_62132"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(157.59 28.3757) rotate(90) scale(67.6243)"
        >
          <stop stopColor="#F84490" stopOpacity="0.6" />
          <stop offset="1" stopColor="#F84490" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

BackgroundBox.displayName = "BackgroundBox";

export default BackgroundBox;
