import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  initialized: false,
  companySkillTags: [],
  res: null,
  skillTagMap: proxyMap(),
  textFilter: "",
  /**
   * hold next offset for temporary
   * this should be given from the server
   * once the server is ready, remove this field
   */
  nextOffset: 0,
});
