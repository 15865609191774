import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useFilters = () => {
  const snapshot = useSnapshotState();
  return snapshot.filters;
};

export const useIsDescending = () => {
  const snapshot = useSnapshotState();
  return snapshot.isDescending;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.page;
};

export const useSize = () => {
  const snapshot = useSnapshotState();
  return snapshot.size;
};

export const useSortMethod = () => {
  const snapshot = useSnapshotState();
  return snapshot.sortMethod;
};

export const useSkillTagRes = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.res;
};

export const useCurrentSkillTags = () => {
  const snapshot = useSnapshotState();
  const skillTagIds = Array.from(snapshot.skillTagState.currentSkillTagIdsSet);
  if (skillTagIds.length === 0) {
    return Array.from(snapshot.skillTagState.skillTagSources.values());
  }
  return skillTagIds.reduce<Types.SkillTag[]>((all, skillTagId) => {
    const skillTag = snapshot.skillTagState.skillTagSources.get(skillTagId);
    if (skillTag) {
      all.push(skillTag);
    }
    return all;
  }, []);
};
export const useSkillTagSources = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.skillTagState.skillTagSources.values());
};

export const useSkillTagTextFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.textFilter;
};

export const useSkillTagNextOffset = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.nextOffset;
};
