import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import * as Graphql from "@hireroo/graphql/client/urql";
import * as Sentry from "@sentry/react";
import * as React from "react";

import type { Mode } from "./types";

export type StartWorkspaceArgs = {
  mode: Mode;
  entityId: number;
  questionId: number;
  questionVersion: string;
  submissionId: number;
};

export type StartWorkspaceErrorStatus = "PENDING" | "ERROR";

export const useStartWorkspace = (args: StartWorkspaceArgs) => {
  const [startDevelopmentWorkspaceResult, startDevelopmentWorkspace] = Graphql.useStartDevelopmentWorkspaceForProjectCodingEditorMutation();
  const [startEvaluationWorkspaceResult, startEvaluationWorkspace] = Graphql.useStartEvaluationWorkspaceForProjectCodingEditorMutation();
  const { setWorkspace } = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const { useWorkspace } = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const workspace = useWorkspace();
  React.useEffect(() => {
    if (workspace) {
      return;
    }
    if (startDevelopmentWorkspaceResult.data || startDevelopmentWorkspaceResult.fetching || args.mode !== "DEVELOPMENT") {
      return;
    }

    startDevelopmentWorkspace({
      input: {
        projectId: args.entityId,
        questionId: args.questionId,
        questionVersion: args.questionVersion,
      },
    })
      .then(res => {
        if (res.data) {
          setWorkspace(res.data.startDevelopmentWorkspace);
        }
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }, [
    args.entityId,
    args.mode,
    args.questionId,
    args.questionVersion,
    setWorkspace,
    startDevelopmentWorkspace,
    startDevelopmentWorkspaceResult,
    startDevelopmentWorkspaceResult.data,
    startDevelopmentWorkspaceResult.error,
    workspace,
  ]);

  React.useEffect(() => {
    if (workspace) {
      return;
    }
    if (startEvaluationWorkspaceResult.data || startEvaluationWorkspaceResult.fetching || args.mode !== "EVALUATION") {
      return;
    }
    startEvaluationWorkspace({
      input: {
        submissionId: args.submissionId,
      },
    })
      .then(res => {
        if (res.data) {
          setWorkspace(res.data.startEvaluationWorkspace);
        }
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  }, [
    args.mode,
    args.submissionId,
    setWorkspace,
    startEvaluationWorkspace,
    startEvaluationWorkspaceResult.data,
    startEvaluationWorkspaceResult.fetching,
    workspace,
  ]);

  const isStartWorkspaceLoading: Record<Mode, boolean> = {
    DEVELOPMENT: startDevelopmentWorkspaceResult.fetching,
    EVALUATION: startEvaluationWorkspaceResult.fetching,
  };
  const startWorkspaceError: Record<Mode, StartWorkspaceErrorStatus> = {
    DEVELOPMENT: startDevelopmentWorkspaceResult.error === undefined ? "PENDING" : "ERROR",
    EVALUATION: startEvaluationWorkspaceResult.error === undefined ? "PENDING" : "ERROR",
  };

  return {
    isStartWorkspaceLoading: isStartWorkspaceLoading[args.mode],
    startWorkspaceError: startWorkspaceError[args.mode],
  };
};
