import { QuestionSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";
import { QuestionTypesFieldArgs } from "./privateHelper";

type Variant = Extract<SearchFormProps, { kind: "MULTI_LINE" }>["variant"];

export type QuestionSearchAreaProps = {
  disabled: boolean;
  defaultValues: QuestionSearchForm.QuestionSearchQuerySchema;
  enableSkillTagFilter?: boolean;
  skillTagsField: PrivateHelper.SkillTagsFiledArgs;
  onChange: SubmitHandler<QuestionSearchForm.QuestionSearchQuerySchema>;
  onReset?: () => void;
  hasStatus?: boolean;
  /**
   * default is "INHERIT"
   */
  variant?: Variant;
  enableLiveCoding?: boolean;
  questionShowingTarget: QuestionTypesFieldArgs["showingTargets"];
};

const QuestionSearchArea: React.FC<QuestionSearchAreaProps> = props => {
  const { onChange } = props;
  const validateSchema = QuestionSearchForm.useQuestionSearchQuery();
  const methods = useForm<QuestionSearchForm.QuestionSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      ...props.defaultValues,
    },
  });
  const textFilterField = PrivateHelper.useTextFilterField();
  const questionTypesField = PrivateHelper.useQuestionTypesField({ showingTargets: props.questionShowingTarget });
  const statusesField = PrivateHelper.useStatusesField();
  const difficultyField = PrivateHelper.useDifficultyField();
  const skillTagsField = PrivateHelper.useSkillTagsField(props.skillTagsField);

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      methods.handleSubmit(onChange, console.warn)();
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);

  const searchForm: SearchFormProps = {
    kind: "MULTI_LINE",
    variant: props.variant ?? "INHERIT",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: [questionTypesField, difficultyField, props.hasStatus && statusesField, props.enableSkillTagFilter && skillTagsField].flatMap(v =>
      v ? [v] : [],
    ),
    onReset: () => {
      const resetValues: QuestionSearchForm.QuestionSearchQuerySchema = {
        questionVariants: [],
        statuses: [],
        difficulties: [],
        textFilter: "",
        skillTags: [],
      };
      methods.reset(resetValues);
      props.onReset?.();
    },
  };
  return (
    <FormProvider {...methods}>
      <SearchForm {...searchForm} />
    </FormProvider>
  );
};

QuestionSearchArea.displayName = "QuestionSearchArea";

export default QuestionSearchArea;
