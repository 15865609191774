import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";
import * as TagField from "./fields/TagsField";

export const useFileField = () => {
  const { t } = useTranslation();
  return z.object({
    id: z.string(),
    name: z.string().min(1, { message: t("名前は必須です。") }),
    fileName: z.string(),
    size: z.number(),
    rawFile: z.custom<File>(),
  });
};
export type FileFieldSchema = z.infer<ReturnType<typeof useFileField>>;

export const useResumeDocumentForm = () => {
  const file = useFileField();
  const viewer = AssignField.useAssignListItem();
  const tag = TagField.useTagItem();
  return z.object({
    resumeDocuments: file.array(),
    isPublic: z.boolean(),
    /**
     * User ID to be set when viewing privileges are changed to private.
     */
    editorUid: z.string().nullable(),
    viewers: viewer.array(),
    tags: tag.array(),
  });
};

export type ResumeDocumentFormSchema = z.infer<ReturnType<typeof useResumeDocumentForm>>;
