import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GeneratePDFViewerDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type PDFViewerDialogContainerProps = GeneratePDFViewerDialogPropsArgs;

const PDFViewerDialogContainer: React.FC<PDFViewerDialogContainerProps> = props => {
  const pdfViewerDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.PDFViewerDialog {...pdfViewerDialogProps} />
    </ErrorBoundary>
  );
};

PDFViewerDialogContainer.displayName = "PDFViewerDialogContainer";

export default withErrorBoundary(PDFViewerDialogContainer, {});
