import { useTranslation } from "@hireroo/i18n";
import { SkillMapForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import SkillTagButton, { SkillTagButtonProps } from "../../ms-components/SkillTag/SkillTagButton/SkillTagButton";

const StyledStack = styled(Stack)(({ theme }) => ({
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  overflowY: "auto",
  width: "100%",
}));
type SkillTag = Pick<SkillTagButtonProps, "label"> & { value: string };

export type SkillTagListProps = {
  searchResult: string;
  skillTags: SkillTag[];
  loading: boolean;
  emptyMessage?: string;
  onReachedEnd: () => void;
};

const SkillTagList: React.FC<SkillTagListProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<SkillMapForm.SkillMapFormSchema>();
  const selectedSkillTagsFields = useFieldArray({ control: methods.control, name: "skillTags" });
  const selectedSkillTagsSet = React.useMemo(() => {
    return new Set(selectedSkillTagsFields.fields.map(tag => tag.value));
  }, [selectedSkillTagsFields]);
  const { onReachedEnd } = props;
  const scrollRootRef = React.useRef<HTMLDivElement>(null);
  const targetRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => entry.isIntersecting && onReachedEnd());
      },
      {
        root: scrollRootRef.current,
        rootMargin: "0px",
        threshold: 0.25,
      },
    );
    const targetElement = targetRef.current;

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [onReachedEnd]);

  const skillTags = React.useMemo((): SkillTagButtonProps[] => {
    return props.skillTags.map(skillTag => {
      const isSelected = selectedSkillTagsSet.has(skillTag.value);
      return {
        status: isSelected ? "SELECTED" : "NOT_SELECTED",
        label: skillTag.label,
        onClick: () => {
          if (isSelected) {
            const targetIndex = selectedSkillTagsFields.fields.findIndex(t => t.value === skillTag.value);
            if (targetIndex >= 0) {
              selectedSkillTagsFields.remove(targetIndex);
            }
          } else {
            selectedSkillTagsFields.append({ value: skillTag.value, label: skillTag.label });
          }
        },
      };
    });
  }, [props.skillTags, selectedSkillTagsFields, selectedSkillTagsSet]);
  return (
    <Stack spacing={2}>
      <Typography fontSize={12} color="textSecondary">
        {props.searchResult}
      </Typography>
      <FormGroup sx={{ maxHeight: "calc(100dvh - 350px)" }}>
        <StyledStack direction="row" ref={scrollRootRef}>
          {!props.loading && props.skillTags.length === 0 && (
            <Typography fontSize={14} color="textSecondary">
              {props.emptyMessage ?? t("スキルタグが見つかりませんでした。")}
            </Typography>
          )}
          {skillTags.map(skillTag => (
            <SkillTagButton key={`${skillTag.label}`} {...skillTag} />
          ))}
          {/*This is the element which is reached to the end by scrolling*/}
          <Box display="flex" ref={targetRef} />
        </StyledStack>
        {props.loading && (
          <Box display="flex" justifyContent="center" alignItems="center" mt={1.5}>
            <CircularProgress size={24} color="secondary" />
          </Box>
        )}
      </FormGroup>
    </Stack>
  );
};

SkillTagList.displayName = "SkillTagList";

export default SkillTagList;
