import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import * as z from "zod";

import * as AssignField from "./fields/AssignField";
import * as TagField from "./fields/TagsField";

export const useResumeEditorForm = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const viewer = AssignField.useAssignListItem();
  const tag = TagField.useTagItem();
  return z.object({
    name: z
      .string()
      .min(1, { message: t("名前は必須です。") })
      .max(100, { message: t2("MaxStringInput", { max: 100 }) }),
    isPublic: z.boolean(),
    /**
     * User ID to be set when viewing privileges are changed to private.
     */
    editorUid: z.string().nullable(),
    viewers: viewer.array(),
    tags: tag.array(),
  });
};

export type ResumeEditorFormSchema = z.infer<ReturnType<typeof useResumeEditorForm>>;
