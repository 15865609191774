import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type SkillMapSettingsContainerProps = {};

const SkillMapSettingsContainer: React.FC<SkillMapSettingsContainerProps> = () => {
  const skillMapSettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.SkillMapSettings {...skillMapSettingsProps} />
    </ErrorBoundary>
  );
};

SkillMapSettingsContainer.displayName = "SkillMapSettingsContainer";

export default withErrorBoundary(SkillMapSettingsContainer, {});
