import { App, Auth } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { Fields } from "@hireroo/validator";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ResumeEditorContainer, { ResumeEditorContainerProps } from "./Container";

export type ResumeEditorFetchContainerProps = {};

const ResumeEditorFetchContainer: React.FC<ResumeEditorFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const currentUid = Auth.useCurrentUid();
  const resumeId = `${id}`;

  const [result, refresh] = Graphql.useGetResumeForResumesIdUpdateQuery({
    variables: {
      resumeId: resumeId,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  const viewers = React.useMemo((): Fields.AssignField.AssignListItemSchema[] => {
    const { data } = result;
    const defaultViewers = (data?.resume.viewers ?? []).reduce<Fields.AssignField.AssignListItemSchema[]>((all, viewer) => {
      switch (viewer.__typename) {
        case "EmployeeGroup": {
          return all.concat({
            value: {
              type: "EMPLOYEE_GROUP",
              groupId: viewer.employeeGroupId,
            },
          });
        }
        case "User": {
          return all.concat({
            value: {
              type: "EMPLOYEE",
              employeeId: viewer.uid,
              locked: data?.resume.createdBy?.uid === currentUid,
            },
          });
        }
        case undefined:
          return all;
        default:
          throw new Error(`Invalid type: ${viewer satisfies never}`);
      }
      return all;
    }, []);
    const hasCurrentUser = defaultViewers.some(viewer => viewer.value?.type === "EMPLOYEE" && viewer.value.employeeId === currentUid);

    return hasCurrentUser
      ? defaultViewers
      : [
          {
            value: {
              type: "EMPLOYEE",
              employeeId: currentUid,
              locked: true,
            },
          },
          ...defaultViewers,
        ];
  }, [currentUid, result]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !result.data) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ResumeEditorContainerProps = {
    defaultValues: {
      name: result.data.resume.name,
      // TODO: remove isPublic from presentation
      isPublic: result.data.resume.viewers.length === 0,
      editorUid: currentUid,
      viewers: viewers,
      tags: result.data.resume.tags.map(tag => ({ value: { name: tag.name } })),
    },
    resumeId: resumeId,
  };

  return <ResumeEditorContainer {...containerProps} />;
};

ResumeEditorFetchContainer.displayName = "ResumeEditorFetchContainer";

export default withErrorBoundary(ResumeEditorFetchContainer, {});
