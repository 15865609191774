import ApartmentOutlined from "@mui/icons-material/ApartmentOutlined";
import SchoolRounded from "@mui/icons-material/SchoolRounded";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CareerSubItems, { CareerSubItemsProps } from "../CareerSubItems/CareerSubItems";

const IconWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#78909C",
  borderRadius: "40px",
  height: "34px",
  width: "34px",
  margin: "3px",
}));

const IconMap = {
  COMPANY: <ApartmentOutlined />,
  SCHOOL: <SchoolRounded />,
} as const satisfies Record<string, React.ReactElement>;

export type CareerItemProps = {
  icon: keyof typeof IconMap;
  title: string;
  description?: string;
  duration: string;
  subSection?: CareerSubItemsProps;
};

const CareerItem: React.FC<CareerItemProps> = props => {
  return (
    <Stack>
      <Stack direction="row">
        <Box display="flex" justifyContent="center">
          <IconWrapper>{IconMap[props.icon]}</IconWrapper>
        </Box>
        <Stack spacing={0.5} px={2} py="6px">
          <Typography fontSize={16} fontWeight={700}>
            {props.title}
          </Typography>
          {props.description && <Typography fontSize={16}>{props.description}</Typography>}
          <Typography fontSize={14} color="textSecondary">
            {props.duration}
          </Typography>
        </Stack>
      </Stack>
      {props.subSection && <CareerSubItems {...props.subSection} />}
    </Stack>
  );
};

CareerItem.displayName = "CareerItem";

export default CareerItem;
