import { ResumeEditorForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import Layout, { LayoutProps } from "./parts/Layout/Layout";
import MainSection, { MainSectionProps } from "./parts/MainSection/MainSection";

export type ResumeEditorProps = {
  layout: Omit<LayoutProps, "children" | "className">;
  mainSection: MainSectionProps;
  defaultValues: ResumeEditorForm.ResumeEditorFormSchema;
};

const ResumeEditor: React.FC<ResumeEditorProps> = props => {
  const validateSchema = ResumeEditorForm.useResumeEditorForm();
  const methods = useForm<ResumeEditorForm.ResumeEditorFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  return (
    <FormProvider {...methods}>
      <Layout {...props.layout}>
        <MainSection {...props.mainSection} />
      </Layout>
    </FormProvider>
  );
};

ResumeEditor.displayName = "ResumeEditor";

export default ResumeEditor;
