import { proxy } from "valtio";
import { proxyMap, proxySet } from "valtio/utils";

import { defaultPager } from "./constants";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  resumeTags: null,
  tagRefresh: null,
  currentSearchFilter: null,
  pager: defaultPager,
  selectedResumeIds: [],
  resumeIds: [],
  dialogStatus: "CLOSE",
  refreshKey: "",
  res: null,
  refreshRes: null,
  resumeMap: proxyMap(),
  skillTagState: {
    res: null,
    currentSkillTagIdsSet: proxySet(),
    skillTagSources: proxyMap(),
    nextOffset: 0,
    textFilter: "",
  },
});
