import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateResumeTagFieldPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ResumeTagFieldContainerProps = GenerateResumeTagFieldPropsArgs;

const ResumeTagFieldContainer: React.FC<ResumeTagFieldContainerProps> = props => {
  const tagFieldProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.TagField {...tagFieldProps} />
    </ErrorBoundary>
  );
};

ResumeTagFieldContainer.displayName = "ResumeTagFieldContainer";

export default withErrorBoundary(ResumeTagFieldContainer, {});
