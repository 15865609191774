import { useEnabledResumeService } from "@hireroo/app-helper/feature";
import { Payment } from "@hireroo/app-store/essential/employee";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

const KindMap = {
  BILLING: "BILLING",
  GENERAL: "GENERAL",
  SKILL_MAP: "SKILL_MAP",
  ACCOUNT: "ACCOUNT",
  INTEGRATION: "INTEGRATION",
  NOTIFICATION: "NOTIFICATION",
  MEMBER: "MEMBER",
  PLAN_LIST: "PLAN_LIST",
} as const;

type Kind = (typeof KindMap)[keyof typeof KindMap];

type Item = Widget.UserOrCompanySettingsNavigatorProps["items"][number];

export type GenerateCompanySettingsNavigatorPropsArgs = {
  active: Kind;
};

export const useGenerateProps = (args: GenerateCompanySettingsNavigatorPropsArgs): Widget.UserOrCompanySettingsNavigatorProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const enabledResumeService = useEnabledResumeService();

  const items = React.useMemo((): Item[] => {
    const baseItems: Item[] = [
      {
        id: KindMap.ACCOUNT,
        label: t("会社設定"),
        href: generatePath("/e/settings/company/profile"),
        onClick: () => {
          navigate("/e/settings/company/profile");
        },
      },
      {
        id: KindMap.MEMBER,
        label: t("メンバー"),
        href: generatePath("/e/settings/company/employee"),
        onClick: () => {
          navigate("/e/settings/company/employee");
        },
      },
      {
        id: KindMap.BILLING,
        label: t("お支払い"),
        href: generatePath("/e/settings/billing/overview"),
        onClick: () => {
          navigate("/e/settings/billing/overview");
        },
      },
    ];
    if (enabledResumeService) {
      const resumeItem: Item = {
        id: KindMap.SKILL_MAP,
        label: t("スキルマップ"),
        href: generatePath("/e/settings/company/skillmap"),
        onClick: () => {
          navigate("/e/settings/company/skillmap");
        },
      };
      baseItems.push(resumeItem);
    }

    const planListItem: Item = {
      id: KindMap.PLAN_LIST,
      label: t("プラン一覧"),
      href: generatePath("/e/settings/plan/list"),
      onClick: () => {
        navigate("/e/settings/plan/list");
      },
    };
    baseItems.push(planListItem);

    if (isAvailableFeature("test.notifications.slack.create")) {
      const slackItem: Item = {
        id: KindMap.NOTIFICATION,
        label: t("通知"),
        href: generatePath("/e/settings/company/notification/slack"),
        onClick: () => {
          navigate("/e/settings/company/notification/slack");
        },
      };
      baseItems.push(slackItem);
    }
    if (isAvailableFeature("integrations.ats.read")) {
      const integrationItem: Item = {
        id: KindMap.INTEGRATION,
        label: t("連携"),
        href: generatePath("/e/settings/company/integration/sonar"),
        onClick: () => {
          navigate("/e/settings/company/integration/sonar");
        },
      };
      baseItems.push(integrationItem);
    }

    return baseItems;
  }, [enabledResumeService, isAvailableFeature, navigate, t]);
  return {
    active: args.active,
    items: items,
  };
};
