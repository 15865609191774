import type { Widget } from "@hireroo/presentation";

export type GeneratePDFViewerDialogPropsArgs = {
  onClose: () => void;
  resumeItem: {
    id: string;
    title: string;
  };
  url: string;
};

export const useGenerateProps = (args: GeneratePDFViewerDialogPropsArgs): Widget.PDFViewerDialogProps => {
  const { resumeItem } = args;

  return {
    dialog: {
      open: true,
      onClose: args.onClose,
    },
    loading: false,
    title: resumeItem.title,
    dataSrc: args.url,
  };
};
