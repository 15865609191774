import { ChallengePad } from "@hireroo/app-helper/hooks";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { languageMapForHighlight } from "@hireroo/challenge/definition";
import * as Time from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import ChallengeCodingEditorRightSidePanelContainer from "./widget/ChallengeCodingEditorRightSidePanel/Container";
import ChallengePlaybackEditorContainer, { ChallengePlaybackEditorContainerProps } from "./widget/ChallengePlaybackEditor/Container";

type CodeEditor = Widget.ChallengeCodingEditorProps["editorAndTestCases"]["CodeEditors"][0];

export type GenerateChallengeCodingEditorPropsArgs = {
  entityId: number;
  editorKind: "CANDIDATE" | "EMPLOYEE";
  interviewKind: "INTERVIEW" | "DEMO";
  uid: string;
  displayName: string;
  collaborativeState: ChallengePad.CollaborativeState;
  collaborativeAction: ChallengePad.CollaborativeAction;
  onMount?: ChallengePlaybackEditorContainerProps["editorDidMount"];
};

type QuestionHintStack = Widget.ChallengeCodingEditorProps["content"]["sidebar"]["questionHintStack"];
type QuestionHintStackItem = Exclude<QuestionHintStack, undefined>["items"][0];

const DELIMITER = ",";

export const useGenerateProps = (args: GenerateChallengeCodingEditorPropsArgs): Widget.ChallengeCodingEditorProps => {
  const { entityId, uid, displayName, collaborativeState, collaborativeAction } = args;
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const challengeEntityHooks = ChallengeCodingEditor.useCreateChallengeEntityHooks(entityId);
  const challengeEntityAction = ChallengeCodingEditor.createChallengeEntityAction(entityId);
  const enableRightSidePanel = ChallengeCodingEditor.useEnableRightSidePanel();
  const enabledHint = ChallengeCodingEditor.useEnableHint();
  const entity = challengeEntityHooks.useEntity();
  const question = challengeEntityHooks.useQuestion();
  const testCaseResults = challengeEntityHooks.useTestCaseResults();
  const appealMessage = challengeEntityHooks.useAppealMessage();
  // const outputStatusMap = challengeEntityHooks.useOutputStatusMap();
  const { testCases, selectedTestCaseIndex, selectedLanguage, updateSelectedLanguage, isLanguageChanged } =
    Widget.useChallengeCodingEditorContext();
  const selectedTestCaseResult = testCaseResults.get(selectedTestCaseIndex);

  const [code, setCode] = React.useState<string>("");
  const [submittedStatus, setSubmitStatus] = React.useState<"READY" | "SUBMITTING">("READY");
  const [runStatus, setRunStatus] = React.useState<"READY" | "RUNNING">("READY");
  const [_appealMessageStatus, setAppealMessageStatus] = React.useState<"READY" | "PENDING">("READY");
  const usedHintSet = challengeEntityHooks.useUsedHintIdsSet();

  const webSession = challengeEntityHooks.useWebSession();
  const chatGPTSession = challengeEntityHooks.useChatGPTSession();

  const runCode = React.useCallback(
    async (requestId: number, inputs: string[], sourceCode?: string) => {
      setRunStatus("RUNNING");
      ChallengeCodingEditor.updateLoadingStatus("LOADING");
      challengeEntityAction.setLoadingOutputStatus(requestId);
      return await client
        .ChallengeRunCode({
          questionId: question?.questionId ?? 0,
          questionVersion: question?.version ?? "",
          challengeId: entityId,
          runtime: collaborativeState.selectedLanguage,
          codeBody: sourceCode ?? code,
          input: inputs.join(DELIMITER),
          takeSnapshot: true,
        })
        .then(res => {
          if (res.challengeRunCode) {
            challengeEntityAction.setTestcaseResult(requestId, res.challengeRunCode);
            collaborativeAction.runCode(res.challengeRunCode.snapshotId);
            Snackbar.notify({
              severity: "success",
              message: t("実行完了。出力を確認してください。"),
            });
          }
        })
        .catch(e => {
          challengeEntityAction.setRejectedOutputStatus(requestId);
          Snackbar.notify({
            severity: "error",
            message: t("実行失敗。エラーを確認し再度お試しください。"),
          });
          Sentry.captureException(e);
        });
    },
    [
      challengeEntityAction,
      client,
      code,
      collaborativeAction,
      collaborativeState.selectedLanguage,
      entityId,
      question?.questionId,
      question?.version,
      t,
    ],
  );

  const runAll = React.useCallback(
    async (sourceCode?: string) => {
      await Promise.all(
        testCases.map(async (testcase, index) => {
          return await runCode(index, testcase.inputs, sourceCode);
        }),
      ).finally(() => {
        setRunStatus("READY");
        ChallengeCodingEditor.updateLoadingStatus("NONE");
      });
    },
    [runCode, testCases],
  );

  const Editors = Object.keys(languageMapForHighlight).map((language): CodeEditor => {
    const editor: ChallengePlaybackEditorContainerProps = {
      firepad: {
        entityId: entityId,
        questionId: question?.questionId ?? 0,
        language: language,
        uid: uid,
        displayName: displayName,
      },
      editorValueDidChange: setCode,
      editorDidMount: (editor, monaco) => {
        args.onMount?.(editor, monaco);
        // command + Enter
        editor.addAction({
          id: "run",
          label: "run",
          keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
          run: e => {
            const value = e.getValue();
            runAll(value);
          },
        });
      },
      defaultValue: ((question?.initialCode as Record<string, string>) || {})[language] ?? "",
    };
    return {
      language: language,
      Content: <ChallengePlaybackEditorContainer {...editor} />,
    };
  });

  const outputResultMap =
    React.useMemo((): Widget.ChallengeCodingEditorProps["editorAndTestCases"]["console"]["testCaseButtonGroups"]["outputResultMap"] => {
      const resultMap = new Map<number, "SUCCESS" | "FAILED">();
      Array.from(testCaseResults.keys()).map(key => {
        const result = testCaseResults.get(key);
        if (result) {
          resultMap.set(key, result.isAccepted ? "SUCCESS" : "FAILED");
        }
      });

      return Object.fromEntries(resultMap);
    }, [testCaseResults]);

  const questionHintStack: QuestionHintStack = React.useMemo((): QuestionHintStack => {
    if (!enabledHint || !question) return undefined;

    const items: QuestionHintStackItem[] = (question.hints || []).map((hint, index) => {
      // there are two state of usedHints because collaborativeState doesn't rerender
      // need to refactor collaborativeState
      if (usedHintSet.has(hint.id) || collaborativeState.usedHints.has(hint.id)) {
        return {
          mode: "unlocked",
          value: {
            disabled: false,
            title: t2("Hint", { num: index + 1 }),
            description: resolveLanguage(hint, lang, "description"),
          },
        };
      }

      return {
        mode: "locked",
        value: {
          disabled: false,
          title: t2("Hint", { num: index + 1 }),
          onUnlocked: () => {
            collaborativeAction.useHint(hint.id);
            challengeEntityAction.appendUsedHintId(hint.id);
          },
        },
      };
    });

    return {
      items,
    };
  }, [challengeEntityAction, collaborativeAction, collaborativeState.usedHints, enabledHint, lang, question, t2, usedHintSet]);

  return {
    content: {
      sidebar: {
        questionSection: {
          title: resolveLanguage(question || {}, lang, "title"),
          description: resolveLanguage(question || {}, lang, "description"),
          /**
           * Always `false` as the user does not need to know if the issue has been archived during testing
           */
          isArchived: false,
        },
        questionHintStack: questionHintStack,
        appealMessage: {
          showing: true,
          placeholder: t(
            "提出するコードに説明を加えることができます。実装に至るまでの思考プロセスや各処理の詳細な説明などを自由に記述してください。",
          ),
          onChange: value => {
            setAppealMessageStatus("PENDING");
            client
              .SaveChallengeAppealMessage({
                challengeId: entityId,
                appealMessage: value,
              })
              .then(res => {
                if (res.saveChallengeAppealMessage?.appealMessage) {
                  challengeEntityAction.updateAppealMessage(res.saveChallengeAppealMessage.appealMessage);
                }
                Snackbar.notify({
                  severity: "success",
                  message: t("説明文の保存に成功しました。"),
                });
              })
              .catch(() => {
                Snackbar.notify({
                  severity: "error",
                  message: t("説明文の保存に失敗しました。しばらくしてから再度お試しいただくか、運営までお問い合わせ下さい。"),
                });
              })
              .finally(() => {
                setAppealMessageStatus("READY");
              });
          },
          value: appealMessage ?? "",
        },
      },
    },
    editorAndTestCases: {
      CodeEditors: Editors,
      editorToolbar: {
        runtimeSelector: isLanguageChanged
          ? {
              enabledLanguages: [...entity.enabledLanguages],
              onChange: event => {
                collaborativeAction.setSelectedLanguageWrapper(event.target.value);
              },
            }
          : undefined,
        runtimeSelectorDialog: {
          value: isLanguageChanged ? selectedLanguage : "",
          baseDialog: {
            open: !isLanguageChanged,
          },
          enabledLanguages: [...entity.enabledLanguages],
          onSubmit: value => {
            updateSelectedLanguage(value);
            collaborativeAction.setSelectedLanguageWrapper(value);
          },
        },
      },
      console: {
        testCaseButtonGroups: {
          resetButton: {
            onClick: () => {
              challengeEntityAction.clearTestcaseResult();
            },
          },
          isLoading: runStatus === "RUNNING",
          outputResultMap: runStatus === "RUNNING" ? undefined : outputResultMap,
        },
        toolbar: {
          runButton: {
            onClick: () => {
              runAll();
            },
            disabled: runStatus === "RUNNING" || submittedStatus === "SUBMITTING",
          },
          submitButton: {
            onClick: () => {
              if (!question) return;
              setSubmitStatus("SUBMITTING");
              //submit
              client
                .ChallengeSubmitCode({
                  challengeId: entityId,
                  questionId: question.questionId,
                  questionVersion: question.version,
                  runtime: collaborativeState.selectedLanguage,
                  codeBody: code,
                })
                .then(() => {
                  Snackbar.notify({
                    severity: "success",
                    message: t("お疲れ様でした！未提出の問題がある場合は次に進んでください。"),
                  });
                  collaborativeAction.submitQuestion(collaborativeState.selectedQuestion);
                  ChallengeCodingEditor.setSubmittedEntity(entity);
                })
                .catch(e => {
                  Snackbar.notify({
                    severity: "error",
                    message: t("問題の提出に失敗しました。再度お試しください。"),
                  });
                  Sentry.captureException(e);
                })
                .finally(() => {
                  setSubmitStatus("READY");
                });
            },
            disabled: runStatus === "RUNNING" || args.editorKind === "EMPLOYEE" || submittedStatus === "SUBMITTING",
            loading: submittedStatus === "SUBMITTING",
          },
        },
        outputConsole: {
          isLoading: runStatus === "RUNNING",
          output: selectedTestCaseResult && {
            expected: selectedTestCaseResult.expected,
            isAccepted: selectedTestCaseResult.isAccepted,
            output: selectedTestCaseResult.output,
            log: selectedTestCaseResult.log,
            status: runStatus === "RUNNING" ? "RUNNING" : selectedTestCaseResult.status,
            performanceNanoSeconds: `${Time.formatTime(selectedTestCaseResult.performance, Time.Unit.NANOSECOND)}`,
            maxMemoryMb: Math.floor(selectedTestCaseResult.maxMemory),
          },
        },
      },
    },
    RightSidePanel: enableRightSidePanel ? (
      <ChallengeCodingEditorRightSidePanelContainer webSession={webSession} chatGPTSession={chatGPTSession} />
    ) : null,
  };
};
