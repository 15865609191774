import * as Graphql from "@hireroo/graphql/client/urql";
import { QuestionSearchForm } from "@hireroo/validator";

const PROJECT_NUMBER_OF_CHILDREN = 3;
const CHALLENGE_NUMBER_OF_CHILDREN = 2;

const isParentQuestionVariant = (selected: Graphql.QuestionVariant): boolean => {
  return selected === Graphql.QuestionVariant.Challenge || selected === Graphql.QuestionVariant.Project;
};

const isChildQuestionVariant = (selected: Graphql.QuestionVariant): boolean => {
  return (
    selected === Graphql.QuestionVariant.ProjectBackend ||
    selected === Graphql.QuestionVariant.ProjectFrontend ||
    selected === Graphql.QuestionVariant.ProjectOther ||
    selected === Graphql.QuestionVariant.ChallengeAlgorithm ||
    selected === Graphql.QuestionVariant.ChallengeDatabase ||
    selected === Graphql.QuestionVariant.ChallengeClass
  );
};

const noChildIsSelected = (parent: Graphql.QuestionVariant, selectedOptions: readonly Graphql.QuestionVariant[]): boolean => {
  if (parent === Graphql.QuestionVariant.Challenge) {
    return selectedOptions.every(option => {
      return (
        option !== Graphql.QuestionVariant.ChallengeAlgorithm &&
        option !== Graphql.QuestionVariant.ChallengeDatabase &&
        option !== Graphql.QuestionVariant.ChallengeClass
      );
    });
  }

  if (parent === Graphql.QuestionVariant.Project) {
    return selectedOptions.every(option => {
      return (
        option !== Graphql.QuestionVariant.ProjectBackend &&
        option !== Graphql.QuestionVariant.ProjectFrontend &&
        option !== Graphql.QuestionVariant.ProjectOther
      );
    });
  }

  return false;
};

const noParentIsSelected = (child: Graphql.QuestionVariant, selectedOptions: readonly Graphql.QuestionVariant[]): boolean => {
  if (
    child === Graphql.QuestionVariant.ChallengeAlgorithm ||
    child === Graphql.QuestionVariant.ChallengeDatabase ||
    child === Graphql.QuestionVariant.ChallengeClass
  ) {
    return !selectedOptions.includes(Graphql.QuestionVariant.Challenge);
  }

  if (
    child === Graphql.QuestionVariant.ProjectBackend ||
    child === Graphql.QuestionVariant.ProjectFrontend ||
    child === Graphql.QuestionVariant.ProjectOther
  ) {
    return !selectedOptions.includes(Graphql.QuestionVariant.Project);
  }

  return false;
};

const parentOf = (child: Graphql.QuestionVariant): Graphql.QuestionVariant | undefined => {
  if (
    child === Graphql.QuestionVariant.ProjectBackend ||
    child === Graphql.QuestionVariant.ProjectFrontend ||
    child === Graphql.QuestionVariant.ProjectOther
  ) {
    return Graphql.QuestionVariant.Project;
  }

  if (
    child === Graphql.QuestionVariant.ChallengeAlgorithm ||
    child === Graphql.QuestionVariant.ChallengeDatabase ||
    child === Graphql.QuestionVariant.ChallengeClass
  ) {
    return Graphql.QuestionVariant.Challenge;
  }

  return undefined;
};

const isChildOf = (parent: Graphql.QuestionVariant, value: Graphql.QuestionVariant): boolean => {
  if (parent === Graphql.QuestionVariant.Project) {
    return (
      value === Graphql.QuestionVariant.ProjectBackend ||
      value === Graphql.QuestionVariant.ProjectFrontend ||
      value === Graphql.QuestionVariant.ProjectOther
    );
  }

  if (parent === Graphql.QuestionVariant.Challenge) {
    return (
      value === Graphql.QuestionVariant.ChallengeAlgorithm ||
      value === Graphql.QuestionVariant.ChallengeDatabase ||
      value === Graphql.QuestionVariant.ChallengeClass
    );
  }

  return false;
};

export const filterSelectedQuestionVariant = (selectedQuestionVariants: readonly Graphql.QuestionVariant[]): Graphql.QuestionVariant[] => {
  const newQuestionVariants = [...selectedQuestionVariants];

  for (const selected of selectedQuestionVariants) {
    // If a parent question option is selected but no children are selected, delete parent
    if (isParentQuestionVariant(selected) && noChildIsSelected(selected, selectedQuestionVariants)) {
      const indexOfChildlessParent = newQuestionVariants.indexOf(selected);
      newQuestionVariants.splice(indexOfChildlessParent, 1);
    }
    // If a child question option is selected but no parent is selected, delete other children
    if (isChildQuestionVariant(selected) && noParentIsSelected(selected, selectedQuestionVariants)) {
      const parent = parentOf(selected);
      if (parent && !newQuestionVariants.includes(parent)) {
        return newQuestionVariants.filter(value => !isChildOf(parent, value));
      }
    }
  }

  return newQuestionVariants;
};

const allChildrenAreSelected = (parent: Graphql.QuestionVariant, selectedQuestionVariants: readonly Graphql.QuestionVariant[]): boolean => {
  if (parent === Graphql.QuestionVariant.Project) {
    const children = selectedQuestionVariants.filter(q => isChildOf(Graphql.QuestionVariant.Project, q));
    if (children.length === PROJECT_NUMBER_OF_CHILDREN) {
      return true;
    }
  }

  if (parent === Graphql.QuestionVariant.Challenge) {
    const children = selectedQuestionVariants.filter(q => isChildOf(Graphql.QuestionVariant.Challenge, q));
    if (children.length === CHALLENGE_NUMBER_OF_CHILDREN) {
      return true;
    }
  }

  return false;
};

export const filterToCondensedQuestionVariantGroups = (
  selectedQuestionVariants: readonly Graphql.QuestionVariant[],
): Graphql.QuestionVariant[] => {
  let newQuestionVariants = [...selectedQuestionVariants];

  for (const selected of selectedQuestionVariants) {
    // If a parent question option is selected and all its children are selected, filter out children
    if (isParentQuestionVariant(selected) && allChildrenAreSelected(selected, selectedQuestionVariants)) {
      newQuestionVariants = newQuestionVariants.filter(q => !isChildOf(selected, q));
    }
    // If a child question option is selected but no parent is selected, delete other children
    if (isChildQuestionVariant(selected)) {
      const parent = parentOf(selected);
      if (parent && !allChildrenAreSelected(parent, selectedQuestionVariants)) {
        newQuestionVariants = newQuestionVariants.filter(value => value !== parent);
      }
    }
  }

  return newQuestionVariants;
};

export const SortFieldValueSortingMap: Record<QuestionSearchForm.SortFieldValue, string> = {
  "accuracy-rate-descending": "ACCURACY_RATE-true",
  "accuracy-rate-ascending": "ACCURACY_RATE-false",
  "leak-score-descending": "LEAK_SCORE-true",
  "leak-score-ascending": "LEAK_SCORE-false",
  "average-elapsed-time-descending": "AVERAGE_ELAPSED_TIME-true",
  "average-elapsed-time-ascending": "AVERAGE_ELAPSED_TIME-false",
  "created-at-descending": "CREATED_AT-true",
  "created-at-ascending": "CREATED_AT-false",
  "num-uses-descending": "NUM_USES-true",
  "num-uses-ascending": "NUM_USES-false",
  "unknown-descending": "UNKNOWN-true",
  "unknown-ascending": "UNKNOWN-false",
};
