/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
//
// Generated by @himenon/openapi-typescript-code-generator v1.0.9
//
// OpenApi : 3.1.0
//
// License  : UNLICENCED
//

export namespace Schemas {
  export type RequestFormData = FormData;
  export interface UploadResumesResponse {
    results: {
      fileId: string;
      status: "SUCCESS" | "FAILED";
      failureReason?: "INVALID_FILE_FORMAT" | "FILE_COUNT_EXCEEDED" | "FILE_TOO_LARGE" | "SYSTEM_ERROR" | "UNKNOWN";
    }[];
  }
  export interface AddResumeFilesResponse {
    results: {
      fileId: string;
      status: "SUCCESS" | "FAILED";
      failureReason?: "INVALID_FILE_FORMAT" | "FILE_COUNT_EXCEEDED" | "FILE_TOO_LARGE" | "SYSTEM_ERROR" | "UNKNOWN";
    }[];
  }
  export interface MulterUploadInvalidResponse {
    errorStatus: "LIMIT_FILE_COUNT" | "LIMIT_FILE_SIZE" | "INVALID_REQUEST";
  }
}
export interface RequestBody$uploadProfile {
  "multipart/form-data": Schemas.RequestFormData;
}
export interface Response$uploadProfile$Status$200 {
  "application/json": {
    status: "UPLOADED" | "SKIPPED";
  };
}
export interface RequestBody$uploadProfiles {
  "multipart/form-data": Schemas.RequestFormData;
}
export interface Response$uploadProfiles$Status$200 {
  "application/json": {
    status: "UPLOADED" | "SKIPPED";
  };
}
export interface RequestBody$uploadResumes {
  "multipart/form-data": Schemas.RequestFormData;
}
export interface Response$uploadResumes$Status$200 {
  "application/json": Schemas.UploadResumesResponse;
}
export interface Response$uploadResumes$Status$400 {
  "application/json": Schemas.MulterUploadInvalidResponse;
}
export interface RequestBody$uploadAddResumeFiles {
  "multipart/form-data": Schemas.RequestFormData;
}
export interface Response$uploadAddResumeFiles$Status$200 {
  "application/json": Schemas.AddResumeFilesResponse;
}
export interface Response$uploadAddResumeFiles$Status$400 {
  "application/json": Schemas.MulterUploadInvalidResponse;
}
export interface Parameter$getUploadedResumeItemFile {
  resumeItemId: string;
}
export interface Response$getUploadedResumeItemFile$Status$200 {
  "application/pdf": Blob;
}
export type RequestContentType$uploadProfile = keyof RequestBody$uploadProfile;
export type ResponseContentType$uploadProfile = keyof Response$uploadProfile$Status$200;
export interface Params$uploadProfile {
  requestBody: RequestBody$uploadProfile["multipart/form-data"];
}
export type RequestContentType$uploadProfiles = keyof RequestBody$uploadProfiles;
export type ResponseContentType$uploadProfiles = keyof Response$uploadProfiles$Status$200;
export interface Params$uploadProfiles {
  requestBody: RequestBody$uploadProfiles["multipart/form-data"];
}
export type RequestContentType$uploadResumes = keyof RequestBody$uploadResumes;
export type ResponseContentType$uploadResumes = keyof Response$uploadResumes$Status$200;
export interface Params$uploadResumes {
  requestBody: RequestBody$uploadResumes["multipart/form-data"];
}
export type RequestContentType$uploadAddResumeFiles = keyof RequestBody$uploadAddResumeFiles;
export type ResponseContentType$uploadAddResumeFiles = keyof Response$uploadAddResumeFiles$Status$200;
export interface Params$uploadAddResumeFiles {
  requestBody: RequestBody$uploadAddResumeFiles["multipart/form-data"];
}
export type ResponseContentType$getUploadedResumeItemFile = keyof Response$getUploadedResumeItemFile$Status$200;
export interface Params$getUploadedResumeItemFile {
  parameter: Parameter$getUploadedResumeItemFile;
}
export type HttpMethod = "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH" | "TRACE";
export interface ObjectLike {
  [key: string]: any;
}
export interface QueryParameter {
  value: any;
  style?: "form" | "spaceDelimited" | "pipeDelimited" | "deepObject";
  explode: boolean;
}
export interface QueryParameters {
  [key: string]: QueryParameter;
}
export type SuccessResponses =
  | Response$uploadProfile$Status$200
  | Response$uploadProfiles$Status$200
  | Response$uploadResumes$Status$200
  | Response$uploadAddResumeFiles$Status$200
  | Response$getUploadedResumeItemFile$Status$200;
export namespace ErrorResponse {
  export type uploadProfile = void;
  export type uploadProfiles = void;
  export type uploadResumes = Response$uploadResumes$Status$400;
  export type uploadAddResumeFiles = Response$uploadAddResumeFiles$Status$400;
  export type getUploadedResumeItemFile = void;
}
export interface Encoding {
  readonly contentType?: string;
  headers?: Record<string, any>;
  readonly style?: "form" | "spaceDelimited" | "pipeDelimited" | "deepObject";
  readonly explode?: boolean;
  readonly allowReserved?: boolean;
}
export interface RequestArgs {
  readonly httpMethod: HttpMethod;
  readonly url: string;
  headers: ObjectLike | any;
  requestBody?: ObjectLike | any;
  requestBodyEncoding?: Record<string, Encoding>;
  queryParameters?: QueryParameters | undefined;
}
export interface ApiClient<RequestOption> {
  request: <T = SuccessResponses>(requestArgs: RequestArgs, options?: RequestOption) => Promise<T>;
}
export const createClient = <RequestOption>(apiClient: ApiClient<RequestOption>, baseUrl: string) => {
  const _baseUrl = baseUrl.replace(/\/$/, "");
  return {
    /** PDFファイルを1つだけアップロードする */
    uploadProfile: (params: Params$uploadProfile, option?: RequestOption): Promise<Response$uploadProfile$Status$200["application/json"]> => {
      const url = _baseUrl + `/upload/profile`;
      const headers = {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      };
      return apiClient.request(
        {
          httpMethod: "POST",
          url,
          headers,
          requestBody: params.requestBody,
        },
        option,
      );
    },
    /** PDFファイルを複数アップロードする */
    uploadProfiles: (
      params: Params$uploadProfiles,
      option?: RequestOption,
    ): Promise<Response$uploadProfiles$Status$200["application/json"]> => {
      const url = _baseUrl + `/upload/profiles`;
      const headers = {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      };
      return apiClient.request(
        {
          httpMethod: "POST",
          url,
          headers,
          requestBody: params.requestBody,
        },
        option,
      );
    },
    /** PDFファイルを複数アップロードする */
    uploadResumes: (params: Params$uploadResumes, option?: RequestOption): Promise<Response$uploadResumes$Status$200["application/json"]> => {
      const url = _baseUrl + `/upload/resumes`;
      const headers = {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      };
      return apiClient.request(
        {
          httpMethod: "POST",
          url,
          headers,
          requestBody: params.requestBody,
        },
        option,
      );
    },
    /** 特定のresumeにPDFファイルを複数アップロードする */
    uploadAddResumeFiles: (
      params: Params$uploadAddResumeFiles,
      option?: RequestOption,
    ): Promise<Response$uploadAddResumeFiles$Status$200["application/json"]> => {
      const url = _baseUrl + `/upload/addResumeFiles`;
      const headers = {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      };
      return apiClient.request(
        {
          httpMethod: "POST",
          url,
          headers,
          requestBody: params.requestBody,
        },
        option,
      );
    },
    /** アップロードされたファイルを取得する */
    getUploadedResumeItemFile: (
      params: Params$getUploadedResumeItemFile,
      option?: RequestOption,
    ): Promise<Response$getUploadedResumeItemFile$Status$200["application/pdf"]> => {
      const url = _baseUrl + `/getUploadedResumeItemFile/${encodeURIComponent(params.parameter.resumeItemId)}`;
      const headers = {
        Accept: "application/pdf",
      };
      return apiClient.request(
        {
          httpMethod: "GET",
          url,
          headers,
        },
        option,
      );
    },
  };
};
type ClientFunction<RequestOption> = typeof createClient<RequestOption>;
export type Client<RequestOption> = ReturnType<ClientFunction<RequestOption>>;
