import type * as Graphql from "@hireroo/graphql/client/urql";

export type SkillTagRes = Graphql.HierarchicalSkillTagsResponseForQuestionSearchAreaFragment;

export type SkillTag = Graphql.SkillTagNodeForQuestionSearchAreaFragment;

export type SkillTagId = string;

export type SkillTagState = {
  res: SkillTagRes | null;
  currentSkillTagIdsSet: Set<SkillTagId>;
  skillTagSources: Map<SkillTagId, SkillTag>;
  nextOffset: number;
  textFilter: string;
};

export type State = {
  isDescending: boolean;
  page: number;
  size: number;
  sortMethod: Graphql.SortMethod;
  filters: Graphql.Filters;
  skillTagState: SkillTagState;
};

// The keys of query params
export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  TITLE: "title",
  STATUS: "status",
  QUESTION_VARIANT: "questionVariant",
  DIFFICULTY: "difficulty",
  SKILL_TAG: "skillTag",
  SORT: "sorting",
} as const;
export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
