import { useTranslation } from "@hireroo/i18n";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorRounded from "@mui/icons-material/ErrorRounded";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DeleteDialog, { DeleteDialogProps } from "../../../../modules/DeleteDialog/DeleteDialog";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "4px 8px",
}));

type Status = "ANALYZING" | "ANALYZED" | "ERROR";

type DialogController = {
  onClose: () => void;
  setLoading: (loading: boolean) => void;
};

export type DocumentTableRowProps = {
  id: string;
  docNumber: number;
  fileName: string;
  previewButton: Pick<IconButtonWithTooltipProps, "onClick">;
  size: string;
  status: Status;
  deleteButton: Pick<IconButtonWithTooltipProps, "disabled" | "title">;
  deleteDialog: {
    onSubmit: (controller: DialogController) => void;
  };
  hideBorderBottom?: boolean;
  errorMassage?: string;
};

const DocumentTableRow: React.FC<DocumentTableRowProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    onClick: () => {
      setOpen(true);
    },
    size: "small",
    children: <DeleteOutlineIcon fontSize="small" />,
  };
  const previewButton: IconButtonWithTooltipProps = {
    ...props.previewButton,
    title: t("書類のプレビュー"),
    size: "small",
    children: <VisibilityIcon fontSize="small" />,
  };
  const StatusMap: Record<Status, React.ReactNode> = {
    ANALYZED: (
      <Stack direction="row" alignItems="center" spacing={1}>
        <CheckIcon fontSize="small" htmlColor={theme.palette.text.secondary} />
        <Typography fontSize={13} color="textSecondary">
          {t("解析済み")}
        </Typography>
      </Stack>
    ),
    ANALYZING: (
      <Stack direction="row" alignItems="center" spacing={1}>
        <GraphicEqIcon fontSize="small" htmlColor={theme.palette.text.secondary} />
        <Typography fontSize={13} color="textSecondary">
          {t("解析中")}
        </Typography>
      </Stack>
    ),
    ERROR: (
      <Stack direction="row" alignItems="center" spacing={1}>
        <ErrorRounded fontSize="small" color="error" />
        <Typography fontSize={13} color="error">
          {t("解析失敗")}
        </Typography>
      </Stack>
    ),
  };
  const { onSubmit } = props.deleteDialog;
  const deleteDialog: DeleteDialogProps = {
    dialog: {
      open: open,
      yesButton: {
        onClick: () => {
          const controller: DialogController = {
            onClose: () => {
              setOpen(false);
            },
            setLoading: setLoading,
          };
          onSubmit(controller);
        },
        disabled: loading,
      },
      noButton: {
        onClick: () => {
          setOpen(false);
        },
        disabled: loading,
      },
    },
    items: [{ name: t("一度削除すると元に戻すことはできません。") }],
    name: props.fileName,
  };
  const bgColorMap: Record<Status, string | undefined> = {
    ANALYZED: undefined,
    ANALYZING: undefined,
    ERROR: theme.palette["Error/Shades"].p8,
  };
  return (
    <>
      <StyledTableRow
        sx={{
          backgroundColor: bgColorMap[props.status],
          borderBottom: props.status === "ERROR" || props.hideBorderBottom ? "none" : undefined,
        }}
      >
        <StyledTableCell>
          <Typography fontSize={14} color="textSecondary">
            {t("書類")}
            {props.docNumber}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography fontSize={14}>{props.fileName}</Typography>
            <IconButtonWithTooltip {...previewButton} />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Typography fontSize={14}>{props.size}</Typography>
        </StyledTableCell>
        <StyledTableCell>{StatusMap[props.status]}</StyledTableCell>
        <StyledTableCell align="right">
          <IconButtonWithTooltip {...deleteButton} />
        </StyledTableCell>
      </StyledTableRow>
      {props.errorMassage && (
        <StyledTableRow sx={{ backgroundColor: bgColorMap[props.status], borderBottom: props.hideBorderBottom ? "none" : undefined }}>
          <StyledTableCell colSpan={5}>
            <Stack direction="row" spacing={1} alignItems="center">
              <ErrorRounded fontSize="small" color="error" sx={{ height: "16px", width: "16px" }} />
              <Typography fontSize={12} color="error">
                {props.errorMassage}
              </Typography>
            </Stack>
          </StyledTableCell>
        </StyledTableRow>
      )}
      <DeleteDialog {...deleteDialog} />
    </>
  );
};

DocumentTableRow.displayName = "DocumentTableRow";

export default DocumentTableRow;
