import { ResumesIdDetail } from "@hireroo/app-store/page/e/resumes_id_detail";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";

type General = Widget.ResumeDetailProps["general"];

export const useGenerateGeneralProps = (): General => {
  const resume = ResumesIdDetail.useResume();
  const navigate = useTransitionNavigate();
  return {
    generalSummary: {
      tags: resume.tags.map(t => t.name),
      editTabButton: {
        onClick: () => {
          navigate("/e/resumes/:id/update", { pathParams: { id: resume.resumeId } });
        },
      },
      accessPermission:
        resume.viewers.length > 0
          ? {
              kind: "PRIVATE",
              authorizedMemberAndGroups: resume.viewers.map(viewer => {
                const type = viewer.__typename;
                switch (type) {
                  case "EmployeeGroup":
                    return {
                      label: viewer.groupName,
                      kind: "GROUP",
                    };
                  case "User":
                    return {
                      label: viewer.displayName || viewer.email,
                      photoUrl: viewer.photoUrl,
                      kind: "MEMBER",
                    };
                  default:
                    throw new Error(`Unexpected viewer type: ${type satisfies never}`);
                }
              }),
            }
          : {
              kind: "PUBLIC",
            },
      editAccessPermissionButton: {
        onClick: () => {
          navigate("/e/resumes/:id/update", { pathParams: { id: resume.resumeId } });
        },
      },
    },
  };
};
