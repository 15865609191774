import { useEnabledQuestionSkillTagSearch } from "@hireroo/app-helper/feature";
import { FeedbackLinkFactory, QuestionStats } from "@hireroo/app-helper/question";
import { useTitle } from "@hireroo/app-helper/react-use";
import * as SkillTagHelper from "@hireroo/app-helper/skill-tag";
import { Credential } from "@hireroo/app-store/essential/shared";
import { formateRateToIntegerPercent } from "@hireroo/formatter/rate";
import { secondsToTimeObject } from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as ProjectHelpers from "@hireroo/project/helpers/fileTree";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useImmer } from "use-immer";

import { useInitialFileNode } from "./privateHelpers";

type AnswerSectionProps = Widget.ProjectQuestionDetailProps["answerSections"][0];

export type GenerateProjectQuestionDetailPropsArgs = {
  questionDetail: Graphql.ProjectQuestionDetailFragment;
};

export const useGenerateProps = (args: GenerateProjectQuestionDetailPropsArgs): Widget.ProjectQuestionDetailProps => {
  const lang = useLanguageCode();
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const { t } = useTranslation();
  const { questionDetail } = args;
  const { answers } = questionDetail;
  const answer = answers.length > 0 ? answers[0] : undefined;
  const fileIndexList = answer?.fileIndexList || [];
  const client = getGraphqlClient();
  const initialFileNode = useInitialFileNode(fileIndexList);
  const [selectedSourceFile, setSelectedFile] = React.useState<string>();
  const [fileTree, setFileTree] = useImmer<ProjectHelpers.FileNode>(initialFileNode);
  const [filesOpened, setFilesOpened] = React.useState<string[]>([]);
  const editorValue = React.useMemo(() => {
    if (!selectedSourceFile) {
      return null;
    }
    return ProjectHelpers.findNode(fileTree, selectedSourceFile)?.value ?? null;
  }, [fileTree, selectedSourceFile]);
  const enabledQuestionSkillTagSearch = useEnabledQuestionSkillTagSearch();
  const skillTagUniqueNameMap = SkillTagHelper.generateUniqueNameMapFromPath(
    questionDetail.skillTags.map(skillTag => ({
      id: skillTag.skillTagNodeId,
      name: skillTag.name,
      path: skillTag.path,
    })),
  );

  useTitle(resolveLanguage(questionDetail, lang, "title"));
  const handleSelectFile = React.useCallback(
    (selectedFilePath: string) => {
      if (!answer?.answerId) {
        return;
      }
      client
        .ProjectAnswerFileBody({
          answerId: answer.answerId,
          filePath: selectedFilePath,
        })
        .then(res => {
          setFileTree(draft => {
            const node = ProjectHelpers.findNode(draft, selectedFilePath);
            if (node) {
              node.value = res.answerFileBody.fileBody;
            }
            return draft;
          });
          setSelectedFile(selectedFilePath);
          if (!filesOpened.includes(selectedFilePath)) {
            setFilesOpened(prev => prev.concat(selectedFilePath));
          }
        })
        .catch(error => {
          Sentry.captureException(error);
        });
    },
    [answer?.answerId, client, filesOpened, setFileTree],
  );
  const handleCloseFile = React.useCallback(
    (closeFilePath: string) => {
      const newFilesOpened = filesOpened.filter(file => file !== closeFilePath);
      if (selectedSourceFile === closeFilePath) {
        if (newFilesOpened.length === 0) {
          setSelectedFile(undefined);
        } else {
          const oldI = filesOpened.indexOf(closeFilePath);
          const newI = Math.max(0, Math.min(newFilesOpened.length - 1, oldI));
          setSelectedFile(newFilesOpened[newI]);
        }
      }
      if (newFilesOpened.length < filesOpened.length) {
        setFilesOpened(newFilesOpened);
      }
    },
    [filesOpened, selectedSourceFile],
  );
  const averageElapsedTimeObject = secondsToTimeObject(questionDetail.averageElapsedTimeSeconds, "MINUTES");
  const timelimitObject =
    questionDetail.timeLimitSeconds !== null ? secondsToTimeObject(questionDetail.timeLimitSeconds, "MINUTES") : undefined;

  const workspaceProps = React.useMemo((): Widget.ProjectQuestionDetailProps["workspace"] => {
    if (!selectedSourceFile) {
      return {
        mode: "IDLE",
        idlePanel: {
          messages: [
            t("左側のファイルツリーから編集したいファイルを選択できます。"),
            t("エディタ下のターミナルには起動中のサーバーのログが表示されます。"),
          ],
        },
      };
    }
    if (selectedSourceFile.match(/.ipynb$/)) {
      return {
        mode: "JUPYTER_NOTEBOOK_VIEWER",
        notebookViewer: {
          source: editorValue ?? "",
        },
      };
    }
    return {
      mode: "MONACO",
      codeEditor: {
        workspaceId: `${args.questionDetail.id.toString()}-${args.questionDetail.version.toString()}`,
        readOnly: true,
        selectedSourceFile: selectedSourceFile,
        editorValue: editorValue,
        lspEndpoint: undefined,
        cwd: ".",
        typeDefs: {
          react: "",
        },
      },
    };
  }, [args.questionDetail.id, args.questionDetail.version, editorValue, selectedSourceFile, t]);

  return {
    questionAndAnswerTab: {
      questionSection: {
        title: resolveLanguage(questionDetail, lang, "title"),
        description: resolveLanguage(questionDetail, lang, "description"),
        isArchived: questionDetail.status === "ARCHIVED",
      },
    },
    answerSections: answers.map((answer): AnswerSectionProps => {
      return {
        title: resolveLanguage(answer, lang, "title"),
        description: resolveLanguage(answer, lang, "description"),
      };
    }),
    toolbar: {
      fileTree,
      selectedFile: selectedSourceFile,
      openedFiles: filesOpened,
      onSelectFile: handleSelectFile,
      onCloseFile: handleCloseFile,
    },
    workspace: workspaceProps,
    fileNavigation: {
      status: "CONNECTED_SERVER",
      fileTreeStatus: "CONNECTED_SERVER",
      renderTree: {
        node: fileTree,
        onSelectFile: handleSelectFile,
        onSelectDirectory: () => undefined,
      },
      connectingPanel: {},
    },
    information: {
      feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
        mailAddress: currentUserMailAddress,
        targetUrl: window.location.href,
      }),
      scoreHistogram: {
        data: QuestionStats.generateScoreDistributionBinsForHistogram(questionDetail.scoreDistributionBins),
        dataKey: {
          x: "name",
          y: "value",
        },
      },
      skillTags: enabledQuestionSkillTagSearch
        ? questionDetail.skillTags.map(skillTag => skillTagUniqueNameMap.get(skillTag.skillTagNodeId) || skillTag.name)
        : undefined,
      stats: {
        avgCorrectIntegerRate:
          questionDetail.numUses > 0
            ? {
                kind: "VALUE",
                value: formateRateToIntegerPercent(questionDetail.accuracyRate),
              }
            : { kind: "NONE" },
        numOfUsage: questionDetail.numUses.toLocaleString(),
        avgElapseTime:
          questionDetail.numUses > 0
            ? {
                kind: "VALUE",
                minutes: averageElapsedTimeObject.minutes,
                seconds: averageElapsedTimeObject.seconds,
              }
            : { kind: "NONE" },
      },
      detailInfo: {
        difficultyStars: {
          difficulty: questionDetail.difficulty,
        },
        timelimitMinutes: timelimitObject?.minutes,
        createdBy: questionDetail.isOfficial
          ? {
              kind: "OFFICIAL",
            }
          : {
              kind: "PRIVATE",
              //TODO: implement when the user can create private question
              displayName: "",
            },
      },
    },
  };
};
