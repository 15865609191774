import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ResumeDetailContainerProps = {};

const ResumeDetailContainer: React.FC<ResumeDetailContainerProps> = () => {
  const resumeDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ResumeDetail {...resumeDetailProps} />
    </ErrorBoundary>
  );
};

ResumeDetailContainer.displayName = "ResumeDetailContainer";

export default withErrorBoundary(ResumeDetailContainer, {});
