import { useTranslation } from "@hireroo/i18n";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import AnalyzedSection, { AnalyzedSectionProps } from "../AnalyzedSection/AnalyzedSection";
import DocumentSection, { DocumentSectionProps } from "../DocumentSection/DocumentSection";
import MatchingRateSection, { MatchingRateSectionProps } from "../MatchingRateSection/MatchingRateSection";
import StatusMessage, { StatusMessageProps } from "../StatusMessage/StatusMessage";

const AnalyzingWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Gray/Shades"].p8,
  borderRadius: "16px",
  padding: theme.spacing(2),
  width: "100%",
  height: "200px",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.text.secondary,
}));

export type AnalyzedReportProps = {
  matchingRateSection: MatchingRateSectionProps;
  analyzedSection?: AnalyzedSectionProps;
  documentSection: DocumentSectionProps;
  helpButton: Pick<ButtonProps, "onClick">;
  statusMessage?: StatusMessageProps;
};

const AnalyzedReport: React.FC<AnalyzedReportProps> = props => {
  const { t } = useTranslation();
  const helpButton: ButtonProps = {
    ...props.helpButton,
    children: t("レポートの見方はこちら"),
    color: "outline-only",
    size: "small",
    variant: "text",
    startIcon: <HelpOutlineOutlinedIcon />,
  };
  return (
    <Stack p={3} spacing={3} width="100%">
      {props.statusMessage && (
        <Box width="100%">
          <StatusMessage {...props.statusMessage} />
        </Box>
      )}
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="end">
          <Button {...helpButton} />
        </Stack>
        <Stack direction="row" spacing={2} width="100%">
          <MatchingRateSection {...props.matchingRateSection} />
          {props.analyzedSection ? (
            <AnalyzedSection {...props.analyzedSection} />
          ) : (
            <AnalyzingWrapper direction="row" spacing={1}>
              <GraphicEqIcon />
              {`${t("データ解析中")}...`}
            </AnalyzingWrapper>
          )}
        </Stack>
      </Stack>
      <DocumentSection {...props.documentSection} />
    </Stack>
  );
};

AnalyzedReport.displayName = "AnalyzedReport";

export default AnalyzedReport;
