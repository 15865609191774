import { DndContext } from "@dnd-kit/core";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { ResumeDetailDocumentForm } from "@hireroo/validator";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import DragAndDropInput, { DragAndDropInputProps } from "../../../../../../ms-components/Resume/DragAndDropInput/DragAndDropInput";

export type DragAndDropContentProps = {};

const DragAndDropContent: React.FC<DragAndDropContentProps> = _props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const methods = useFormContext<ResumeDetailDocumentForm.ResumeDocumentFormSchema>();
  const resumeDocuments = useFieldArray({ control: methods.control, name: "resumeDocuments" });
  const dragDropInput: DragAndDropInputProps = {
    onSelectedFiles: files => {
      files.forEach(file => {
        if (file.type === "application/pdf") {
          resumeDocuments.append({
            id: `${file.name}-${crypto.randomUUID()}`,
            fileName: file.name,
            size: file.size,
            rawFile: file,
          });
        } else {
          console.warn(`file ${file.name} was not not pdf`);
        }
      });
    },
  };
  return (
    <Stack spacing={1.5}>
      <DndContext>
        <DragAndDropInput {...dragDropInput} />
      </DndContext>
      <Stack>
        {/*TODO fix size*/}
        <Typography fontSize={12} color="textSecondary">
          {t("※")}
          {t2("MaxFileSize", { size: "5MB" })}
        </Typography>
        {/*TODO fix max files*/}
        <Typography fontSize={12} color="textSecondary">
          {t("※")}
          {t2("MaxUploadFileCount", { count: "100" })}
        </Typography>
        <Typography fontSize={12} color="textSecondary">
          {t("※")}
          {t("ファイル形式はPDFのみ対応しています。")}
        </Typography>
      </Stack>
    </Stack>
  );
};

DragAndDropContent.displayName = "DragAndDropContent";

export default DragAndDropContent;
