import { UploadResumesSchema } from "@hireroo/validator";

export const addUploadRequestFormData = (formData: FormData, args: UploadResumesSchema.UploadResumeRequestSchema): FormData => {
  formData.set(`companyId`, args.companyId);
  formData.set(`createdBy`, args.createdBy);
  args.tagNames?.forEach((tag, index) => {
    formData.set(`tagNames[${index}]`, tag);
  });
  args.viewerIds?.forEach((viewerId, index) => {
    formData.set(`viewerIds[${index}][type]`, viewerId.type);
    formData.set(`viewerIds[${index}][viewerId]`, viewerId.viewerId);
  });
  args.resumeMetas.forEach((resumeMeta, index) => {
    formData.set(`resumeMetas[${index}][name]`, resumeMeta.name);
    formData.set(`resumeMetas[${index}][fileId]`, resumeMeta.fileId);
  });
  return formData;
};
