import { App } from "@hireroo/app-store/essential/employee";
import { ResumesIdDetail } from "@hireroo/app-store/page/e/resumes_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ResumeDetailContainer, { ResumeDetailContainerProps } from "./Container";

export type ResumeDetailFetchContainerProps = {};

const ResumeDetailFetchContainer: React.FC<ResumeDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const resumeId = `${id}`;
  const initialized = ResumesIdDetail.useInitialized();
  const [params] = useSearchParams();
  const [result, refresh] = Graphql.useGetResumeForResumesIdDetailQuery({
    variables: {
      resumeId: resumeId,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      ResumesIdDetail.initialize(result.data.resume);
    }
  }, [result.data]);

  React.useEffect(() => {
    ResumesIdDetail.setRefresh(refresh);
  }, [refresh]);

  React.useEffect(() => {
    const tab = params.get("tab");
    if (tab === "GENERAL" || tab === "ANALYZED_REPORT") {
      ResumesIdDetail.setCurrentTab(tab);
    }
  }, [params]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ResumeDetailContainerProps = {};

  return <ResumeDetailContainer {...containerProps} />;
};

ResumeDetailFetchContainer.displayName = "ResumeDetailFetchContainer";

export default withErrorBoundary(ResumeDetailFetchContainer, {});
