import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledConnector = styled(StepConnector)(() => ({
  "& .MuiStepConnector-line": {
    height: "8px",
    minHeight: "8px",
  },
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-iconContainer": {
    width: "40px",
    marginRight: theme.spacing(1.25),
  },
}));

const StyledBadge = styled("div")(({ theme }) => ({
  height: "8px",
  width: "8px",
  backgroundColor: theme.palette.text.secondary,
  borderRadius: "10px",
  alignItems: "center",
  marginRight: "8px",
  marginLeft: "8px",
}));

type SubItem = {
  title: string;
  duration: string;
};

export type CareerSubItemsProps = {
  items: SubItem[];
};

const CareerSubItems: React.FC<CareerSubItemsProps> = props => {
  return (
    <Stepper orientation="vertical" connector={<StyledConnector />} sx={{ pl: 1 }}>
      {props.items.map((item, index) => (
        <Step key={`${item.title}-${index}`} active>
          <StyledStepLabel StepIconComponent={StyledBadge} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize={16}>{item.title}</Typography>
          </StyledStepLabel>
          <StepContent>
            <Typography fontSize={14} ml={2.25} color="textSecondary">
              {item.duration}
            </Typography>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

CareerSubItems.displayName = "CareerSubItems";

export default CareerSubItems;
