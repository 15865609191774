import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Role } from "@hireroo/app-store/essential/employee";
import { Resumes } from "@hireroo/app-store/page/e/resumes";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { useGenerateBulkListActivityBarProps } from "./useGenerateBulkListActivityBarProps";

type Item = Widget.ResumeListProps["table"]["items"][number];

export type GenerateResumeListPropsArgs = {};

export const useGenerateProps = (_args: GenerateResumeListPropsArgs): Widget.ResumeListProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const client = getGraphqlClient();

  const navigate = useTransitionNavigate();
  const currentUser = Auth.useCurrentUser();
  const refreshKey = Resumes.useRefreshKey();
  const [loadingStatus, setLoadingStatus] = React.useState<"LOADING" | "READY">("READY");
  const pager = Resumes.usePager();
  const res = Resumes.useListResponse();
  const resumes = Resumes.useResumes();
  const bulkListActivityBarProps = useGenerateBulkListActivityBarProps({});
  const dialogStatus = Resumes.useDialogStatus();
  const selectableTags = Resumes.useTags().map(tag => tag.name);
  const selectedResumeIds = Resumes.useSelectedResumeIds();
  const matchingRole = Role.useMatchingRole();

  const sortOptions = React.useMemo((): Widget.ResumeListProps["searchResultBar"]["sortField"]["options"] => {
    return [
      {
        displayName: t("作成日時が新しい順"),
        value: Resumes.SortFields.CREATED_AT_DESCENDING,
      },
      {
        displayName: t("作成日時が古い順"),
        value: Resumes.SortFields.CREATED_AT_ASCENDING,
      },
      {
        displayName: t("評価日時が新しい順"),
        value: Resumes.SortFields.EVALUATED_AT_DESCENDING,
      },
      {
        displayName: t("評価日時が古い順"),
        value: Resumes.SortFields.EVALUATED_AT_ASCENDING,
      },
      {
        displayName: t("マッチングレートが高い順"),
        value: Resumes.SortFields.MATCHING_RATE_DESCENDING,
      },
      {
        displayName: t("マッチングレートが低い順"),
        value: Resumes.SortFields.MATCHING_RATE_ASCENDING,
      },
    ];
  }, [t]);

  const statusMap = React.useMemo((): Record<Graphql.ResumeStatus, Item["status"]> => {
    return {
      [Graphql.ResumeStatus.Created]: {
        text: t("採点中"),
        color: "GRAY",
      },
      [Graphql.ResumeStatus.Calculated]: {
        text: t("採点済み"),
        color: "PRIMARY",
      },
      [Graphql.ResumeStatus.Failed]: {
        text: t("採点失敗"),
        color: "GRAY",
        errorMessage: t("採点に失敗しました。詳細画面から確認してください。"),
      },
      [Graphql.ResumeStatus.Evaluated]: {
        text: t("評価済"),
        color: "GRAY",
      },
      [Graphql.ResumeStatus.Recalculating]: {
        text: t("採点中"),
        color: "GRAY",
      },
    };
  }, [t]);

  const isValidOptionValue = React.useCallback(
    (value: string): value is Resumes.SortFieldValue => {
      return sortOptions.some(sortOption => sortOption.value === value);
    },
    [sortOptions],
  );

  const items = React.useMemo((): Item[] => {
    return resumes.map((resume): Item => {
      const showMatchingRate = ["CALCULATED", "EVALUATED"].includes(resume.status);
      const actionMenu: Item["actionMenu"] = {
        disabled: false,
        options: [
          {
            startIcon: "EDIT",
            value: "edit",
            displayName: t("編集"),
            onClick: () => {
              navigate("/e/resumes/:id/update", { pathParams: { id: resume.resumeId } });
            },
          },
          {
            startIcon: "LABEL",
            value: "tag-setting",
            displayName: t("タグ設定"),
            onClick: () => {
              Resumes.updateDialogStatus(`TAG_OPEN_${resume.resumeId}`);
            },
            divider: true,
          },
          {
            startIcon: "DELETE",
            value: "delete",
            displayName: t("削除"),
            color: "error",
            onClick: () => {
              Resumes.updateDialogStatus(`DELETE_OPEN_${resume.resumeId}`);
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
          },
        ],
        tooltipText: t("複数選択中はこの操作はできません。"),
        tagDialog: {
          open: dialogStatus === `TAG_OPEN_${resume.resumeId}`,
          onClose: () => {
            Resumes.updateDialogStatus("CLOSE");
          },
          tagInputField: {
            disabled: loadingStatus === "LOADING",
            options: selectableTags,
          },
          onSubmit: field => {
            setLoadingStatus("LOADING");
            client
              .UpdateResumeForResumes({
                input: {
                  resumeId: resume.resumeId,
                  tags: field.tags,
                },
              })
              .then(res => {
                Snackbar.notify({
                  severity: "success",
                  message: t("タグを更新しました。"),
                });
                Resumes.updateResume(res.updateResume);
                Resumes.updateDialogStatus("CLOSE");
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("タグの更新に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setLoadingStatus("READY");
              });
          },
          defaultValues: {
            tags: resume.tags.map(tag => tag.name),
          },
          disabled: loadingStatus === "LOADING",
        },
        deleteDialog: {
          dialog: {
            open: dialogStatus === `DELETE_OPEN_${resume.resumeId}`,
            yesButton: {
              disabled: loadingStatus === "LOADING",
              onClick: () => {
                setLoadingStatus("LOADING");
                client
                  .DeleteResumeForResumes({
                    input: {
                      resumeId: resume.resumeId,
                    },
                  })
                  .then(() => {
                    Snackbar.notify({
                      severity: "success",
                      message: t("書類選考を削除しました。"),
                    });
                    Resumes.deleteResume(resume.resumeId);
                    Resumes.updateRefreshKey();
                    Resumes.updateDialogStatus("CLOSE");
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類選考の削除に失敗しました。"));
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    setLoadingStatus("READY");
                  });
              },
            },
            noButton: {
              disabled: loadingStatus === "LOADING",
              onClick: () => {
                Resumes.updateDialogStatus("CLOSE");
              },
            },
          },
          items: [
            {
              name: t("一度削除すると元に戻すことはできません。"),
            },
          ],
          name: resume.name,
        },
      };

      const evaluationMap: Record<Graphql.ResumeStatus, Item["evaluation"]> = {
        [Graphql.ResumeStatus.Created]: {
          status: "CANNOT_EVALUATE",
        },
        [Graphql.ResumeStatus.Calculated]: {
          status: "CAN_EVALUATE",
          testName: resume.name,
          url: generateCurrentOriginUrl("/e/resumes/:id/detail", { pathParams: { id: resume.resumeId } }),
          onSubmit: (fields, controller) => {
            controller.setLoading(true);
            client
              .EvaluateResumeForResumes({
                input: {
                  resumeId: resume.resumeId,
                  employeeId: currentUser.uid,
                  isPassed: fields.result === "PASSED",
                  evaluationComment: fields.comment,
                },
              })
              .then(res => {
                Snackbar.notify({
                  severity: "success",
                  message: t("書類選考の評価を完了しました。"),
                });
                Resumes.updateResume(res.evaluateResume);
                controller.close();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類選考の評価に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                controller.setLoading(false);
              });
          },
        },
        [Graphql.ResumeStatus.Failed]: {
          status: "CANNOT_EVALUATE",
        },
        [Graphql.ResumeStatus.Evaluated]: {
          status: resume.isPassed ? "PASSED" : "NOT_PASSED",
        },
        [Graphql.ResumeStatus.Recalculating]: {
          status: "CANNOT_EVALUATE",
        },
      };
      return {
        id: resume.resumeId,
        disabled: false,
        mode: selectedResumeIds.length > 0 ? "SELECTABLE" : "DEFAULT",
        href: generatePath("/e/resumes/:id/detail", { pathParams: { id: resume.resumeId } }),
        onClick: () => {
          navigate("/e/resumes/:id/detail", { pathParams: { id: resume.resumeId } });
        },
        actionMenu: actionMenu,
        meta: {
          title: resume.name,
        },
        status: statusMap[resume.status],
        matchingScore: showMatchingRate ? `${resume.matchingRate}%` : "-",
        evaluation: evaluationMap[resume.status],
        skillTags: resume.resumeSkillTags.map(skillTag => skillTag.skillTagName || skillTag.skillTagId),
      };
    });
  }, [
    client,
    currentUser.uid,
    dialogStatus,
    loadingStatus,
    matchingRole.ADMIN_OR_MANAGER.matched,
    matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
    navigate,
    resumes,
    selectableTags,
    selectedResumeIds.length,
    statusMap,
    t,
  ]);

  return {
    table: {
      items: items,
      onChange: fields => {
        const selectedItems = fields.items.filter(item => item.selected).map(item => item.itemId);
        Resumes.updateSelectedResumeIds(selectedItems);
      },
      defaultValues: {
        items: React.useMemo(() => {
          return res.resumes.map(resume => ({ itemId: resume.resumeId, selected: false }));
        }, [res.resumes]),
      },
    },
    searchResultBar: {
      mark: "READY",
      resultText: t2("SearchResultCount", { count: res.count }),
      bulkListActivityBar: bulkListActivityBarProps,
      mode: selectedResumeIds.length > 0 ? "SELECTED" : "NONE",
      sortField: {
        options: sortOptions,
        onChange: value => {
          if (isValidOptionValue(value)) {
            Resumes.updateSortField(value);
          }
        },
        defaultValue: pager.sortFieldValue,
      },
    },
    refreshKey: refreshKey,
    pagination: {
      count: res.count,
      page: pager.page,
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = Number(event.target.value);
        Resumes.updatePageSize(newSize);
        /**
         * clear selected resumes ids to refresh state inside the list
         */
        Resumes.clearSelectedResumeIds();
      },
      onPageChange: (_, page) => {
        Resumes.updatePage(page);
        /**
         * clear selected resumes ids to refresh state inside the list
         */
        Resumes.clearSelectedResumeIds();
      },
    },
  };
};
