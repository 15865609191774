import { TextSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import InlineTextFilterField, { InlineTextFilterFieldProps } from "../../../InlineTextFilterField/InlineTextFilterField";

const StyledInlineTextFiled = styled(InlineTextFilterField)(({ theme }) => ({
  borderColor: theme.palette.Other.Divider,
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.Other.Divider,
  },
}));

export type TextFilterProps = Pick<InlineTextFilterFieldProps, "placeholder" | "disabled"> & {
  onSubmit: SubmitHandler<TextSearchForm.TextSearchFormSchema>;
};

const TextFilter: React.FC<TextFilterProps> = props => {
  const validateSchema = TextSearchForm.useTextSearchForm();
  const methods = useForm<TextSearchForm.TextSearchFormSchema>({
    resolver: zodResolver(validateSchema),
    mode: "onSubmit",
    defaultValues: {
      text: "",
    },
  });
  const { onSubmit, ...rest } = props;

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      onSubmit(methods.getValues());
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onSubmit]);

  const textFilterField: InlineTextFilterFieldProps = {
    ...rest,
    variant: "outlined",
    name: "text",
  };
  return (
    <FormProvider {...methods}>
      <StyledInlineTextFiled {...textFilterField} />
    </FormProvider>
  );
};

TextFilter.displayName = "TextFilter";

export default TextFilter;
