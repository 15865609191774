import { ResumeDocumentForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

export type Step = "DOCUMENT_LIST" | "OPTIONAL";

export type ContextValue = {
  step: Step;
  updateStep: (step: Step) => void;
  title: string;
  setTitle: (title: string) => void;
};

export const ResumeDocumentUploadDialogContext = React.createContext<ContextValue>({
  step: "DOCUMENT_LIST",
  updateStep: () => undefined,
  title: "",
  setTitle: () => undefined,
});

export const useResumeDocumentUploadDialogContext = () => React.useContext(ResumeDocumentUploadDialogContext);

export type ResumeDocumentUploadDialogProviderProps = {
  defaultValues?: ResumeDocumentForm.ResumeDocumentFormSchema;
};
export const ResumeDocumentUploadDialogProvider: React.FC<React.PropsWithChildren<ResumeDocumentUploadDialogProviderProps>> = props => {
  const validateSchema = ResumeDocumentForm.useResumeDocumentForm();
  const methods = useForm<ResumeDocumentForm.ResumeDocumentFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues ?? {
      resumeDocuments: [],
      tags: [],
      viewers: [],
      editorUid: null,
    },
  });

  const [title, setTitle] = React.useState("");
  const [step, setStep] = React.useState<Step>("DOCUMENT_LIST");

  const contextValue: ContextValue = {
    step: step,
    updateStep: setStep,
    title: title,
    setTitle: setTitle,
  };
  return (
    <FormProvider {...methods}>
      <ResumeDocumentUploadDialogContext.Provider value={contextValue} children={props.children} />
    </FormProvider>
  );
};
