import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { scrollToTargetElementById } from "@hireroo/app-helper/html-element";
import { Auth, Role } from "@hireroo/app-store/essential/employee";
import { ResumesIdDetail } from "@hireroo/app-store/page/e/resumes_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as TimeFormatter from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate, useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { DOCUMENT_SECTION_ELEMENT_ID } from "./privateHelper";
import { useGenerateAnalyzedSectionProps } from "./useGenerateAnalyzedSectionProps";
import { useGenerateDocumentSectionProps } from "./useGenerateDocumentSectionProps";
import { useGenerateGeneralProps } from "./useGenerateGeneralProps";

export type GenerateResumeDetailPropsArgs = {};

export const useGenerateProps = (_args: GenerateResumeDetailPropsArgs): Widget.ResumeDetailProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const matchingRole = Role.useMatchingRole();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const expectedRole = Role.useRoleToText("MANAGER");
  const navigate = useTransitionNavigate();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const dialogStatus = ResumesIdDetail.useDialogStatus();
  const currentUid = Auth.useCurrentUid();

  const client = getGraphqlClient();
  const analyzedSectionProps = useGenerateAnalyzedSectionProps();
  const documentSectionProps = useGenerateDocumentSectionProps();
  const generalProps = useGenerateGeneralProps();
  const [loading, setLoading] = React.useState(false);

  const [, setParams] = useSearchParams();
  const currentTab = ResumesIdDetail.useCurrentTab();

  const resume = ResumesIdDetail.useResume();

  const isAnalyzed = ["CALCULATED", "EVALUATED"].includes(resume.status);

  const displayAreaMap: Record<Graphql.ResumeStatus, Widget.ResumeDetailProps["report"]["matchingRateSection"]["displayArea"]> = {
    CREATED: {
      kind: "ANALYZING",
    },
    CALCULATED: {
      kind: "MATCHING_RATE",
    },
    RECALCULATING: {
      kind: "ANALYZING",
    },
    FAILED: {
      kind: "ERROR",
      message: t("採点失敗"),
    },
    EVALUATED: {
      kind: "MATCHING_RATE",
    },
  };

  const statusMessage = React.useMemo((): Widget.ResumeDetailProps["report"]["statusMessage"] => {
    const hasAnalyzingItem = resume.resumeItems.some(item => item.status === "ENQUEUED");
    const hasFailedItem = resume.resumeItems.some(item => item.status === "FAILED");
    if (hasFailedItem) {
      return {
        content: {
          kind: "ERROR",
          actionButton: {
            onClick: () => {
              scrollToTargetElementById(DOCUMENT_SECTION_ELEMENT_ID);
            },
          },
        },
      };
    }
    if (hasAnalyzingItem) {
      return {
        content: {
          kind: "ANALYZING",
          actionButton: {
            onClick: () => {
              scrollToTargetElementById(DOCUMENT_SECTION_ELEMENT_ID);
            },
          },
        },
      };
    }
    if (resume.status === "CALCULATED") {
      return {
        content: {
          kind: "ANALYZED",
        },
      };
    }
    const evaluatedBy = resume.evaluatedBy ? `${resume.evaluatedBy.displayName || resume.evaluatedBy.email}` : t("不明");
    const evaluatedTime = resume.evaluatedAtSeconds ? TimeFormatter.unixToDateFormat(resume.evaluatedAtSeconds) : "";
    if (resume.status === "EVALUATED" && resume.isPassed) {
      return {
        content: {
          kind: "PASSED",
          props: {
            message: resume.evaluationComment,
            additionalInfo: {
              text: `${t("評価者")}: ${evaluatedBy} ${evaluatedTime}`,
            },
          },
        },
      };
    }
    if (resume.status === "EVALUATED" && !resume.isPassed) {
      return {
        content: {
          kind: "NOT_PASSED",
          props: {
            message: resume.evaluationComment,
            additionalInfo: {
              text: `${t("評価者")}: ${evaluatedBy} ${evaluatedTime}`,
            },
          },
        },
      };
    }

    return undefined;
  }, [resume.evaluatedAtSeconds, resume.evaluatedBy, resume.evaluationComment, resume.isPassed, resume.resumeItems, resume.status, t]);

  return {
    header: {
      title: resume.name,
      evaluateButton:
        resume.status === "CALCULATED"
          ? {
              onClick: () => {
                ResumesIdDetail.updateDialogStatus("OPEN_EVALUATION");
              },
              disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
              title: !matchingRole.ADMIN_OR_MANAGER.matched
                ? t2("PermissionTooltip", {
                    actualRole: currentRoleDisplayText,
                    expectedRole: expectedRole,
                  })
                : undefined,
            }
          : undefined,
      moreButton: {
        options: [
          {
            value: "edit",
            displayName: t("編集"),
            onClick: () => {
              navigate("/e/resumes/:id/update", {
                pathParams: {
                  id: resume.resumeId,
                },
              });
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
            divider: false,
            startIcon: "EDIT",
          },
          {
            value: "document",
            onClick: () => {
              ResumesIdDetail.setCurrentTab("ANALYZED_REPORT");
              /**
               * delay scroll to target element to wait for the tab change
               */
              window.setTimeout(() => {
                scrollToTargetElementById(DOCUMENT_SECTION_ELEMENT_ID);
              }, 200);
            },
            displayName: t("書類情報"),
            divider: true,
            startIcon: "CONTENT_COPY",
          },
          {
            value: "delete",
            displayName: t("削除する"),
            color: "error",
            onClick: () => {
              ResumesIdDetail.updateDialogStatus("OPEN_DELETION");
            },
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
            disabledText: t2("PermissionTooltip", {
              actualRole: currentRoleDisplayText,
              expectedRole: expectedRole,
            }),
            divider: false,
            startIcon: "DELETE",
          },
        ],
      },
    },
    report: {
      matchingRateSection: {
        matchingRate: resume.matchingRate,
        displayArea: displayAreaMap[resume.status],
      },
      analyzedSection: isAnalyzed ? analyzedSectionProps : undefined,
      documentSection: documentSectionProps,
      helpButton: {
        onClick: () => {
          helpCenterNavigate("RESUME_REPORT_PAGE");
        },
      },
      statusMessage: statusMessage,
    },
    general: generalProps,
    generalTab: {
      onClick: () => {
        setParams({ tab: "GENERAL" });
        ResumesIdDetail.setCurrentTab("GENERAL");
      },
    },
    analyzedReportTab: {
      onClick: () => {
        setParams({ tab: "ANALYZED_REPORT" });
        ResumesIdDetail.setCurrentTab("ANALYZED_REPORT");
      },
    },
    defaultTab: currentTab,
    evaluation: {
      open: dialogStatus === "OPEN_EVALUATION",
      url: generateCurrentOriginUrl("/e/resumes/:id/detail", { pathParams: { id: resume.resumeId } }),
      testName: resume.name,
      onClose: () => {
        ResumesIdDetail.updateDialogStatus("CLOSED");
      },
      onSubmit: fields => {
        setLoading(true);
        client
          .EvaluateResumeForResumesIdDetail({
            input: {
              resumeId: resume.resumeId,
              evaluationComment: fields.comment,
              isPassed: fields.result === "PASSED",
              employeeId: currentUid,
            },
          })
          .then(res => {
            ResumesIdDetail.updateResume(res.evaluateResume);
            ResumesIdDetail.updateDialogStatus("CLOSED");
            Snackbar.notify({
              severity: "success",
              message: t("書類選考の評価を完了しました。"),
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類選考の評価に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
      yesButton: {
        disabled: loading,
      },
      noButton: {
        disabled: loading,
      },
    },
    deleteDialog: {
      dialog: {
        open: dialogStatus === "OPEN_DELETION",
        noButton: {
          onClick: () => {
            ResumesIdDetail.updateDialogStatus("CLOSED");
          },
          disabled: loading,
        },
        yesButton: {
          onClick: () => {
            setLoading(true);
            client
              .DeleteResumeForResumesIdDetail({
                input: {
                  resumeId: resume.resumeId,
                },
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("書類を削除しました。"),
                });
                navigate("/e/resumes");
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類の削除に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          },
          disabled: loading,
        },
      },
      name: resume.name,
      items: [
        {
          name: t("一度削除すると元に戻すことはできません。"),
        },
      ],
    },
  };
};
