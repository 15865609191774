import { ref } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const initialize = (resume: Types.Resume): void => {
  state.resume = resume;
};

export const clear = (): void => {
  state.resume = null;
  clearSelectedResumeItem();
  state.dialogStatus = "CLOSED";
  state.currentTab = "ANALYZED_REPORT";
};

export const updateResume = (resume: Types.Resume): void => {
  state.resume = resume;
};

export const updateDialogStatus = (dialogStatus: Types.DialogStatus): void => {
  state.dialogStatus = dialogStatus;
};

export const setRefresh = (refresh: () => void): void => {
  state.refresh = ref(refresh);
};
export const refresh = () => {
  state.refresh?.();
};

export const setSelectedResumeItem = (selectedResumeItem: Types.ResumeItem): void => {
  state.selectedResumeItem = selectedResumeItem;
};

export const clearSelectedResumeItem = (): void => {
  state.selectedResumeItem = null;
};

export const setCurrentTab = (currentTab: Types.Tab): void => {
  state.currentTab = currentTab;
};
