import { SkillMapForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import SkillMapSettingsForm, { SkillMapSettingsFormProps } from "./parts/SkillMapSettingsForm/SkillMapSettingsForm";
import TextFilter, { TextFilterProps } from "./parts/TextFilter/TextFilter";

export type SkillMapSettingsProps = {
  SkillTagList: React.ReactNode;
  textFilter: TextFilterProps;
  skillMapForm: SkillMapSettingsFormProps;
  defaultValues: SkillMapForm.SkillMapFormSchema;
};

const SkillMapSettings: React.FC<SkillMapSettingsProps> = props => {
  const validateSchema = SkillMapForm.useSkillMapFormSchema();
  const methods = useForm<SkillMapForm.SkillMapFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });

  return (
    <Stack direction="row" spacing={3} width="100%">
      <FormProvider {...methods}>
        <Box width="30%">
          <SkillMapSettingsForm {...props.skillMapForm} />
        </Box>

        <Stack width="70%" spacing={1.5}>
          <TextFilter {...props.textFilter} />
          {props.SkillTagList}
        </Stack>
      </FormProvider>
    </Stack>
  );
};

SkillMapSettings.displayName = "SkillMapSettings";

export default SkillMapSettings;
