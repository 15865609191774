import { useTranslation } from "@hireroo/i18n";
import { ResumeDocumentForm } from "@hireroo/validator";
import { ErrorMessage } from "@hookform/error-message";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorIcon from "@mui/icons-material/Error";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
}));
const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  padding: "4px 8px",
}));

const StyledInputControlTextField = styled(InputControlTextField)(({ theme }) => ({
  padding: theme.spacing(1),
  "& .MuiFilledInput-root": {
    "&:before": {
      borderBottom: "none",
    },
    "&:hover": {
      borderBottom: "none",
    },
  },
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
  },
}));

const ReservedName = {
  RESUME_DOCUMENTS: "resumeDocuments",
} satisfies Record<string, keyof ResumeDocumentForm.ResumeDocumentFormSchema>;

export type ResumeDocumentTableRowProps = {
  name: string;
  fileName: string;
  size: string;
  deleteButton: Pick<IconButtonWithTooltipProps, "onClick">;
  errorMessageName: `${typeof ReservedName.RESUME_DOCUMENTS}.${number}`;
};

const ResumeDocumentTableRow: React.FC<ResumeDocumentTableRowProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const methods = useFormContext<ResumeDocumentForm.ResumeDocumentFormSchema>();
  const deleteButton: IconButtonWithTooltipProps = {
    ...props.deleteButton,
    title: t("削除"),
    children: <DeleteOutlineIcon />,
  };
  const textField: InputControlTextFieldProps = {
    variant: "filled",
  };
  const filedState = methods.getFieldState(props.errorMessageName);
  return (
    <>
      <StyledTableRow sx={{ borderBottom: filedState.error ? "none" : "1px solid", borderColor: theme.palette.Other.Divider }}>
        <StyledTableCell>
          {/* name */}
          <StyledInputControlTextField name={props.name} {...textField} />
        </StyledTableCell>
        <StyledTableCell sx={{ maxWidth: "250px" }}>
          {/* fileName */}
          <Typography sx={{ overflowX: "auto" }}>{props.fileName}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          {/* size */}
          <Typography>{props.size}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          {/* delete button */}
          <IconButtonWithTooltip {...deleteButton} />
        </StyledTableCell>
      </StyledTableRow>
      <ErrorMessage
        name={props.errorMessageName}
        render={({ message }) => (
          <StyledTableRow sx={{ borderTop: "none" }}>
            <StyledTableCell colSpan={4}>
              <Stack direction="row" spacing={1} alignItems="center">
                <ErrorIcon color="error" fontSize="small" sx={{ height: "16px", width: "16px" }} />
                <Typography fontSize={12} color="error">
                  {message}
                </Typography>
              </Stack>
            </StyledTableCell>
          </StyledTableRow>
        )}
      />
    </>
  );
};

ResumeDocumentTableRow.displayName = "ResumeDocumentTableRow";

export default ResumeDocumentTableRow;
