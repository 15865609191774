import CheckIcon from "@mui/icons-material/Check";
import Chip, { ChipProps } from "@mui/material/Chip";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const StyledChip = styled(Chip)(() => ({
  fontSize: "13px",
  lineHeight: "18px",
  height: "24px",
}));

type Kind = "CHECKED" | "DEFAULT";

export type SkillTagProps = {
  label: string;
  kind: Kind;
};

const SkillTag: React.FC<SkillTagProps> = props => {
  const theme = useTheme();
  const colorMap: Record<Kind, string> = {
    CHECKED: theme.palette.primary.dark,
    DEFAULT: theme.palette["Gray/Shades"].p16,
  };

  const chip: ChipProps = {
    sx: {
      backgroundColor: colorMap[props.kind],
      alignItems: "center",
    },
    icon: props.kind === "CHECKED" ? <CheckIcon sx={{ width: "18px", height: "18px" }} /> : undefined,
    label: props.label,
  };
  return <StyledChip {...chip} />;
};

SkillTag.displayName = "SkillTag";

export default SkillTag;
