import { useTranslation } from "@hireroo/i18n";
import { ResumeSearchForm as ResumeSearchFormValidator } from "@hireroo/validator";

import { TagSearchSelectFieldProps } from "../../modules/SearchForm/parts/TagSearchSelectField/TagSearchSelectField";
import type { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "MULTI_CHOICE";
  }
>;
export type SingleChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "SINGLE_CHOICE";
  }
>;
export type GroupMultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "GROUP_MULTI_CHOICE";
  }
>;
export type RangeFieldProps = Extract<
  FieldProps,
  {
    kind: "RANGE";
  }
>;
export type TagFieldProps = Extract<
  FieldProps,
  {
    kind: "TAG";
  }
>;

export type SkillTagFieldProps = Extract<
  FieldProps,
  {
    kind: "TAG_SEARCH";
  }
>;

type FieldKeyName = keyof ResumeSearchFormValidator.ResumeSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  SKILL_TAGS: "skillTags",
  TAGS: "tags",
  MATCHING_RATE: "matchingRate",
  STATUSES: "statuses",
  RESULT: "result",
} satisfies Record<string, FieldKeyName>;

type ResultOption = {
  displayName: string;
  value: Exclude<ResumeSearchFormValidator.ResumeSearchQuerySchema["result"], null>;
};

export const useResultsField = (): SingleChoiceFieldProps => {
  const { t } = useTranslation();
  const options: ResultOption[] = [
    { value: "PASSED", displayName: t("合格") },
    { value: "NOT_PASSED", displayName: t("不合格") },
  ];
  return {
    kind: "SINGLE_CHOICE",
    title: t("結果"),
    name: FieldName.RESULT,
    options: options,
  };
};

type StatusesOption = {
  displayName: string;
  value: ResumeSearchFormValidator.ResumeSearchQuerySchema["statuses"][0];
};

export const useStatusesField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: StatusesOption[] = [
    { value: "CALCULATING", displayName: t("採点中") },
    { value: "FAILED", displayName: t("採点失敗") },
    { value: "CALCULATED", displayName: t("採点済み") },
    { value: "EVALUATED", displayName: t("評価済み") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ステータス"),
    name: FieldName.STATUSES,
    options: options,
  };
};

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("タイトルを検索"),
  };
};

export const useMatchingRateField = (): RangeFieldProps => {
  const { t } = useTranslation();
  return {
    kind: "RANGE",
    name: FieldName.MATCHING_RATE,
    title: t("マッチングレート"),
    label: t("マッチングレート"),
  };
};

export const useTagsField = (props: Pick<TagFieldProps, "selectableTags">): TagFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "TAG",
    name: FieldName.TAGS,
    title: t("タグ"),
  };
};

export type SkillTagsFiledArgs = Pick<
  TagSearchSelectFieldProps,
  "selectableTags" | "scrollableTags" | "textFilter" | "tagSources" | "onChange" | "splitButton"
>;

export const useSkillTagsField = (props: SkillTagsFiledArgs): SkillTagFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "TAG_SEARCH",
    name: FieldName.SKILL_TAGS,
    title: t("スキルタグ"),
  };
};
