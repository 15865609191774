import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

const MIN_HEIGHT = 70;

export type PDFViewerDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose">;
  loading: boolean;
  title: string;
  dataSrc: string;
};

const PDFViewerDialog: React.FC<PDFViewerDialogProps> = props => {
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: props.title,
    optionalDialog: {
      PaperProps: {
        sx: {
          maxWidth: "100%",
          width: "100%",
          minWidth: "70vw",
          minHeight: `${MIN_HEIGHT}vh`,
        },
      },
      sx: {
        width: "100vw",
        height: "100vh",
      },
    },
  };
  return (
    <DialogWithCloseIcon {...dialog}>
      {props.loading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height={`${MIN_HEIGHT}vh`}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <iframe
          title={props.title}
          src={props.dataSrc}
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
          }}
        />
      )}
    </DialogWithCloseIcon>
  );
};

PDFViewerDialog.displayName = "PDFViewerDialog";

export default PDFViewerDialog;
