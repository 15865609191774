import { useEnabledDevOnlyPage, useEnabledResumeService } from "@hireroo/app-helper/feature";
import { App, Credential } from "@hireroo/app-store/essential/shared";
import * as Graphql from "@hireroo/graphql/client/urql";
import routing from "@hireroo/router/config";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import BasicLayout from "./BasicLayout";
import Dynamic from "./dynamic";
import NotFoundContainer from "./NotFound/Container";
import OopsContainer from "./Oops/Container";
import PermittedRouteForCandidateContainer from "./PermittedRouteForCandidate/Container";
import PermittedRouteForEmployeeContainer from "./PermittedRouteForEmployee/Container";
import PermittedRouteForTalentContainer from "./PermittedRouteForTalent/Container";
import Redirect from "./Redirect";
import V2 from "./v2";

const AppRouter: React.FC = () => {
  const userType = Credential.useUserType();
  const enableTalentScore = App.useEnableTalentScore();
  const enabledDevOnlyPage = useEnabledDevOnlyPage();
  const enabledResumeService = useEnabledResumeService();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BasicLayout />}>
          <Route path={routing["/"]} element={<V2.Root />} />
          <Route path="/" element={<PermittedRouteForEmployeeContainer />}>
            {/* Employee Routing */}
            <Route path={routing["/e/invitations/:id"]} element={<V2.EmployeeInvitationsId />} />
            {userType === Graphql.UserType.Employee && (
              <>
                <Route path={routing["/e/home"]} element={<V2.Home />} />
                <Route path={routing["/e/settings/company"]} element={<Navigate to={routing["/e/settings/company/profile"]} />} />

                <Route path={routing["/e/settings/billing/overview"]} element={<V2.CompanySettingsBillingOverview />} />
                <Route path={routing["/e/settings/billing/usages"]} element={<V2.CompanySettingsBillingUsages />} />
                <Route path={routing["/e/settings/order/history"]} element={<V2.CompanySettingsOrderHistory />} />
                <Route path={routing["/e/settings/contract/create"]} element={<V2.ContractCreate />} />
                <Route path={routing["/e/settings/payment/method/update"]} element={<V2.PaymentMethodUpdate />} />
                <Route path={routing["/e/settings/plan/update"]} element={<V2.PlanUpdate />} />
                <Route path={routing["/e/settings/plan/list"]} element={<V2.PlanList />} />
                <Route path={routing["/e/settings/buy/selections"]} element={<V2.BuySelections />} />
                <Route path={routing["/e/settings/billing/invoices"]} element={<V2.CompanySettingsBillingInvoices />} />

                <Route path={routing["/e/remotes"]} element={<V2.RemoteInterviewList />} />
                <Route path={routing["/e/remotes/:id"]} element={<V2.EmployeeRemotesId />} />
                <Route path={routing["/e/remotes/:id/detail"]} element={<V2.RemoteInterviewDetail />} />
                <Route path={routing["/e/remotes/create"]} element={<V2.RemoteInterviewCreate />} />
                <Route path={routing["/e/remotes/:id/update"]} element={<V2.RemoteInterviewIdUpdate />} />
                <Route path={routing["/e/remotes/:id/evaluate"]} element={<V2.EmployeeRemotesIdEvaluate />} />

                {enabledResumeService && (
                  <>
                    <Route path={routing["/e/resumes"]} element={<V2.ResumeList />} />
                    <Route path={routing["/e/resumes/:id/detail"]} element={<V2.ResumeIdDetail />} />
                    <Route path={routing["/e/resumes/:id/update"]} element={<V2.ResumeIdUpdate />} />
                    <Route path={routing["/e/settings/company/skillmap"]} element={<V2.CompanySkillMapSettings />} />
                  </>
                )}

                <Route path={routing["/e/interviews"]} element={<Navigate to="/e/screenings/tests" />} />
                <Route path={routing["/e/interviews/:id/detail"]} element={<Redirect from="/e/interviews/:id/detail" />} />
                <Route path={routing["/e/interviews/:id/review"]} element={<Redirect from="/e/interviews/:id/review" />} />
                <Route path={routing["/e/interviews/create"]} element={<Navigate to={routing["/e/screenings/create"]} />} />
                <Route path={routing["/e/interviews/:id/update"]} element={<Redirect from="/e/interviews/:id/update" />} />
                {/* Obsolete, but redirects in case the link is still there */}
                <Route path={routing["/e/interview-groups/list"]} element={<Navigate to="/e/screenings" />} />

                <Route path={routing["/e/screenings/create"]} element={<V2.ScreeningCreate />} />
                <Route path={routing["/e/screenings/:id/update"]} element={<V2.ScreeningIdUpdate />} />
                <Route path={routing["/e/screenings"]} element={<V2.ScreeningList />} />
                <Route path={routing["/e/screenings/tests"]} element={<V2.ScreeningTestList />} />
                <Route path={routing["/e/screenings/:id/detail"]} element={<V2.ScreeningIdDetail />} />
                <Route path={routing["/e/screenings/tests/:id/detail"]} element={<V2.ScreeningTestIdDetail />} />
                <Route path={routing["/e/screenings/tests/:id/review"]} element={<V2.ScreeningTestReview />} />

                <Route path={routing["/e/templates/remote/create"]} element={<V2.TemplateRemoteCreate />} />
                <Route path={routing["/e/templates/remote/:id/update"]} element={<V2.TemplateRemoteIdUpdate />} />
                <Route path={routing["/e/settings/user"]} element={<V2.EmployeeUserSettings />} />
                <Route path={routing["/e/questions"]} element={<V2.QuestionList />} />
                <Route path={routing["/e/questions/challenge/create"]} element={<Dynamic.QuestionsChallengeCreate />} />
                <Route path={routing["/e/questions/challenge/:id"]} element={<V2.QuestionsChallengeId />} />
                <Route path={routing["/e/questions/challenge/:id/update"]} element={<Dynamic.QuestionsChallengeIdUpdate />} />
                <Route path={routing["/e/questions/freepad/create"]} element={<Dynamic.QuestionsFreepadCreate />} />
                <Route path={routing["/e/questions/freepad/:id"]} element={<V2.QuestionsFreepadId />} />
                <Route path={routing["/e/questions/freepad/:id/update"]} element={<Dynamic.QuestionsFreepadIdUpdate />} />
                <Route path={routing["/e/questions/quiz/:id"]} element={<V2.QuestionsQuizId />} />
                <Route path={routing["/e/questions/quiz/create"]} element={<Dynamic.QuestionsQuizCreate />} />
                <Route path={routing["/e/questions/quiz/:id/update"]} element={<Dynamic.QuestionsQuizIdUpdate />} />
                <Route path={routing["/e/questions/project/:id"]} element={<V2.QuestionsProjectId />} />
                <Route path={routing["/e/questions/systemdesign/:id"]} element={<V2.QuestionsSystemDesignId />} />
                <Route path={routing["/e/questions/packages"]} element={<V2.QuestionsPackages />} />
                <Route path={routing["/e/questions/packages/:id/detail"]} element={<V2.QuestionsPackagesIdDetail />} />
                <Route path={routing["/e/settings/company/profile"]} element={<V2.CompanyProfileSettings />} />
                <Route path={routing["/e/settings/company/security"]} element={<V2.SecuritySettings />} />
                <Route path={routing["/e/settings/company/integration/sonar"]} element={<V2.IntegrationSonarSettings />} />
                <Route path={routing["/e/settings/company/notification/slack"]} element={<V2.SlackNotificationSettings />} />
                <Route path={routing["/e/settings/company/employee"]} element={<V2.EmployeeSettings />} />
                <Route path={routing["/e/settings/company/group/employee"]} element={<V2.EmployeeGroupSettings />} />
                <Route path={routing["/e/evaluations/groups"]} element={<V2.EvaluationsMetricsGroups />} />
                <Route path={routing["/e/evaluations/metrics"]} element={<V2.EvaluationsMetrics />} />
                {enableTalentScore && (
                  <>
                    <Route path={routing["/e/settings/company/talent"]} element={<V2.TalentSettings />} />
                    <Route path={routing["/e/assessments/create"]} element={<V2.AssessmentCreate />} />
                    <Route path={routing["/e/assessments/:id/update"]} element={<V2.AssessmentUpdate />} />
                    <Route path={routing["/e/assessments"]} element={<V2.AssessmentsForEmployee />} />
                    <Route path={routing["/e/assessments/:id/detail"]} element={<V2.AssessmentsIdDetailForEmployee />} />
                    <Route path={routing["/e/exams/:id/review"]} element={<V2.EmployeeExamsIdReview />} />
                  </>
                )}
              </>
            )}
          </Route>

          {/* Sign In / Sign Up Routing */}
          <Route path={routing["/signin"]} element={<V2.SignIn />} />
          <Route path={routing["/signup"]} element={<V2.SignUp />} />
          {/* Redirect because it is still possible to access from the old URI */}
          <Route path={"/e/signin"} element={<Navigate to={routing["/signin"]} />} />
          <Route path={"/e/signup"} element={<Navigate to={routing["/signup"]} />} />
          {/* Reset Password Routing */}
          <Route path={routing["/reset/password"]} element={<V2.ResetPassword />} />
          {/* Routing redirected after email address confirmation */}
          <Route path={routing["/auth"]} element={<V2.Auth />} />
          {/* Talent Routing */}
          <Route path="/" element={<PermittedRouteForTalentContainer />}>
            <Route path={routing["/t/invitations/:id"]} element={<V2.TalentInvitationsId />} />
            {enableTalentScore && userType === Graphql.UserType.Talent && (
              <>
                <Route path={routing["/t/assessments"]} element={<V2.AssessmentsForTalent />} />
                <Route path={routing["/t/assessments/:id/detail"]} element={<V2.AssessmentsIdDetailForTalent />} />
                <Route path={routing["/t/exams/:id"]} element={<V2.ExamsIdForTalent />} />
                <Route path={routing["/t/exams/:id/review"]} element={<V2.TalentExamsIdReview />} />
                <Route path={routing["/t/settings/user"]} element={<V2.TalentUserSettings />} />
              </>
            )}
          </Route>
          {/* Candidate Routing */}
          {userType === Graphql.UserType.Candidate && (
            <Route path="/" element={<PermittedRouteForCandidateContainer />}>
              <Route path={routing["/c/interviews/:id"]} element={<Redirect from="/c/interviews/:id" />} />
              <Route path={routing["/c/interviews/:id/detail"]} element={<Redirect from="/c/interviews/:id/detail" />} />
              <Route path={routing["/c/interview-groups/:id"]} element={<Redirect from="/c/interview-groups/:id" />} />
              <Route path={routing["/c/demos/start"]} element={<V2.CandidateDemoStart />} />
              <Route path={routing["/c/demos/:id"]} element={<V2.CandidateDemoInterviewsId />} />
              <Route path={routing["/c/remotes/:id"]} element={<V2.CandidateRemotesId />} />
              <Route path={routing["/c/screenings/:id"]} element={<V2.CandidateScreeningsId />} />
              <Route path={routing["/c/screenings/tests/:id"]} element={<V2.CandidateScreeningsTestsId />} />
              <Route path={routing["/c/screenings/tests/:id/detail"]} element={<V2.CandidateScreeningTestIdDetail />} />
            </Route>
          )}

          {enabledDevOnlyPage && (
            <>
              <Route path="/devonly/monaco-editor" element={<V2.MonacoEditorPlayground />} />
              <Route path="/devonly/evaluation-result-ui" element={<V2.EvaluationResultPlaygroundContainer />} />
            </>
          )}

          {/* Not Found Routing */}
          <Route path="*" element={<NotFoundContainer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

AppRouter.displayName = "AppRouter";

export default withErrorBoundary(AppRouter, { fallback: <OopsContainer /> });
