import MatchingRatePieChart, { MatchingRatePieChartProps } from "@hireroo/charts/react/v2/MatchingRatePieChart";
import Box from "@mui/material/Box";
import * as React from "react";

export type MatchingRateSectionProps = MatchingRatePieChartProps;

const MatchingRateSection: React.FC<MatchingRateSectionProps> = props => {
  return (
    <Box>
      <React.Suspense>
        <MatchingRatePieChart {...props} />
      </React.Suspense>
    </Box>
  );
};

MatchingRateSection.displayName = "MatchingRateSection";

export default MatchingRateSection;
