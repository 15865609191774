import { languageMapForDisplay } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../../../primitive/BaseDialog/BaseDialog";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&:hover ": {
      backgroundColor: theme.palette["Secondary/Shades"].p8,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

export type RuntimeSelectorDialogProps = {
  value: SelectProps<string>["value"];
  baseDialog: Pick<BaseDialogProps, "open">;
  enabledLanguages?: string[];
  onSubmit: (value: string) => void;
};

const RuntimeSelectorDialog: React.FC<RuntimeSelectorDialogProps> = props => {
  const { t } = useTranslation();
  const [draftValue, setDraftValue] = React.useState<string>(props.value || "");

  const baseDialogProps: BaseDialogProps = {
    title: t("言語指定"),
    yesButton: {
      disabled: !draftValue,
      children: t("確定"),
      onClick: () => {
        props.onSubmit(draftValue);
      },
    },
    optionalDialog: {
      PaperProps: {
        sx: {
          width: 1,
          maxWidth: 600,
        },
      },
    },
    ...props.baseDialog,
  };

  const handleSelectEvent: SelectProps<string>["onChange"] = event => {
    setDraftValue(event.target.value);
  };

  if (!props.enabledLanguages || props.enabledLanguages.length <= 0) {
    return null;
  }

  return (
    <BaseDialog {...baseDialogProps}>
      <Typography>
        {t("解答に使用する言語を選択してください。")}
        <br />
        {t("※ 解答する言語は後から変更することが可能です。")}
      </Typography>
      <Select
        value={draftValue}
        sx={{ mt: 2 }}
        fullWidth
        size={"small"}
        color="secondary"
        variant={"outlined"}
        displayEmpty
        onChange={handleSelectEvent}
      >
        <StyledMenuItem disabled value="">
          <Typography sx={{ color: "text.secondary" }}>{t("選択してください")}</Typography>
        </StyledMenuItem>
        {Array.from(props.enabledLanguages)
          .sort()
          .map(enabledLanguage => (
            <StyledMenuItem key={enabledLanguage} value={enabledLanguage}>
              {languageMapForDisplay[enabledLanguage] ?? enabledLanguage}
            </StyledMenuItem>
          ))}
      </Select>
    </BaseDialog>
  );
};

RuntimeSelectorDialog.displayName = "RuntimeSelectorDialog";

export default RuntimeSelectorDialog;
