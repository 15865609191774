import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateProjectCodingEditorV3PropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectCodingEditorV3ContainerProps = GenerateProjectCodingEditorV3PropsArgs;

const ProjectCodingEditorV3Container: React.FC<ProjectCodingEditorV3ContainerProps> = props => {
  const projectCodingEditorV3Props = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectCodingEditorV3 {...projectCodingEditorV3Props} />
    </ErrorBoundary>
  );
};

ProjectCodingEditorV3Container.displayName = "ProjectCodingEditorV3Container";

export default withErrorBoundary(ProjectCodingEditorV3Container, {});
