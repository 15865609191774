import { useSnapshot } from "valtio";

import type * as Types from "../resumes/types";
import * as Def from "./constants";
import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitializedCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentSearchFilter !== null;
};

export const useCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.currentSearchFilter) {
    throw new Error("CurrentSearchFilter is not initialized");
  }
  return snapshot.currentSearchFilter;
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};
export const useSortParams = () => {
  const snapshot = useSnapshotState();
  return Def.SortParams[snapshot.pager.sortFieldValue];
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.res;
};

export const useResponse = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Response not found");
  }
  return snapshot.res;
};

export const useSkillTagRes = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.res;
};

export const useCurrentSkillTags = () => {
  const snapshot = useSnapshotState();
  const skillTagIds = Array.from(snapshot.skillTagState.currentSkillTagIdsSet);
  if (skillTagIds.length === 0) {
    return Array.from(snapshot.skillTagState.skillTagSources.values());
  }
  return skillTagIds.reduce<Types.SkillTag[]>((all, skillTagId) => {
    const skillTag = snapshot.skillTagState.skillTagSources.get(skillTagId);
    if (skillTag) {
      all.push(skillTag);
    }
    return all;
  }, []);
};
export const useSkillTagSources = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.skillTagState.skillTagSources.values());
};

export const useSkillTagTextFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.textFilter;
};

export const useSkillTagNextOffset = () => {
  const snapshot = useSnapshotState();
  return snapshot.skillTagState.nextOffset;
};
