import { QUERY_PARAMS_FOR_DEFAULT_QUESTIONS, QuestionTypeMap } from "@hireroo/app-definition/question";
import { useEnabledLeakScore } from "@hireroo/app-helper/feature";
import { compress } from "@hireroo/app-helper/lz-string";
import { QuestionStats, useQuestionVariantLabelMap } from "@hireroo/app-helper/question";
import { App, Company, Payment } from "@hireroo/app-store/essential/employee";
import { QuestionsPackagesIdDetail } from "@hireroo/app-store/page/e/questions_packages_id_detail";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

type Item = Widget.QuestionPackageDetailProps["questionSelector"]["table"]["items"][number];

export type GenerateQuestionPackageDetailPropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionPackageDetailPropsArgs): Widget.QuestionPackageDetailProps => {
  const { t } = useTranslation();
  const questionPackage = QuestionsPackagesIdDetail.useQuestionPackage();
  const lang = useLanguageCode();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const navigate = useTransitionNavigate();
  const questionVariantLabelMap = useQuestionVariantLabelMap();
  const selectedQuestionObjectIds = QuestionsPackagesIdDetail.useSelectedQuestionObjectIds();
  const selectedQuestionIdentities = QuestionsPackagesIdDetail.useSelectedQuestionIdentities();
  const kind = QuestionsPackagesIdDetail.useSelectedKind();
  const isAvailableQuestionByPayment = Payment.useIsAvailableQuestionToUse(kind);
  const enableTalentScore = App.useEnableTalentScore();
  const isAvailableQuestion = React.useCallback(
    (variant: Graphql.QuestionVariant): boolean => {
      if (kind === "exam" && enableTalentScore) {
        return true;
      }
      return isAvailableQuestionByPayment(variant);
    },
    [kind, enableTalentScore, isAvailableQuestionByPayment],
  );

  const enabledLeakScore = useEnabledLeakScore();

  const items = React.useMemo(() => {
    return questionPackage.questionObjects.map((questionObject): Item => {
      const { question } = questionObject;
      const numUses = QuestionStats.getNumUses(question);
      const averageElapsedTimeSeconds = QuestionStats.getAverageElapsedTime(question);
      const accuracyRate = QuestionStats.getAccuracyRate(question);
      const showAccuracyRate = question.__typename !== "FreepadQuestion" && numUses > 0;
      const showElapsedTime = numUses > 0;
      const status = QuestionStats.getQuestionStatus(question);

      const leakScoreChip: Item["leakScoreChip"] = (() => {
        if (!enabledLeakScore) {
          return undefined;
        }
        if (!question.isSupportedLeakScore) {
          return { level: "NONE", reason: "NOT_SUPPORTED" };
        }
        if (!question.leakScore) {
          return { level: "NONE", reason: "NO_DATA" };
        }
        return { level: question.leakScore.level, score: formatScore(question.leakScore.score) };
      })();

      return {
        id: questionObject.questionObjectId,
        leakScoreChip: leakScoreChip,
        showLeakScore: enabledLeakScore,
        meta: {
          title: {
            href: (() => {
              if (question.__typename === "MultiChoicePackage") {
                return generatePath("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.packageId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "AlgorithmQuestion") {
                return generatePath("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.questionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "FreepadQuestion") {
                return generatePath("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.freepadQuestionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "ProjectQuestion") {
                return generatePath("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.questionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else {
                return generatePath("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question?.questionId?.toString() ?? "",
                  },
                });
              }
            })(),
            onClick: event => {
              event.preventDefault();
              event.stopPropagation();
              if (question.__typename === "MultiChoicePackage") {
                navigate("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.packageId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "AlgorithmQuestion") {
                navigate("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.questionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "FreepadQuestion") {
                navigate("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.freepadQuestionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "ProjectQuestion") {
                navigate("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.questionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else {
                navigate("/e/questions/:entityType/:id", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question?.questionId?.toString() ?? "",
                  },
                });
              }
            },
            children: resolveLanguage(questionObject.question, lang, "title"),
          },
          variant: questionVariantLabelMap[questionObject.questionVariant],
          difficulty: question.difficulty,
          isOfficial: question.isOfficial,
          enableLanguages: question.__typename === "AlgorithmQuestion" ? question.supportedLanguages.slice() : undefined,
          mark: "NONE",
        },
        correctnessRate: status === "PUBLISHED" && showAccuracyRate ? formatScore(accuracyRate ?? 0) : 0,
        averageTimeToAnswer:
          status === "PUBLISHED" && showElapsedTime ? `${Time.secondsToFormatMinutes(averageElapsedTimeSeconds, lang)}` : "-",
        numUses: status === "PUBLISHED" ? numUses : 0,
        mode: isAvailableQuestion(questionObject.questionVariant)
          ? {
              kind: "DEFAULT",
            }
          : {
              kind: "LOCKED",
              description: t("ご利用のプランでは、この問題はご利用できません。プランを変更するには、設定ページにアクセスしてください。"),
            },
        options: [
          {
            value: "edit",
            disabled: activeCompanyId !== question.companyId,
            disabledText: t("他社企業が作成した問題は編集できません。"),
            displayName: t("編集"),
            onClick: () => {
              if (question.__typename === "MultiChoicePackage") {
                navigate("/e/questions/:entityType/:id/update", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.packageId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "AlgorithmQuestion") {
                navigate("/e/questions/:entityType/:id/update", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.questionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "FreepadQuestion") {
                navigate("/e/questions/:entityType/:id/update", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.freepadQuestionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else {
                navigate("/e/questions/:entityType/:id/update", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.id,
                  },
                });
              }
            },
          },
        ],
      };
    });
  }, [enabledLeakScore, questionPackage.questionObjects, lang, questionVariantLabelMap, isAvailableQuestion, t, activeCompanyId, navigate]);
  const disableCreateButton = React.useMemo(() => selectedQuestionObjectIds.length === 0, [selectedQuestionObjectIds.length]);
  return {
    title: resolveLanguage(questionPackage, lang, "title"),
    description: resolveLanguage(questionPackage, lang, "description"),
    shortDetail: {
      difficultyStars: {
        difficulty: questionPackage.difficulty,
      },
      questionCount: questionPackage.questionCount,
    },
    variants: {
      text: questionPackage.questionObjects.map(questionObject => questionVariantLabelMap[questionObject.questionVariant]).join(", "),
    },
    questionSelector: {
      selectedQuestionCount: selectedQuestionIdentities.length,
      createCodingTestButton: {
        onClick: () => {
          if (selectedQuestionIdentities.length > 0) {
            const filteredSelectedQuestionIdentities = selectedQuestionIdentities.filter(questionIdentity => {
              return isAvailableQuestion(questionIdentity.questionVariant);
            });
            const value = compress(JSON.stringify(filteredSelectedQuestionIdentities));
            navigate("/e/screenings/create", {
              queryParams: {
                [QUERY_PARAMS_FOR_DEFAULT_QUESTIONS.key]: value,
              },
            });
          }
        },
        onSelected: () => {
          QuestionsPackagesIdDetail.updateSelectedKind("test");
        },
        disabled: disableCreateButton,
        title: disableCreateButton ? t("問題を選択してください") : undefined,
      },
      disabledTooltip: disableCreateButton ? t("問題を選択してください") : "",
      disabledCreateButton: disableCreateButton,
      createTalentScoreButton: {
        onClick: () => {
          if (selectedQuestionIdentities.length > 0) {
            const filteredSelectedQuestionIdentities = selectedQuestionIdentities.filter(questionIdentity =>
              isAvailableQuestion(questionIdentity.questionVariant),
            );
            const value = compress(JSON.stringify(filteredSelectedQuestionIdentities));
            navigate("/e/assessments/create", {
              queryParams: {
                [QUERY_PARAMS_FOR_DEFAULT_QUESTIONS.key]: value,
              },
            });
          }
        },
        onSelected: () => {
          QuestionsPackagesIdDetail.updateSelectedKind("exam");
        },
        disabled: !enableTalentScore || disableCreateButton,
        title: ((): string | undefined => {
          if (!enableTalentScore) {
            return t("お使いのプランではこの機能を利用できません");
          }
          if (disableCreateButton) {
            return t("問題を選択してください");
          }
          return;
        })(),
      },
      table: {
        items: items,
        showLeakScore: enabledLeakScore,
        onChange: fields => {
          const ids = fields.items.filter(item => item.selected).map(item => item.itemId);
          QuestionsPackagesIdDetail.updateQuestionObjectIds(ids);
        },
      },
    },
  };
};
