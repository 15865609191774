import { Resumes } from "@hireroo/app-store/page/e/resumes";
import { ResumeSearchForm } from "@hireroo/validator";

export const convertStringsToSkillTags = (values: string[]): ResumeSearchForm.SkillTags => {
  // Get all the valid string values
  const tags: ResumeSearchForm.SkillTags = [];
  values.forEach(value => {
    value.split("%").forEach(value => {
      tags.push({
        value: value,
      });
    });
  });
  return tags;
};

export const convertStringsToTagNames = (values: string[]): ResumeSearchForm.Tags => {
  // Get all the valid string values
  const tags: ResumeSearchForm.Tags = [];
  values.forEach(value => {
    value.split("%").forEach(value => {
      tags.push({
        displayName: value,
      });
    });
  });
  return tags;
};

export const convertStringsToStatuses = (values: string[]): ResumeSearchForm.StatusSchema[] => {
  const statuses: ResumeSearchForm.StatusSchema[] = [];
  values.forEach(value => {
    value.split("%").forEach(value => {
      const result = ResumeSearchForm.Status.safeParse(value);
      if (result.success) {
        statuses.push(result.data);
      }
    });
  });
  return statuses;
};

export const convertStringToMatchingRate = (value: string | null): number | null => {
  if (value === null) return null;
  const rate = Number(value);
  if (Number.isNaN(rate) || rate < 0 || rate > 100) {
    return null;
  }
  return rate;
};

export const convertStringToResult = (value: string | null): ResumeSearchForm.ResultSchema | null => {
  if (value === null) return null;
  const result = ResumeSearchForm.Result.safeParse(value);
  if (!result.success) {
    return null;
  }
  return result.data;
};

export const convertStringToSortFieldValue = (value: string | null): ResumeSearchForm.SortFieldValue => {
  if (value === null) return Resumes.defaultPager.sortFieldValue;
  const result = ResumeSearchForm.SortFieldValue.safeParse(value);
  if (!result.success) {
    return Resumes.defaultPager.sortFieldValue;
  }
  return result.data;
};

export const convertStringsToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (page < 1) {
    return 0;
  }
  return page - 1;
};

const SIZES = [20, 30, 50];
export const convertStringToSize = (value: string | null) => {
  if (value === null) return Resumes.defaultPager.size;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return Resumes.defaultPager.size;
  }
  return size;
};
