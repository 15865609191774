import { Resumes } from "@hireroo/app-store/page/e/resumes";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return Resumes.subscribeAllState(state => {
    if (!state.currentSearchFilter) {
      return;
    }
    const { currentSearchFilter, pager } = state;

    const sameMaxMatchingRate = currentSearchFilter?.matchingRate.max === Resumes.defaultFilters.matchingRate.max;
    const sameMinMatchingRate = currentSearchFilter?.matchingRate.min === Resumes.defaultFilters.matchingRate.min;

    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [Resumes.QueryKeys.TAG]: currentSearchFilter.tags.map(tag => tag.displayName),
      [Resumes.QueryKeys.NAME]:
        currentSearchFilter.textFilter && currentSearchFilter.textFilter.length > 0 ? [currentSearchFilter.textFilter] : [],
      [Resumes.QueryKeys.RESULT]: currentSearchFilter?.result ? [currentSearchFilter.result] : [],
      [Resumes.QueryKeys.SKILL_TAG]: currentSearchFilter.skillTags.map(skillTag => skillTag.value),
      [Resumes.QueryKeys.STATUS]: currentSearchFilter?.statuses ?? [],
      [Resumes.QueryKeys.MAX_MATCHING_RATE]:
        typeof currentSearchFilter?.matchingRate?.max === "number" && !sameMaxMatchingRate
          ? [currentSearchFilter?.matchingRate.max.toFixed(0)]
          : [],
      [Resumes.QueryKeys.MIN_MATCHING_RATE]:
        typeof currentSearchFilter?.matchingRate?.min === "number" && !sameMinMatchingRate
          ? [currentSearchFilter?.matchingRate.min.toFixed(0)]
          : [],
      [Resumes.QueryKeys.SORT]: pager.sortFieldValue === Resumes.defaultPager.sortFieldValue ? [] : [pager.sortFieldValue],
      /**
       * Set page number to "1" if it is not a page change
       */
      [Resumes.QueryKeys.PAGE]: pager.page > 0 ? [(pager.page + 1).toString()] : [],
      [Resumes.QueryKeys.SIZE]: pager.size !== Resumes.defaultPager.size ? [pager.size.toString()] : [],
    });
  });
};
