import { useTranslation } from "@hireroo/i18n";
import { ResumeListForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import ResumeTableRow, { ResumeTableRowProps } from "../ResumeTableRow/ResumeTableRow";

const StyledCheckbox = styled(Checkbox)(() => ({
  width: "38px",
  height: "38px",
  padding: "9px",
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "normal",
  fontSize: "12px",
  border: "none",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  height: 40,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type ResumeTableProps = {
  items: Omit<ResumeTableRowProps, "name">[];
  onChange?: (fields: ResumeListForm.ResumeListForm) => void;
  defaultValues: ResumeListForm.ResumeListForm;
};

const ResumeTable: React.FC<ResumeTableProps> = props => {
  const { onChange } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = ResumeListForm.useResumeListForm();
  const methods = useForm<ResumeListForm.ResumeListForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const [mode, setMode] = React.useState<ResumeTableRowProps["mode"]>("DEFAULT");

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      const fields = methods.getValues();
      const hasSelected = fields.items.some(item => item.selected);
      setMode(hasSelected ? "SELECTABLE" : "DEFAULT");
      onChange?.(fields);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onChange]);

  const isAllSelected = methods.watch("items").every(item => item.selected);
  const isIndeterminate = methods.watch("items").some(item => item.selected) && !isAllSelected;

  const checkboxProps: CheckboxProps = {
    checked: isAllSelected,
    indeterminate: isIndeterminate,
    color: "secondary",
    size: "small",
    onClick: event => {
      event.stopPropagation();
      const newFieldValue = methods.getValues().items.map(item => {
        return {
          itemId: item.itemId,
          selected: isIndeterminate ? false : !isAllSelected,
        };
      });
      methods.reset({ items: newFieldValue });
    },
  };

  if (props.items.length === 0) {
    return (
      <Box sx={{ width: "100%" }} p={2}>
        <Typography variant="body2" textAlign="center" color="textSecondary">
          {t("該当の書類選考が見つかりません")}
        </Typography>
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <TableContainer sx={{ overflow: "auto" }}>
        <Table component="div" sx={{ display: "table" }}>
          <TableHead component="div" sx={{ display: "table-header-group" }}>
            <TableRow sx={{ borderTop: "1px solid", borderBottom: "1px solid", borderColor: theme.palette["Gray/Shades"].p8 }} component="div">
              <StyledTableHeaderCell align="center" component="div">
                <StyledCheckbox {...checkboxProps} />
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left" component="div" width="20%">
                {t("名前")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left" component="div">
                {t("スキルタグ")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left" component="div">
                {t("ステータス")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="center" component="div" width="100px">
                {t("マッチングレート")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="left" component="div">
                {t("結果")}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell align="right" component="div">
                {/* for MoreButton */}
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {props.items.map((itemProps, index) => (
              <ResumeTableRow key={itemProps.id} {...itemProps} name={`items.${index}`} mode={mode} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormProvider>
  );
};

ResumeTable.displayName = "ScreeningTable";

export default ResumeTable;
