import { Resumes } from "@hireroo/app-store/page/e/resumes";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type TagSources = Widget.ResumeSearchAreaProps["skillTagsField"]["tagSources"];

export const useGenerateSkillTagsFieldProps = (): Widget.ResumeSearchAreaProps["skillTagsField"] => {
  const { t } = useTranslation();
  const skillTagRes = Resumes.useSkillTagRes();
  const currentSkillTags = Resumes.useCurrentSkillTags();
  const skillTagSources = Resumes.useSkillTagSources();
  const textFilter = Resumes.useSkillTagTextFilter();
  const nextOffset = Resumes.useSkillTagNextOffset();

  const [result, refresh] = Graphql.useGetHierarchicalSkillTagsForResumesQuery({
    variables: {
      input: {
        name: textFilter,
        depth: 1,
        offset: nextOffset,
        limit: Resumes.SKILL_TAG_SIZE,
        withCount: false,
      },
    },
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      Resumes.updateSkillTags(result.data.hierarchicalSkillTags);
    }
  }, [result.data]);

  return {
    selectableTags: currentSkillTags.map(skillTag => ({ value: skillTag.skillTagNodeId })),
    tagSources: skillTagSources.reduce<TagSources>((all, skillTag) => {
      all[skillTag.skillTagNodeId] = {
        label: skillTag.name,
      };
      return all;
    }, {}),
    onChange: fields => {
      // Clear skillTagState fields if the field is empty
      if (fields.length === 0) {
        Resumes.clearCurrentSkillTagState();
      }
    },
    splitButton: {
      onOpen: () => {
        if (!result.fetching) {
          refresh();
        }
      },
      onClose: () => {
        Resumes.clearCurrentSkillTagState();
      },
    },
    textFilter: {
      placeholder: `${t("タグを検索")} ${t("関連するタグも表示されます。")}`,
      onSubmit: fields => {
        if (fields.text !== textFilter) {
          Resumes.clearCurrentSkillTagState();
        }
        Resumes.updateSkillTagTextFilter(fields.text);
      },
    },
    scrollableTags: {
      onReachedEnd: () => {
        if (skillTagRes?.hasNext && !result.fetching) {
          Resumes.updateSkillTagNextOffset();
        }
      },
      loading: result.fetching,
    },
  };
};
