import { SkillMapSettings } from "@hireroo/app-store/widget/e/SkillMapSettings";
import { useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateSkillTagListPropsArgs = {
  loading: boolean;
};

export const useGenerateProps = (args: GenerateSkillTagListPropsArgs): Widget.SkillTagListProps => {
  const { t: t2 } = useTranslationWithVariable();
  const res = SkillMapSettings.useRes();
  const skillTags = SkillMapSettings.useSkillTags();

  return {
    searchResult: res ? t2("SearchResultCount", { count: res.count }) : "-",
    skillTags: skillTags.map(tag => ({ label: tag.name, value: tag.skillTagNodeId })),
    loading: args.loading,
    onReachedEnd: () => {
      if (res?.hasNext && !args.loading) {
        SkillMapSettings.updateNextOffset();
      }
    },
  };
};
