import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { CodingToolbarProps } from "../../modules/CodingToolbar/CodingToolbar";
import QuestionListSelector, { QuestionListSelectorProps } from "./parts/QuestionListSelector/QuestionListSelector";
import QuizQuestionCard, { QuizQuestionCardProps } from "./parts/QuizQuestionCard/QuizQuestionCard";

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export type QuizCodingEditorProps = {
  status: "LOADING" | "READY";
  codingToolbar: CodingToolbarProps;
  packageTitle: string;
  quizQuestionCard: QuizQuestionCardProps;
  buttonGroup: {
    submitButton: LoadingButtonProps;
    backButton: LoadingButtonProps;
  };
  questionListSelector: QuestionListSelectorProps;
};

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  minWidth: "128px",
  "&:hover": {
    borderColor: theme.palette.secondary.main,
  },
}));

const QuizCodingEditor: React.FC<QuizCodingEditorProps> = props => {
  return (
    <Wrapper>
      <Box display="flex" justifyContent="center" m={4} minHeight="450px" gap={4} width="95vw">
        {props.status === "LOADING" ? (
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography variant="h4" fontWeight="bold">
              {props.packageTitle}
            </Typography>
            <Box display="flex" gap={2} mb={6} mt={2}>
              <Box width="65vw">
                <QuizQuestionCard {...props.quizQuestionCard} />
                <Stack spacing={1} direction="row" justifyContent="center" mt={2}>
                  <StyledButton {...props.buttonGroup.backButton} color="secondary" variant="outlined" />
                  <StyledButton {...props.buttonGroup.submitButton} variant="contained" color="secondary" />
                </Stack>
              </Box>
              <Box width="25vw">
                <QuestionListSelector {...props.questionListSelector} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

QuizCodingEditor.displayName = "QuizCodingEditor";

export default QuizCodingEditor;
