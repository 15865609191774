import { useTranslation } from "@hireroo/i18n";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Group from "@mui/icons-material/Group";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButtonWithTooltip, {
  IconButtonWithTooltipProps,
} from "../../../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import ListContent, { ListContentProps } from "../ListContent/ListContent";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "16px",
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: "14px",
  variant: "subtitle2",
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 24,
  ".MuiChip-deleteIcon": {
    color: theme.palette["Gray/Shades"].p16,
  },
  backgroundColor: theme.palette["Gray/Shades"].p16,
}));

type MemberAndGroupChip = ChipProps & {
  photoUrl?: string;
  kind: "MEMBER" | "GROUP";
};

type AccessPermission =
  | {
      kind: "PUBLIC";
    }
  | {
      kind: "PRIVATE";
      authorizedMemberAndGroups: MemberAndGroupChip[];
    };

export type GeneralSummaryProps = {
  tags: string[];
  accessPermission: AccessPermission;
  editTabButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  editAccessPermissionButton: Pick<IconButtonWithTooltipProps, "onClick" | "disabled" | "title">;
};

const GeneralSummary: React.FC<GeneralSummaryProps> = props => {
  const { t } = useTranslation();
  const editTagButton: IconButtonWithTooltipProps = {
    ...props.editTabButton,
    children: <EditOutlined fontSize="small" />,
  };
  const editAccessPermissionButton: IconButtonWithTooltipProps = {
    ...props.editAccessPermissionButton,
    children: <EditOutlined fontSize="small" />,
  };

  const listContents: ListContentProps[] = [
    {
      title: t("タグ"),
      children: (
        <Box display="flex">
          {props.tags.length > 0 ? (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.tags.map(tagName => (
                <StyledChip label={tagName} key={`tag-chip-${tagName}`} />
              ))}
            </Stack>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <StyledTypography>{t("タグが指定されていません。")}</StyledTypography>
            </Box>
          )}
          <Box flexGrow={1} />
          <IconButtonWithTooltip {...editTagButton} />
        </Box>
      ),
    },
    {
      title: t("閲覧権限"),
      children: (
        <Box display="flex" alignItems="center">
          {props.accessPermission.kind === "PUBLIC" && <StyledTypography>{t("メンバー全員に許可する。")}</StyledTypography>}
          {props.accessPermission.kind === "PRIVATE" && (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.accessPermission.authorizedMemberAndGroups.map((authorized, index) => {
                const { kind, photoUrl, ...rest } = authorized;
                return <Chip key={`${authorized.key}-${index}`} {...rest} avatar={kind === "GROUP" ? <Group /> : <Avatar src={photoUrl} />} />;
              })}
            </Stack>
          )}
          <Box flexGrow={1} />
          <IconButtonWithTooltip {...editAccessPermissionButton} />
        </Box>
      ),
    },
  ];
  return (
    <StyledBox>
      <List component="nav" aria-label="coding-test-summary" sx={{ px: 2 }}>
        {listContents.map((content, index) => (
          <ListContent key={content.title} {...content} divider={index !== listContents.length - 1} />
        ))}
      </List>
    </StyledBox>
  );
};

GeneralSummary.displayName = "GeneralSummary";

export default GeneralSummary;
