import { useFailureReasonMap, useInvalidMulterUploadStatusMap } from "@hireroo/app-definition/resume";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { formatFileSize } from "@hireroo/app-helper/files";
import { createContentIncludedFormData } from "@hireroo/app-helper/form-data";
import { Auth, Company } from "@hireroo/app-store/essential/employee";
import { Resumes } from "@hireroo/app-store/page/e/resumes";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ContentApi, ErrorResponse, getContentApiClient } from "@hireroo/rest-api/client";
import { ResumeDocumentForm, UploadResumesSchema } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import ResumeTagFieldContainer from "../../../../../../widget/v2/e/ResumeTagField/Container";
import ViewerAssignFieldContainer from "../../../../../../widget/v2/e/ViewerAssignField/Container";
import * as PrivateHelper from "./privateHelper";

type Row = Widget.ResumeDocumentUploadDialogProps["documentTable"]["rows"][number];

const MAX_FILE_COUNT = 20;
const MAX_FILE_SIZE = "10MB";

export type GenerateResumeDocumentUploadDialogPropsArgs = {
  dialog: Widget.ResumeDocumentUploadDialogProps["dialog"];
};

export const useGenerateProps = (args: GenerateResumeDocumentUploadDialogPropsArgs): Widget.ResumeDocumentUploadDialogProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const companyId = Company.useStrictActiveCompanyId();
  const currentUser = Auth.useCurrentUser();
  const methods = useFormContext<ResumeDocumentForm.ResumeDocumentFormSchema>();
  const contentApiClient = getContentApiClient();
  const [loading, setLoading] = React.useState(false);
  const { updateStep } = Widget.useResumeDocumentUploadDialogContext();
  const failureReasonMap = useFailureReasonMap();
  const invalidMulterUploadStatusMap = useInvalidMulterUploadStatusMap();

  const resumeDocuments = methods.watch("resumeDocuments");
  const rows = React.useMemo((): Row[] => {
    return resumeDocuments.map((resumeDoc, index): Row => {
      return {
        name: `resumeDocuments.${index}.name`,
        errorMessageName: `resumeDocuments.${index}`,
        fileName: resumeDoc.fileName,
        size: `${formatFileSize(resumeDoc.size)}`,
        deleteButton: {
          onClick: () => {
            methods.setValue(
              "resumeDocuments",
              resumeDocuments.filter(fields => fields.id !== resumeDoc.id),
            );
            methods.clearErrors(`resumeDocuments.${index}`);
          },
        },
      };
    });
  }, [methods, resumeDocuments]);
  return {
    dialog: args.dialog,
    onSubmit: fields => {
      setLoading(true);
      const formData = createContentIncludedFormData({
        type: "MULTI",
        files: fields.resumeDocuments.map(resumeDoc => resumeDoc.rawFile),
      });
      PrivateHelper.addUploadRequestFormData(formData, {
        companyId: companyId.toString(),
        createdBy: currentUser.uid,
        tagNames: fields.tags.map(tag => tag.value.name),
        viewerIds: fields.viewers.map((viewer): Exclude<UploadResumesSchema.UploadResumeRequestSchema["viewerIds"], undefined>[number] => {
          if (!viewer.value) {
            throw new Error("viewerId is not found");
          }
          const type = viewer.value.type;
          switch (type) {
            case "EMPLOYEE": {
              return {
                type: "EMPLOYEE",
                viewerId: viewer.value.employeeId,
              };
            }
            case "EMPLOYEE_GROUP": {
              return {
                type: "GROUP",
                viewerId: viewer.value.groupId,
              };
            }
            case "TALENT":
              throw new Error("Invalid viewerId type talent");
            default:
              throw new Error(`Invalid argument: ${type satisfies never}`);
          }
        }),
        resumeMetas: resumeDocuments.map(
          (resumeDoc): UploadResumesSchema.ResumeMetaSchema => ({
            name: resumeDoc.name,
            fileId: resumeDoc.id,
          }),
        ),
      });

      contentApiClient
        .uploadResumes({
          requestBody: formData,
        })
        .then(res => {
          const failedRes = res.results.filter(result => result.status === "FAILED");
          if (failedRes.length > 0) {
            Snackbar.notify({
              message: t2("MultiUploadFailed", { num: failedRes.length }),
              severity: "error",
            });
            const failedResultMap = new Map<string, ContentApi.Schemas.UploadResumesResponse["results"][number]>();
            failedRes.forEach(failed => {
              failedResultMap.set(failed.fileId, failed);
            });
            resumeDocuments.forEach((resumeDoc, index) => {
              const failedResult = failedResultMap.get(resumeDoc.id);
              if (failedResult) {
                methods.setError(`resumeDocuments.${index}`, {
                  type: "manual",
                  message: failureReasonMap[failedResult.failureReason ?? "UNKNOWN"],
                });
              }
            });
            methods.setValue(
              "resumeDocuments",
              resumeDocuments.filter(resumeDoc => failedResultMap.has(resumeDoc.id)),
            );
            updateStep("DOCUMENT_LIST");
          } else {
            Snackbar.notify({
              message: t("書類をアップロードしました。"),
              severity: "success",
            });
            Resumes.refreshRes();
            args.dialog.onClose?.();
          }
        })
        .catch(async error => {
          if (error instanceof ErrorResponse) {
            const errorJson = await error.json((obj): obj is ContentApi.Response$uploadAddResumeFiles$Status$400["application/json"] => {
              return "errorStatus" in obj && typeof obj.errorStatus === "string";
            });
            Snackbar.notify({
              message: t("書類のアップロードに失敗しました。") + invalidMulterUploadStatusMap[errorJson.errorStatus],
              severity: "error",
            });
            updateStep("DOCUMENT_LIST");
            return;
          }
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類のアップロードに失敗しました。"));
          Snackbar.notify({
            message: errorNotification.message,
            severity: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    optionalContent: {
      ViewerField: <ViewerAssignFieldContainer name="viewers" variant="OUTLINED" />,
      TagField: <ResumeTagFieldContainer name="tags" variant="OUTLINED" />,
    },
    documentTable: {
      dragAndDropContent: {
        maxFileCount: MAX_FILE_COUNT,
        maxFileSize: MAX_FILE_SIZE,
      },
      rows: rows,
    },
    loading: loading,
  };
};
