import "./xterm.css";

import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { ITerminalOptions } from "xterm";

import { useProjectContext } from "../../../../../store-v3";

const StyledBox = styled(Box)(() => ({
  "& .terminal.xterm": {
    height: "100%",
  },
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));

export type ProjectConsoleProps = {
  endpoint?: string;
};

const BUFFER_SPACE = 20;

const ProjectConsole: React.FC<ProjectConsoleProps> = props => {
  const theme = useTheme();
  const { endpoint } = props;
  const Store = useProjectContext();

  const parentRef = React.useRef<HTMLDivElement>(null);
  const terminalRef = React.useRef<HTMLDivElement | null>(null);
  const wrapper = useResizeDetector({
    targetRef: parentRef,
  });

  React.useEffect(() => {
    Store.action.handleResize();
  }, [Store.action, wrapper.height, wrapper.width]);

  React.useEffect(() => {
    if (!endpoint || !terminalRef.current) return;

    const terminalOption: ITerminalOptions = {
      theme: {
        background: theme.palette["Secondary/Shades"].p12,
      },
      allowTransparency: true,
    };
    Store.action.initializeTerminal(endpoint, terminalRef.current, terminalOption);

    return () => {
      Store.action.cleanUpTerminal();
    };
  }, [Store.action, endpoint, theme.palette]);

  const adjustHeight: number = React.useMemo(() => {
    if (!parentRef.current) {
      return 0;
    }
    const rect = parentRef.current.getBoundingClientRect();
    const overhangHeight = rect.y + (wrapper.height ?? 0) - window.innerHeight;
    return Math.ceil(overhangHeight + BUFFER_SPACE);
  }, [wrapper.height]);

  const height = React.useMemo(() => {
    return Math.floor((wrapper.height ?? 0) - adjustHeight);
  }, [adjustHeight, wrapper.height]);

  // specify background color by the time terminal is initialized
  const boxBgColor = endpoint && terminalRef.current ? undefined : theme.palette["Secondary/Shades"].p12;

  return (
    <StyledBox className="terminal-panel" bgcolor={boxBgColor} ref={parentRef}>
      <div
        id="terminal"
        ref={terminalRef}
        style={{
          height: "100%",
          width: wrapper.width,
          maxHeight: height,
        }}
      />
    </StyledBox>
  );
};

ProjectConsole.displayName = "ProjectConsole";

export default ProjectConsole;
