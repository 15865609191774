import { useEnabledNewProjectEditor } from "@hireroo/app-helper/feature";
import { BrowserWindowEventDetect, useFirebaseSender, useProjectRealtimeDatabase } from "@hireroo/app-helper/hooks";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getTimestamp } from "@hireroo/firebase";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as ProjectHelpers from "@hireroo/project/helpers/fileTree";
import { findNode } from "@hireroo/project/helpers/fileTree";
import { useFocusNode } from "@hireroo/project/helpers/hooks";
import { FirebaseStorePath, HistoriesPathsModel } from "@hireroo/project-shared-utils";
import * as Sentry from "@sentry/react";
import * as React from "react";

import { useAgentServerHealthCheck, useApplicationServerHealthCheck, useEndpoints } from "./privateHelpers";
import type { Mode } from "./types";
import { useGenerateAppealMessageProps } from "./useAppealMessageProps";
import { useFileTree } from "./useFileTree";
import { useGenerateBackendTestCaseDialogProps } from "./useGenerateBackendTestCaseDialog";
import { useGenerateDataScienceTestCaseDialogProps } from "./useGenerateDataScienceTestCaseDialogProps";
import { useGenerateDefaultTestCaseDialogProps } from "./useGenerateDefaultTestCaseDialog";
import { useGenerateFrontendTestCaseDialogProps } from "./useGenerateFrontendTestCaseDialogProps";
import { useGenerateTerminalProps } from "./useGenerateTerminalProps";
import { StartWorkspaceErrorStatus, useStartWorkspace } from "./useStartWorkspace";
import ExtendedCodingEditorContainer, { ExtendedCodingEditorContainerProps } from "./widgets/ExtendedCodingEditor/Container";

export type GenerateProjectCodingEditorV3PropsArgs = {
  mode: Mode;
  endpointId: number;
  uid: string;
  entityId: number;
  questionId: number;
  questionVersion: string;
  submissionId: number;
  isCandidate: boolean;
  enableBrowserEventDetector: boolean;
  userName: string;
  historiesPathsModel: HistoriesPathsModel;
};

export const useGenerateProps = (args: GenerateProjectCodingEditorV3PropsArgs): Widget.ProjectCodingEditorV3Props => {
  const { historiesPathsModel } = args;
  const lang = useLanguageCode();
  const hooks = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const action = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const client = getGraphqlClient();
  const entity = hooks.useEntity();
  const { t } = useTranslation();
  const { question } = entity;
  const completed = hooks.useCompleted();
  const [openTestCaseDialog, setOpenTestCaseDialog] = React.useState(false);
  const [resetOption, setResetOption] = React.useState<"withoutSourceCode" | "withSourceCode" | null>(null);
  const [resetDevelopmentWorkspaceResult, resetDevelopmentWorkspace] = Graphql.useResetDevelopmentWorkspaceForProjectCodingEditorMutation();
  const [resetEvaluationWorkspaceResult, resetEvaluationWorkspace] = Graphql.useResetEvaluationWorkspaceForProjectCodingEditorMutation();
  const { setWorkspace, setAgentServerHealth, setApplicationServerHealth } = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const { useWorkspace, useAgentServerHealth, useApplicationServerHealth } = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const workspace = useWorkspace();
  const agentServerHealth = useAgentServerHealth();
  const applicationServerHealth = useApplicationServerHealth();
  const [status, setStatus] = React.useState<"READY" | "RUNNING_TEST_CASE">("READY");
  const ipAddress = hooks.useIpAddress();
  const geolocation = hooks.useGeolocation();
  const enabledNewProjectEditor = useEnabledNewProjectEditor();

  const isResetWorkspaceLoading = React.useMemo(() => {
    const isResetWorkspaceLoadingMap: Record<Mode, boolean> = {
      DEVELOPMENT: resetDevelopmentWorkspaceResult.fetching,
      EVALUATION: resetEvaluationWorkspaceResult.fetching,
    };
    return isResetWorkspaceLoadingMap[args.mode];
  }, [args.mode, resetDevelopmentWorkspaceResult.fetching, resetEvaluationWorkspaceResult.fetching]);
  const resetWorkspaceError = React.useMemo(() => {
    const resetWorkspaceErrorMap: Record<Mode, StartWorkspaceErrorStatus> = {
      DEVELOPMENT: resetDevelopmentWorkspaceResult.error === undefined ? "PENDING" : "ERROR",
      EVALUATION: resetEvaluationWorkspaceResult.error === undefined ? "PENDING" : "ERROR",
    };
    return resetWorkspaceErrorMap[args.mode];
  }, [args.mode, resetDevelopmentWorkspaceResult.error, resetEvaluationWorkspaceResult.error]);

  const { pushEventHistory, pushAccessEvent } = useFirebaseSender({
    appType: "project",
    uid: args.uid,
    projectId: args.entityId,
    questionId: args.questionId,
  });
  const browserWindowEventDetector = React.useRef(
    new BrowserWindowEventDetect({
      callback: pushEventHistory,
    }),
  );

  React.useEffect(() => {
    if (!args.enableBrowserEventDetector) {
      return;
    }
    if (entity.projectStatus !== "STARTED") {
      return;
    }
    const stop = browserWindowEventDetector.current.subscribe();
    return () => {
      stop();
    };
  }, [entity.projectStatus, args.enableBrowserEventDetector, ipAddress, geolocation, pushAccessEvent]);

  React.useEffect(() => {
    const shouldDetectEvents = args.enableBrowserEventDetector && entity.projectStatus === "STARTED";

    if (!shouldDetectEvents) {
      return;
    }

    if (ipAddress !== null && geolocation !== null) {
      pushAccessEvent({
        l: ipAddress,
        g: geolocation,
        t: getTimestamp(),
      });
    }
  }, [ipAddress, geolocation, pushAccessEvent, args.enableBrowserEventDetector, entity.projectStatus]);
  const endpoints = useEndpoints({
    id: args.endpointId,
    mode: args.mode,
  });
  useAgentServerHealthCheck(args.entityId, endpoints.agentHealthCheck);
  React.useEffect(() => {
    if (agentServerHealth) {
      ScreeningTestTutorial.autoStartTutorial("PROJECT_V3");
    }
  }, [agentServerHealth]);
  useApplicationServerHealthCheck(args.entityId, endpoints.appHealthCheck, agentServerHealth);
  const appealMessageProps = useGenerateAppealMessageProps({
    mode: args.mode,
    appealMessage: entity.appealMessage ?? "",
    entityId: args.entityId,
  });
  const { isStartWorkspaceLoading, startWorkspaceError } = useStartWorkspace({
    mode: args.mode,
    entityId: args.entityId,
    questionId: args.questionId,
    questionVersion: args.questionVersion,
    submissionId: args.submissionId,
  });
  const [state] = useProjectRealtimeDatabase({
    projectId: args.entityId,
    isInterviewing: !completed && !isStartWorkspaceLoading && !isResetWorkspaceLoading,
    isCandidate: args.isCandidate,
    selectedQuestion: args.questionId,
  });
  const [treeState, treeAction] = useFileTree({
    entityId: args.entityId,
    endpoint: endpoints.fileSync,
  });
  const { focusedNode, setFocusNode } = useFocusNode(".", treeState.fileTree);
  const projectNotReady = isStartWorkspaceLoading || isResetWorkspaceLoading || !state.ready || !treeState.ready;
  const editorValue = React.useMemo(() => {
    if (!treeState.selectedFile) {
      return null;
    }
    return ProjectHelpers.findNode(treeState.fileTree, treeState.selectedFile)?.value ?? null;
  }, [treeState.fileTree, treeState.selectedFile]);

  const lspEndpoint = React.useMemo(() => {
    if (!projectNotReady && treeState.ready) {
      return endpoints.lsp;
    }
    return undefined;
  }, [endpoints.lsp, projectNotReady, treeState.ready]);

  /**
   * TODO: Check build script rather than question variant
   */
  const enableTerminal = question?.variant === "Backend" || question?.variant === "Frontend";

  const currentDirectoryFiles: string[] = React.useMemo((): string[] => {
    const node: ProjectHelpers.FileNode | null = ProjectHelpers.findNode(treeState.fileTree, focusedNode);
    if (node?.isDir) {
      return Object.keys(node.children || {});
    }
    const parent = ProjectHelpers.findParent(treeState.fileTree, focusedNode);
    return Object.keys(parent?.children || {});
  }, [focusedNode, treeState.fileTree]);

  const terminalProps = useGenerateTerminalProps({
    ready: !projectNotReady && treeState.ready,
    endpoint: endpoints.build,
    runTestCaseButton: {
      disabled: args.mode === "DEVELOPMENT" && completed,
      loading: status === "RUNNING_TEST_CASE" || !agentServerHealth,
      onClick: () => {
        if (question?.variant === "Default") {
          Snackbar.notify({
            severity: "info",
            message: t("実行が開始しました。処理が終わるまでしばらくお待ち下さい。"),
          });
          setStatus("RUNNING_TEST_CASE");

          if (!workspace) {
            Snackbar.notify({
              severity: "error",
              message: t("実行に失敗しました。運営までお問い合わせください。"),
            });
            Sentry.captureException("failed to run project for project since workspace is not found");
            setOpenTestCaseDialog(true);
            setStatus("READY");
            return;
          }

          client
            .RunProjectForProjectCodingEditor({
              input: {
                projectId: args.entityId,
                questionId: args.questionId,
                questionVersion: args.questionVersion,
                testCase: question.defaultTestCase,
                workspaceId: workspace.id,
                takeSnapshot: args.mode === "DEVELOPMENT",
              },
            })
            .then(res => {
              action.setDefaultTestCaseState({
                status: "DEFAULT",
                snapshotStatus: res.runProject.status,
                failureReason: res.runProject.failureReason,
              });
              action.setTestCaseResultForDefault(res.runProject.testResult);
              Snackbar.notify({
                severity: "success",
                message: t("実行が完了しました。結果を確認してください。"),
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              Snackbar.notify({
                severity: "error",
                message: t("実行に失敗しました。"),
              });
            })
            .finally(() => {
              setOpenTestCaseDialog(true);
              setStatus("READY");
            });
        } else {
          setOpenTestCaseDialog(true);
        }
      },
    },
    submitButton: {
      disabled: args.mode === "EVALUATION" || completed,
      loading: !agentServerHealth,
    },
    showConsole: enableTerminal,
  });

  const frontendTestCaseDialogProps = useGenerateFrontendTestCaseDialogProps({
    open: openTestCaseDialog,
    entityId: args.entityId,
    mode: args.mode,
  });
  const backendTestCaseDialog = useGenerateBackendTestCaseDialogProps({
    open: openTestCaseDialog,
    entityId: args.entityId,
    mode: args.mode,
  });
  const dataScienceTestCaseDialogProps = useGenerateDataScienceTestCaseDialogProps({
    open: openTestCaseDialog,
    entityId: args.entityId,
    mode: args.mode,
  });
  const defaultTestCaseDialog = useGenerateDefaultTestCaseDialogProps({
    open: openTestCaseDialog,
    entityId: args.entityId,
    testCaseResult: hooks.useDefaultTestCaseResult(),
  });

  const selectedNode = React.useMemo(() => {
    return findNode(treeState.fileTree, treeState.selectedFile ?? "");
  }, [treeState.fileTree, treeState.selectedFile]);

  React.useEffect(() => {
    if (!treeState.ready) {
      return;
    }
    const list = ProjectHelpers.listAllFilePaths(treeState.fileTree).filter(filepath => !!filepath && filepath !== ".");
    historiesPathsModel.initialize(list);
  }, [historiesPathsModel, historiesPathsModel.initialize, treeState]);

  const fileContentHistoryStoredPath = React.useMemo((): string | null => {
    if (!treeState.selectedFile) {
      return null;
    }
    const fileId = historiesPathsModel.getLatestFileIdByFilepath(treeState.selectedFile);
    if (!fileId) {
      return null;
    }
    return FirebaseStorePath.generateFileContentHistoryStoredPath({
      filepath: treeState.selectedFile,
      fileId: fileId,
    });
  }, [historiesPathsModel, treeState.selectedFile]);

  const extendedCodingEditorContainerProps = React.useMemo((): ExtendedCodingEditorContainerProps | undefined => {
    if (!fileContentHistoryStoredPath || !treeState.selectedFile) {
      return;
    }

    return {
      uid: args.uid,
      value: editorValue ?? "",
      filepath: treeState.selectedFile,
      uri: `file://${treeState.cwd}/${treeState.selectedFile}?w=${args.entityId}-${fileContentHistoryStoredPath}`,
      userName: args.userName,
      rdbKey: `projects/${args.entityId}/questions/${args.questionId}${fileContentHistoryStoredPath}`,
      readOnly: selectedNode?.isRead ?? false,
      lspServerUrl: lspEndpoint,
    };
  }, [
    args.entityId,
    args.questionId,
    args.uid,
    args.userName,
    editorValue,
    fileContentHistoryStoredPath,
    lspEndpoint,
    selectedNode?.isRead,
    treeState.cwd,
    treeState.selectedFile,
  ]);

  const workspaceProps = React.useMemo((): Widget.ProjectCodingEditorV3Props["workspace"] => {
    if (!treeState.selectedFile) {
      return {
        mode: "IDLE",
        idlePanel: {
          messages: [
            t("左側のファイルツリーから編集したいファイルを選択できます。"),
            t("エディタ下のターミナルには起動中のサーバーのログが表示されます。"),
          ],
        },
      };
    }
    const isNotebook = question?.variant === "DataScience" && treeState.selectedFile?.match(/.ipynb$/);
    const readOnly = selectedNode?.isReadOnly ?? false;
    if (isNotebook) {
      return {
        mode: "JUPYTER_NOTEBOOK",
        notebook: {
          filename: treeState.selectedFile ?? "",
          config: {
            baseUrl: endpoints.httpBase,
            wsUrl: endpoints.wsBase,
            token: "",
          },
        },
      };
    }

    if (enabledNewProjectEditor) {
      return {
        mode: "INJECTION",
        children: extendedCodingEditorContainerProps && (
          <ExtendedCodingEditorContainer
            key={`${args.entityId.toString()}-${extendedCodingEditorContainerProps.rdbKey}`}
            {...extendedCodingEditorContainerProps}
          />
        ),
      };
    }

    return {
      mode: "MONACO",
      codeEditor: {
        workspaceId: args.entityId.toString(),
        readOnly,
        selectedSourceFile: treeState.selectedFile,
        lspEndpoint: lspEndpoint,
        editorValue: editorValue,
        typeDefs: {
          react: TYPE_DEF_REACT,
        },
        cwd: treeState.cwd,
        editorValueDidChange: (selectedFile, editorValue) => {
          treeAction.update(selectedFile, editorValue);
        },
      },
    };
  }, [
    treeState.selectedFile,
    treeState.cwd,
    question?.variant,
    selectedNode?.isReadOnly,
    enabledNewProjectEditor,
    args.entityId,
    lspEndpoint,
    editorValue,
    t,
    endpoints.httpBase,
    endpoints.wsBase,
    extendedCodingEditorContainerProps,
    treeAction,
  ]);

  const renderTreeProps = React.useMemo((): Widget.ProjectCodingEditorV3Props["fileNavigation"]["renderTree"] => {
    if (enabledNewProjectEditor) {
      // TODO @Himenon 初期コードの取得の実装を行うときに、Agent Serverと疎通ができるまでの間にrenderTreePropsを構築する実装をいれる
    }
    return {
      node: treeState.fileTree,
      onSelectFile: sourceFile => {
        setFocusNode(sourceFile);
        treeAction.selectFile(sourceFile);
      },
      onSelectDirectory: directory => {
        setFocusNode(directory);
      },
    };
  }, [setFocusNode, treeAction, treeState.fileTree, enabledNewProjectEditor]);

  return {
    enableTerminal: enableTerminal,
    sidebar: {
      questionSection: {
        title: resolveLanguage(question || {}, lang, "title"),
        description: resolveLanguage(question || {}, lang, "description"),
        /**
         * Always `false` as the user does not need to know if the issue has been archived during testing
         */
        isArchived: false,
      },
      appealMessage: appealMessageProps,
    },
    notificationPanel: React.useMemo(() => {
      if (enabledNewProjectEditor) {
        return;
      }
      if (workspace && agentServerHealth) {
        return;
      }
      if (startWorkspaceError === "ERROR" || resetWorkspaceError === "ERROR") {
        return {
          kind: "FAILED_LAUNCH",
        };
      }
      if (isResetWorkspaceLoading) {
        return {
          kind: "WAITING_FOR_RESET",
        };
      }
      return {
        kind: "WAITING_FOR_LAUNCH",
      };
    }, [enabledNewProjectEditor, workspace, agentServerHealth, startWorkspaceError, resetWorkspaceError, isResetWorkspaceLoading]),
    onChangeCreatingActionNode: targetNode => {
      setFocusNode(targetNode.id);
    },
    onCreateFile: (fields, targetNode) => {
      treeAction.addFile(targetNode.id, fields.value);
      // TODO @himenon firepad-workerがagent serverに組み込まれたとき、agent-serverにファイルツリーの同期処理を寄せるため
      // 以下のファイルツリーの同期処理を消す。
      const newFilepath = treeAction.generateFilepath(targetNode.id, fields.value);
      if (newFilepath) {
        historiesPathsModel.update(newFilepath, {
          op: "CREATED",
        });
      }
    },
    onCreateDirectory: (fields, targetNode) => {
      treeAction.addDir(targetNode.id, fields.value);
    },
    onDeleteNode: targetNode => {
      treeAction.remove(targetNode.id);
      if (focusedNode === targetNode.id) {
        setFocusNode(".");
      }
      // TODO @himenon firepad-workerがagent serverに組み込まれたとき、agent-serverにファイルツリーの同期処理を寄せるため
      // 以下のファイルツリーの同期処理を消す。
      historiesPathsModel.update(targetNode.id, {
        op: "DELETED",
      });
    },
    currentDirectoryFiles: currentDirectoryFiles,
    fileNavigation: {
      focused: focusedNode,
      status: agentServerHealth ? "CONNECTED_SERVER" : "CONNECTING",
      fileTreeStatus: treeState.ready ? "CONNECTED_SERVER" : "CONNECTING",
      connectingPanel: {
        reconnectButton: agentServerHealth
          ? {
              onClick: controller => {
                controller.setLoading(true);
                treeAction.reconnect();
                window.setTimeout(() => {
                  controller.setLoading(false);
                }, 3000);
              },
            }
          : undefined,
      },
      resetButton: {
        disabled: args.mode === "DEVELOPMENT" && (completed || !agentServerHealth),
      },
      renderTree: renderTreeProps,
    },
    enableUiFrame: question?.variant === "Frontend",
    uiFrame:
      question?.variant === "Frontend"
        ? {
            url: endpoints.httpBase,
            loading: !applicationServerHealth,
            openWindowButton: {
              onClick: () => {
                window.open(endpoints.httpBase, "_blank");
              },
            },
          }
        : undefined,
    toolbar: {
      fileTree: treeState.fileTree,
      selectedFile: treeState.selectedFile,
      openedFiles: treeState.filesOpened,
      onSelectFile: treeAction.selectFile,
      onCloseFile: treeAction.closeFile,
    },
    workspace: workspaceProps,
    terminal: terminalProps,
    frontendTestCaseDialog: frontendTestCaseDialogProps,
    backendTestCaseDialog: backendTestCaseDialog,
    dataScienceTestCaseDialog: dataScienceTestCaseDialogProps,
    defaultTestCaseDialog: defaultTestCaseDialog,
    submitConfirmDialog: {
      onAccept: () => {
        client
          .EnqueueProjectSubmissionForProjectCodingEditor({
            input: {
              projectId: args.entityId,
              questionId: args.questionId,
              questionVersion: args.questionVersion,
            },
          })
          .then(() => {
            ProjectCodingEditorV3.setSubmittedEntity(entity);
            Snackbar.notify({
              severity: "success",
              message: t("お疲れ様でした！未提出の問題がある場合は次に進んでください。"),
            });
          })
          .catch(() => {
            Snackbar.notify({
              severity: "error",
              message: t("問題の提出に失敗しました。再度お試しください。"),
            });
          });
      },
    },
    resetConfirmDialog: {
      resetOption: resetOption,
      onSelectResetOption: (optionType: "withoutSourceCode" | "withSourceCode") => {
        setResetOption(optionType);
      },
      onAccept: () => {
        if (resetOption === null) return;

        if (args.mode === "DEVELOPMENT") {
          setWorkspace(null);
          setAgentServerHealth(false);
          setApplicationServerHealth(false);

          /**
           * TODO @Himenon ソースコードを引き継がない場合（withSourceCode === false）の場合、
           * 初期コードを取得して、HistoriesPathsModelを一度すべてDELETEして、再initializeする
           */
          resetDevelopmentWorkspace({
            input: {
              projectId: args.entityId,
              withSourceCode: resetOption === "withSourceCode",
            },
          })
            .then(res => {
              if (res.data) {
                setWorkspace(res.data.resetDevelopmentWorkspace);
              }
            })
            .catch(error => {
              Sentry.captureException(error);
            });
        }

        if (args.mode === "EVALUATION") {
          setWorkspace(null);
          setAgentServerHealth(false);
          setApplicationServerHealth(false);

          resetEvaluationWorkspace({
            input: {
              submissionId: args.submissionId,
              withSourceCode: resetOption === "withSourceCode",
            },
          })
            .then(res => {
              if (res.data) {
                setWorkspace(res.data.resetEvaluationWorkspace);
              }
            })
            .catch(error => {
              Sentry.captureException(error);
            });
        }

        setResetOption(null);
      },
      onClose: () => {
        setResetOption(null);
      },
    },
  };
};
