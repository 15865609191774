import * as z from "zod";

export const usePlaybackSettingsForm = () => {
  return z.object({
    enabledCopyAndPasteDetection: z.boolean(),
    enabledBehavioralControl: z.boolean(),
  });
};

export type PlaybackSettingsFormSchema = z.infer<ReturnType<typeof usePlaybackSettingsForm>>;
