import { useTitle } from "@hireroo/app-helper/react-use";
import { Resumes } from "@hireroo/app-store/page/e/resumes";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import ResumeDocumentUploadDialogInitialContainer, {
  ResumeDocumentUploadDialogInitialContainerProps,
} from "./widgets/ResumeDocumentUploadDialog/InitialContainer";
import ResumeListFetchContainer from "./widgets/ResumeList/FetchContainer";
import ResumeSearchAreaFetchContainer from "./widgets/ResumeSearchArea/FetchContainer";

export type GenerateResumeListPropsArgs = {};

export const useGenerateProps = (_args: GenerateResumeListPropsArgs): Pages.ResumeListProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const initializedCurrentSearchFilter = Resumes.useInitializedCurrentSearchFilter();

  const resumeDocumentUploadDialogInitialContainerProps: ResumeDocumentUploadDialogInitialContainerProps = {
    dialog: {
      open: open,
      onClose: () => {
        setOpen(false);
      },
    },
  };

  useTitle(t("書類選考一覧"));

  React.useEffect(() => {
    Resumes.initializeFilters(
      {
        textFilter: searchParams.get(Resumes.QueryKeys.NAME) ?? "",
        matchingRate: {
          min:
            PrivateHelper.convertStringToMatchingRate(searchParams.get(Resumes.QueryKeys.MIN_MATCHING_RATE)) ??
            Resumes.defaultFilters.matchingRate.min,
          max:
            PrivateHelper.convertStringToMatchingRate(searchParams.get(Resumes.QueryKeys.MAX_MATCHING_RATE)) ??
            Resumes.defaultFilters.matchingRate.max,
        },
        statuses: PrivateHelper.convertStringsToStatuses(searchParams.getAll(Resumes.QueryKeys.STATUS)),
        result: PrivateHelper.convertStringToResult(searchParams.get(Resumes.QueryKeys.RESULT)),
        skillTags: PrivateHelper.convertStringsToSkillTags(searchParams.getAll(Resumes.QueryKeys.SKILL_TAG)),
        tags: PrivateHelper.convertStringsToTagNames(searchParams.getAll(Resumes.QueryKeys.TAG)),
      },
      {
        page: PrivateHelper.convertStringsToPage(searchParams.get(Resumes.QueryKeys.PAGE)),
        size: PrivateHelper.convertStringToSize(searchParams.get(Resumes.QueryKeys.SIZE)),
        sortFieldValue: PrivateHelper.convertStringToSortFieldValue(searchParams.get(Resumes.QueryKeys.SORT)),
      },
    );
  }, [searchParams]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    skillMapButton: {
      onClick: () => {
        navigate("/e/settings/company/skillmap");
      },
    },
    uploadButton: {
      onClick: () => {
        setOpen(true);
      },
    },
    SearchArea: initializedCurrentSearchFilter ? <ResumeSearchAreaFetchContainer /> : null,
    List: initializedCurrentSearchFilter ? <ResumeListFetchContainer /> : null,
    Dialog: <ResumeDocumentUploadDialogInitialContainer key={`dialog-${open}`} {...resumeDocumentUploadDialogInitialContainerProps} />,
  };
};
