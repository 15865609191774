import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import DeleteDialog, { DeleteDialogProps } from "../../modules/DeleteDialog/DeleteDialog";
import RestrictableTab, { RestrictableTabProps } from "../../modules/RestrictableTab/RestrictableTab";
import TestEvaluationDialog, { TestEvaluationDialogProps } from "../../modules/TestEvaluationDialog/TestEvaluationDialog";
import AnalyzedReport, { AnalyzedReportProps } from "./parts/AnalyzedReport/AnalyzedReport";
import DetailPageHeader, { DetailPageHeaderProps } from "./parts/DetailPageHeader/DetailPageHeader";
import ResumeGeneral, { ResumeGeneralProps } from "./parts/ResumeGeneral/ResumeGeneral";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette["Background/Paper"].p2,
  minWidth: 900,
}));

type Tab = RestrictableTabProps["items"][0]["label"];

export type ResumeDetailProps = {
  header: DetailPageHeaderProps;
  report: AnalyzedReportProps;
  general: ResumeGeneralProps;
  generalTab: Pick<Tab, "onClick">;
  analyzedReportTab: Pick<Tab, "onClick">;
  defaultTab?: "GENERAL" | "ANALYZED_REPORT";
  evaluation: TestEvaluationDialogProps;
  deleteDialog: DeleteDialogProps;
};

const ResumeDetail: React.FC<ResumeDetailProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const restrictableTab: RestrictableTabProps = {
    defaultTab: props.defaultTab,
    items: [
      {
        id: "GENERAL",
        Component: <ResumeGeneral {...props.general} />,
        label: {
          ...props.generalTab,
          name: t("一般情報"),
        },
      },
      {
        id: "ANALYZED_REPORT",
        Component: <AnalyzedReport {...props.report} />,
        label: {
          ...props.analyzedReportTab,
          name: t("解析レポート"),
        },
      },
    ],
    variant: "OUTLINED",
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    tabPanelBox: {
      pt: 0,
      sx: {
        height: "100%",
        bgcolor: theme.palette.background.default,
      },
    },
  };
  return (
    <StyledBox>
      <DetailPageHeader {...props.header} />
      <RestrictableTab {...restrictableTab} />
      <TestEvaluationDialog {...props.evaluation} />
      <DeleteDialog {...props.deleteDialog} />
    </StyledBox>
  );
};

ResumeDetail.displayName = "ResumeDetail";

export default ResumeDetail;
