import { useTranslation } from "@hireroo/i18n";
import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DocumentTableRow, { DocumentTableRowProps } from "../DocumentTableRow/DocumentTableRow";

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  boxShadow: "none",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.Other.Divider,
}));

export type DocumentSectionProps = {
  scrollTargetElementId: string;
  items: Omit<DocumentTableRowProps, "docNumber" | "hideBorderBottom">[];
  uploadButton: Pick<ButtonProps, "onClick">;
  AddDialog: React.ReactNode;
  PreviewDialog: React.ReactNode;
};

const DocumentSection: React.FC<DocumentSectionProps> = props => {
  const { t } = useTranslation();
  const uploadButtonProps: ButtonProps = {
    ...props.uploadButton,
    children: t("アップロード"),
    variant: "outlined",
    color: "outline-only",
    size: "small",
    startIcon: <UploadIcon />,
  };
  return (
    <Stack spacing={2} id={props.scrollTargetElementId}>
      <Typography fontWeight={700}>{t("書類情報")}</Typography>
      <Wrapper spacing={2}>
        <Table>
          <TableBody>
            {props.items.map((item, index) => (
              <DocumentTableRow key={item.id} {...item} docNumber={index + 1} hideBorderBottom={index === props.items.length - 1} />
            ))}
          </TableBody>
        </Table>
        <Box>
          <StyledButton {...uploadButtonProps} />
        </Box>
        {props.PreviewDialog}
        {props.AddDialog}
      </Wrapper>
    </Stack>
  );
};

DocumentSection.displayName = "DocumentSection";

export default DocumentSection;
