import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Role } from "@hireroo/app-store/essential/employee";
import { ScreeningTestListForDetail } from "@hireroo/app-store/widget/e/ScreeningTestListForDetail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import * as Sentry from "@sentry/react";
import * as React from "react";

type BulkListActivityBarProps = Widget.ScreeningTestListProps["searchResultBar"]["bulkListActivityBar"];

type TagOption = BulkListActivityBarProps["tagPopper"]["form"]["multiChoiceField"]["options"][number];
type DefaultValues = BulkListActivityBarProps["tagPopper"]["form"]["defaultValues"];

type EvaluationItem = Exclude<BulkListActivityBarProps["evaluation"], undefined>["dialog"]["items"][0];

export type GenerateBulkListActivityBarPropsArgs = {
  refresh: () => void;
};

export const useGenerateBulkListActivityBarProps = (args: GenerateBulkListActivityBarPropsArgs): BulkListActivityBarProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { refresh } = args;
  const client = getGraphqlClient();
  const selectedSpotIds = ScreeningTestListForDetail.useSelectedSpotIds();
  const selectedSpots = ScreeningTestListForDetail.useSelectedSpots();
  const tags = ScreeningTestListForDetail.useTags();
  const [addedTags, setAddedTags] = React.useState<string[]>([]);
  const currentUid = Auth.useCurrentUid();
  const matchingRole = Role.useMatchingRole();

  const defaultValues = React.useMemo((): DefaultValues => {
    const tagSet = new Set<string>();
    const newAddedTags = addedTags.map((tag): DefaultValues["tags"][0] => ({
      name: tag,
      status: "CHECKED",
    }));
    const indeterminateTags = selectedSpots.reduce<DefaultValues["tags"]>((all, screening) => {
      screening.tags.forEach(tag => {
        if (!tagSet.has(tag.name)) {
          all.push({
            name: tag.name,
            status: "INDETERMINATE",
          });
          tagSet.add(tag.name);
        }
      });
      return all;
    }, []);
    return {
      tags: newAddedTags.concat(indeterminateTags),
    };
  }, [addedTags, selectedSpots]);

  const defaultTags = React.useMemo((): string[] => {
    return defaultValues.tags.map(tag => tag.name);
  }, [defaultValues]);

  const tagOptions = React.useMemo((): TagOption[] => {
    const newAddedTags = addedTags.map(
      (tag): TagOption => ({
        value: tag,
        displayName: tag,
      }),
    );
    const existingTags = tags.map((tag): TagOption => {
      return {
        value: tag.name,
        displayName: tag.name,
        hasIndeterminate: defaultTags.includes(tag.name),
      };
    });
    return newAddedTags.concat(existingTags);
  }, [addedTags, tags, defaultTags]);

  const canMultiEvaluate = React.useMemo(() => selectedSpots.every(spot => spot.status === "FINALIZED"), [selectedSpots]);

  const titleTooltip = React.useMemo(() => {
    if (canMultiEvaluate) {
      if (!matchingRole.ADMIN_OR_MANAGER.matched) {
        return matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched;
      }
      return undefined;
    }
    return t("採点済みのステータスのみ評価が可能です。");
  }, [canMultiEvaluate, matchingRole.ADMIN_OR_MANAGER.matched, matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched, t]);

  return {
    selectedItemsCount: selectedSpotIds.length > 0 ? selectedSpotIds.length : undefined,
    evaluation: {
      dialog: {
        items: selectedSpots.map((spot): EvaluationItem => {
          return {
            id: spot.spotId,
            candidateName: spot.candidateName || spot.name,
            candidateEmail: spot.candidateEmail || undefined,
            reportButton: {
              href: generateCurrentOriginUrl("/e/screenings/:id/detail", { pathParams: { id: spot.spotId } }),
              text: t("候補者詳細"),
            },
          };
        }),
        heading: `${t("候補者情報")} (${t2("SelectingPerson", { nums: selectedSpots.length })})`,
        additionalCautionItems: [t("この結果は候補者には通知されません。")],
      },
      onSubmit: (fields, controller) => {
        controller.setLoading(true);
        client
          .EvaluateMultiSpotsForScreeningsTests({
            input: {
              spotIds: selectedSpotIds.slice(),
              employeeId: currentUid,
              isPassed: fields.result === "PASSED",
              evaluationComment: fields.comment,
            },
          })
          .then(res => {
            ScreeningTestListForDetail.updateSpots(res.evaluateMultiSpots);
            ScreeningTestListForDetail.clearSelectedSpotIds();
            ScreeningTestListForDetail.updateRefreshKey();
            Snackbar.notify({
              severity: "success",
              message: t("テストの評価を完了しました。"),
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(
              error,
              t("テストの評価に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
            );
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            controller.setLoading(false);
            controller.close();
          });
      },
      disabled: !canMultiEvaluate || !matchingRole.ADMIN_OR_MANAGER.matched,
      title: titleTooltip,
    },
    deleteButton: {
      disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
      title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
    },
    deletesDialog: {
      title: t("選択された候補者を一括削除"),
      description: t("一度削除すると元に戻すことはできません。（共有されたリンクからもアクセスができなくなりますのでご注意ください。）"),
      items: selectedSpots.map(spot => {
        return {
          id: spot.spotId,
          name: spot.name,
        };
      }),
      onSubmit: controller => {
        controller.setLoading(true);
        const targetSpotIds = selectedSpotIds.slice();
        client
          .DeleteMultiSpotsForScreeningsTests({
            input: {
              spotIds: targetSpotIds,
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("候補者を削除しました。"),
            });
            ScreeningTestListForDetail.clearSelectedSpotIds();
            ScreeningTestListForDetail.deleteSpots(targetSpotIds);
            refresh();
            controller.close();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("候補者の削除に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            controller.setLoading(false);
          });
      },
    },
    tagPopper: {
      form: {
        onSubmit: (fields, controller) => {
          const addTags = fields.tags.filter(tag => tag.status === "INDETERMINATE" || tag.status === "CHECKED").map(t => t.name);
          const deleteTags = fields.tags.filter(tag => tag.status === "UNCHECKED").map(t => t.name);
          controller.setLoading(true);
          client
            .UpdateTagsToSpotsForScreeningsTests({
              input: {
                spotIds: selectedSpotIds.slice(),
                addTagNames: addTags,
                deleteTagNames: deleteTags,
              },
            })
            .then(res => {
              Snackbar.notify({
                severity: "success",
                message: t("コーディングテストにタグを付与しました。"),
              });
              ScreeningTestListForDetail.updateSpots(res.updateTagsToSpots);
              ScreeningTestListForDetail.tagRefresh();
              controller.close();
              ScreeningTestListForDetail.updateRefreshKey();
              ScreeningTestListForDetail.clearSelectedSpotIds();
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("コーディングテストのタグの付与に失敗しました。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              controller.setLoading(false);
            });
        },
        onTagAdded: tagName => {
          setAddedTags(prev => [tagName, ...prev]);
        },
        multiChoiceField: {
          options: tagOptions,
        },
        defaultValues: defaultValues,
      },
    },
  };
};
