import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { formatFileSize } from "@hireroo/app-helper/files";
import { ResumesIdDetail } from "@hireroo/app-store/page/e/resumes_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import PDFViewerDialogFetchContainer, { PDFViewerDialogFetchContainerProps } from "../PDFViewerDialog/FetchContainer";
import ResumeDocumentAddDialogInitialContainer, {
  ResumeDocumentAddDialogInitialContainerProps,
} from "../ResumeDocumentAddDialog/InitialContainer";
import { DOCUMENT_SECTION_ELEMENT_ID } from "./privateHelper";

type DocumentSection = Widget.ResumeDetailProps["report"]["documentSection"];
type Item = DocumentSection["items"][number];

const statusMap: Record<Graphql.ResumeItemStatus, Item["status"]> = {
  ANALYZED: "ANALYZED",
  ENQUEUED: "ANALYZING",
  FAILED: "ERROR",
};

export const useGenerateDocumentSectionProps = (): DocumentSection => {
  const { t } = useTranslation();
  const client = getGraphqlClient();

  const resume = ResumesIdDetail.useResume();
  const [open, setOpen] = React.useState(false);
  const selectedResumeItem = ResumesIdDetail.useSelectedResumeItem();

  const items = React.useMemo((): Item[] => {
    return resume.resumeItems.map((item): Item => {
      return {
        id: item.resumeItemId,
        fileName: item.pdfItem?.fileName ?? t("不明"),
        previewButton: {
          onClick: () => {
            ResumesIdDetail.setSelectedResumeItem(item);
          },
        },
        size: typeof item.pdfItem?.fileSizeBytes === "number" ? `${formatFileSize(item.pdfItem.fileSizeBytes)}` : "-",
        status: statusMap[item.status],
        deleteButton: {
          disabled: resume.resumeItems.length === 1,
          title: t("書類は最低１つは必要です。"),
        },
        deleteDialog: {
          onSubmit: controller => {
            controller.setLoading(true);
            client
              .DeleteResumeItemForResumesIdDetail({
                input: {
                  resumeItemId: item.resumeItemId,
                },
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("書類を削除しました。"),
                });
                controller.onClose();
                ResumesIdDetail.refresh();
              })
              .catch(error => {
                Sentry.captureException(error);
                const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類の削除に失敗しました。"));
                Snackbar.notify({
                  severity: "error",
                  message: errorNotification.message,
                });
              })
              .finally(() => {
                controller.setLoading(false);
              });
          },
        },
      };
    });
  }, [client, resume.resumeItems, t]);
  const resumeDocumentAddDialogInitialContainerProps: ResumeDocumentAddDialogInitialContainerProps = {
    open: open,
    onChangeOpen: setOpen,
  };
  const pdfViewerDialog: PDFViewerDialogFetchContainerProps | null = selectedResumeItem
    ? {
        onClose: () => {
          ResumesIdDetail.clearSelectedResumeItem();
        },
        resumeItem: {
          id: selectedResumeItem.resumeItemId,
          title: selectedResumeItem.pdfItem?.fileName ?? t("不明"),
        },
      }
    : null;
  return {
    scrollTargetElementId: DOCUMENT_SECTION_ELEMENT_ID,
    items: items,
    uploadButton: {
      onClick: () => {
        setOpen(true);
      },
    },
    PreviewDialog: pdfViewerDialog ? <PDFViewerDialogFetchContainer {...pdfViewerDialog} /> : null,
    AddDialog: <ResumeDocumentAddDialogInitialContainer {...resumeDocumentAddDialogInitialContainerProps} />,
  };
};
