import { Deletion, Insertion, Retain } from "@hireroo/app-helper/challenge/cheatDetection";
import type { IStandaloneCodeEditor } from "@hireroo/app-helper/monaco";
import { BehavioralEvent, ClipboardEvent, CodeEditorInputEvent, PlaybackTickEvent } from "@hireroo/app-helper/playback";
import { DeepReadonly } from "@hireroo/app-helper/types";
import { CursorWidgetV2 } from "@hireroo/code-editor/extensions";
import { ITextModel, Monaco } from "@hireroo/code-editor/react/CodeEditor";
import * as React from "react";

const DEVIATION = 100;
const CLASS_NAME = "pastedTextDecorator";

type Trigger = "PASTING" | "TYPING";

type Meta = {
  user: string;
  timestamp: number;
  trigger: Trigger;
};

export type PasteRange = {
  position: number;
  selectionEnd: number;
};

export type PasteRangeMap = Record<string, PasteRange>;

type Track = {
  meta: Meta;
  histories: Record<string, PasteRange>;
};

export type PasteEventMap = Record<string, Track>;

/**
 * function purely for debugging purposes
 */
function strToUtf16(s: string): number[] {
  const result: number[] = [];

  for (let i = 0; i < s.length; i++) {
    const codeUnit = s.charCodeAt(i);
    result.push(codeUnit);
  }

  return result;
}

function arraysHaveSameContent(arr1: number[], arr2: number[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

function flattenHistory(history: Record<string, CodeEditorInputEvent>): (Retain | Deletion | Insertion)[] {
  const seq: (Retain | Deletion | Insertion)[] = [];

  for (const key of Object.keys(history).sort()) {
    let index = 0;
    const codeEditorInputEvent = history[key];
    for (const op of codeEditorInputEvent.textOperations) {
      if (typeof op === "number") {
        if (op >= 0) {
          seq.push(new Retain(key, codeEditorInputEvent.userId, index, op, codeEditorInputEvent.ts));
          index += op;
        } else {
          seq.push(new Deletion(key, codeEditorInputEvent.userId, index, op, codeEditorInputEvent.ts));
        }
      } else if (typeof op === "string") {
        const utf16Op = strToUtf16(op);
        seq.push(new Insertion(key, codeEditorInputEvent.userId, index, utf16Op, codeEditorInputEvent.ts));
        index += utf16Op.length;
      }
    }
  }

  return seq;
}

function flattenEvent(events: (ClipboardEvent | undefined)[]): (Retain | Deletion | Insertion)[] {
  const seq: (Retain | Deletion | Insertion)[] = [];

  for (const event of events) {
    if (!event) {
      continue;
    }
    if (event.kind !== "EDITOR_PASTE") {
      continue;
    }

    const key = event.key;

    let index = 0;
    for (const op of event.value) {
      if (typeof op === "number") {
        if (op >= 0) {
          seq.push(new Retain(key, event.userId, index, op, event.ts));
          index += op;
        } else {
          seq.push(new Deletion(key, event.userId, index, op, event.ts));
        }
      } else if (typeof op === "string") {
        const utf16Op = strToUtf16(op);
        seq.push(new Insertion(key, event.userId, index, utf16Op, event.ts));
        index += utf16Op.length;
      }
    }
  }

  return seq;
}

export const createPasteEventMap = (
  eventRecords: (ClipboardEvent | undefined)[],
  revisionRecords: Record<string, CodeEditorInputEvent>,
): { pasteEventMap: PasteEventMap; pasteNum: number } => {
  const pasteEventMap: PasteEventMap = {};
  const eventSeq = flattenEvent(eventRecords);
  const historySeq = flattenHistory(revisionRecords);

  let trackId = 0;
  let pasteNum = 0;

  /**
   * For each paste event, do the followings:
   * 1. Find out the history revision pointer
   * 2. Track the pasted offset, given following timestamps
   */
  for (const eventOp of eventSeq) {
    if (!(eventOp instanceof Insertion)) {
      continue;
    }

    for (const [i, historyOp] of historySeq.entries()) {
      /**
       * If pasted text matches to the part of composed text within certain range, then we know that is the pasted text
       * NOTE: this algorithm also checks the position, so it's unlikely the case where it falsely detect other part of text, yet there is a possiblity
       */
      if (
        historyOp instanceof Insertion &&
        Math.abs(eventOp.timestamp - historyOp.timestamp) <= DEVIATION &&
        eventOp.index === historyOp.index &&
        /** in JS, arrays with same numbers in the same sequence is not considered equal, therefore we need to iterate through the contents */
        arraysHaveSameContent(eventOp.text, historyOp.text)
      ) {
        pasteNum++;
        const data: Track = {
          meta: {
            user: historyOp.author,
            timestamp: historyOp.timestamp,
            trigger: "PASTING",
          },
          histories: {
            [historyOp.revision]: {
              position: eventOp.index,
              selectionEnd: eventOp.index + eventOp.text.length,
            },
          },
        };

        type PasteQueue = { ptr: number; revision: string; track: Track };
        const queue: PasteQueue[] = [];
        queue.push({ ptr: i + 1, revision: historyOp.revision, track: data });

        /**
         * We will track how pasted text transforms as timestamp moves on
         */
        while (queue.length > 0) {
          const trackObj = queue.shift();
          if (!trackObj) break;

          const { revision, track } = trackObj;
          let { ptr } = trackObj;
          let position = track.histories[revision].position;
          let selectionEnd = track.histories[revision].selectionEnd;

          while (ptr < historySeq.length) {
            const operation = historySeq[ptr];
            let newPosition = position;
            let newSelectionEnd = selectionEnd;

            if (operation instanceof Deletion) {
              /**
               * There are following cases that we have to cover:
               *    1. Deleted region doesn't overlap
               *        a. It's the prceeding text that is deleted => decrement new_position and new_selection_end.
               *        b. It's the following text that is deleted => do nothing.
               *    2. Deleted region does overlap
               *        a. Both start/end position is within the track => decrement new_selection_end
               *        b. Both start/end position is outside of the track => set both new_position and new_selection_end to -1
               *        c. Start position is left side of the track => set new_position to start of deleted range, and decrement new_selection_end by (end of deleted region - start of the track)
               *        d. End position is right side of the track => set new_selection_end to start of deleted region
               */
              const delPosition = operation.index;
              const delSelectionEnd = operation.index - operation.val; /** op is negative number, hence subtract it */

              if (delSelectionEnd < newPosition) {
                /** 1.a */
                newPosition += operation.val;
                newSelectionEnd += operation.val;
              } else if (newSelectionEnd < delPosition) {
                /** 1.b */
                /** Do nothing, but just make sure it's the case, otherwise following condition won't guarantee it's the case */
              } else if (newPosition <= delPosition && delSelectionEnd <= newSelectionEnd) {
                /** 2.a */
                newSelectionEnd -= delSelectionEnd - delPosition;
              } else if (delPosition <= newPosition && newSelectionEnd <= delSelectionEnd) {
                /** 2.b */
                newPosition = -1;
                newSelectionEnd = -1;
              } else if (delPosition < newPosition) {
                /** 2.c */
                newPosition = delPosition;
                newSelectionEnd -= delSelectionEnd - newPosition;
              } else if (newSelectionEnd < delSelectionEnd) {
                /** 2.d */
                newSelectionEnd = delPosition;
              }
            } else if (operation instanceof Insertion) {
              /**
               * If this condition is met, it means some text has been typed in in the middle of pasted text
               * We want to separate the pasted text into two segments in such case
               */
              if (newPosition < operation.index && operation.index < newSelectionEnd) {
                const newTrack: Track = {
                  meta: {
                    user: operation.author,
                    timestamp: operation.timestamp,
                    trigger: "TYPING",
                  },
                  histories: {
                    [operation.revision]: {
                      position: operation.index + operation.text.length,
                      selectionEnd: newSelectionEnd + operation.text.length,
                    },
                  },
                };
                queue.push({ ptr: ptr + 1, revision: operation.revision, track: newTrack });
                newSelectionEnd = operation.index;

                /**
                 * If this condition is met, some text was typed in prior to the pasted text.
                 * We want to update position and selectionEnd accordingly
                 */
              } else if (operation.index <= newPosition) {
                newPosition += operation.text.length;
                newSelectionEnd += operation.text.length;
              }
            }

            if (newPosition !== position || newSelectionEnd !== selectionEnd) {
              track.histories[operation.revision] = {
                position: newPosition !== newSelectionEnd ? newPosition : -1,
                selectionEnd: newPosition !== newSelectionEnd ? newSelectionEnd : -1,
              };

              position = newPosition !== newSelectionEnd ? newPosition : -1;
              selectionEnd = newPosition !== newSelectionEnd ? newSelectionEnd : -1;
            }

            if (position === -1 && selectionEnd === -1) {
              break;
            }

            ptr++;
          }

          pasteEventMap[generatePasteTrackKey(trackId)] = track;
          trackId++;
        }
      }
    }
  }
  return { pasteEventMap, pasteNum };
};

const generatePasteTrackKey = (trackId: number) => `P${trackId}`;

type HistoryIndex = number;

export type HistoryPasteMap = Record<HistoryIndex, PasteRangeMap>;

export const decoratePlaybackText = (
  monaco: Monaco,
  model: ITextModel,
  oldDecorations: string[],
  rangesToDecorateAtNewIndex: PasteRangeMap,
): string[] => {
  // reset decoration
  model.deltaDecorations(oldDecorations, [
    {
      range: new monaco.Range(0, 0, 10000, 10000),
      options: {},
    },
  ]);

  let newDecorations: string[] = [];
  Object.values(rangesToDecorateAtNewIndex).forEach(target => {
    const start = model.getPositionAt(target.position);
    const end = model.getPositionAt(target.selectionEnd);
    const range = new monaco.Range(start.lineNumber, start.column, end.lineNumber, end.column);

    // apply decoration
    const d = model.deltaDecorations(
      [],
      [
        {
          range: range,
          options: {
            className: CLASS_NAME,
            stickiness: monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
          },
        },
      ],
    );
    newDecorations = newDecorations.concat(d).filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
  });
  return newDecorations;
};

export const useCursorWidgetManager = () => {
  const cursorWidgetControllers = React.useRef<Map<IStandaloneCodeEditor, CursorWidgetV2.CursorWidgetController>>(new Map());
  const dispose = React.useCallback(() => {
    for (const controller of cursorWidgetControllers.current.values()) {
      controller.dispose();
    }
    cursorWidgetControllers.current.clear();
  }, []);

  React.useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  const updateCursor = React.useCallback((params: CursorWidgetV2.Types.UpdateCursorOptions) => {
    for (const controller of cursorWidgetControllers.current.values()) {
      controller.updateCursor(params);
    }
  }, []);

  const initCursorWidgetController = React.useCallback((editor: IStandaloneCodeEditor) => {
    const controller = new CursorWidgetV2.CursorWidgetController(editor);
    cursorWidgetControllers.current.set(editor, controller);
    editor.onDidDispose(() => {
      controller.dispose();
      cursorWidgetControllers.current.delete(editor);
    });
  }, []);

  return React.useMemo(() => {
    return {
      updateCursor,
      initCursorWidgetController,
    };
  }, [updateCursor, initCursorWidgetController]);
};

export const isBehavioralEvent = (tickEvent: DeepReadonly<PlaybackTickEvent>): tickEvent is BehavioralEvent => {
  return (
    tickEvent.kind === "CHATGPT_REQUEST" ||
    tickEvent.kind === "CHATGPT_RESPOND" ||
    tickEvent.kind === "CHATGPT_RESET" ||
    tickEvent.kind === "WEB_SITE_SEARCH" ||
    tickEvent.kind === "EXTERNAL_WEB_SITE_ACCESS"
  );
};
