import { useTranslation } from "@hireroo/i18n";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CareerItem, { CareerItemProps } from "../CareerItem/CareerItem";
import ExtractedSection, { ExtractedSectionProps } from "../ExtractedSection/ExtractedSection";
import LinkItem, { LinkItemProps } from "../LinkItem/LinkItem";
import Subsection from "../Subsection/Subsection";

export type AnalyzedSectionProps = {
  extractedSection: ExtractedSectionProps;
  careerItems: Omit<CareerItemProps, "icon">[];
  educationItems: Omit<CareerItemProps, "icon">[];
  externalLinks: LinkItemProps[];
};

const AnalyzedSection: React.FC<AnalyzedSectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2} width="100%">
      <ExtractedSection {...props.extractedSection} />
      {props.careerItems.length > 0 && (
        <Subsection title={t("経歴")}>
          <Stack divider={<Divider sx={{ my: 0.5 }} />}>
            {props.careerItems.map((careerItem, index) => (
              <CareerItem key={`${careerItem.title}-${index}`} {...careerItem} icon="COMPANY" />
            ))}
          </Stack>
        </Subsection>
      )}
      {props.educationItems.length > 0 && (
        <Subsection title={t("学歴")}>
          <Stack divider={<Divider sx={{ my: 0.5 }} />}>
            {props.educationItems.map((careerItem, index) => (
              <CareerItem key={`${careerItem.title}-${index}`} {...careerItem} icon="SCHOOL" />
            ))}
          </Stack>
        </Subsection>
      )}
      <Subsection title={t("公開リンク")}>
        <Stack divider={<Divider sx={{ my: 0.5 }} />}>
          {props.externalLinks.length === 0 && (
            <Typography fontSize={12} color="textSecondary">
              {t("なし")}
            </Typography>
          )}
          {props.externalLinks.map((linkItem, index) => (
            <LinkItem key={`${linkItem.href}-${index}`} {...linkItem} />
          ))}
        </Stack>
      </Subsection>
    </Stack>
  );
};

AnalyzedSection.displayName = "AnalyzedSection";

export default AnalyzedSection;
