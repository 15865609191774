import { ResumeSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type ResumeSearchAreaProps = {
  disabled: boolean;
  tagsField: Pick<PrivateHelper.TagFieldProps, "selectableTags">;
  skillTagsField: PrivateHelper.SkillTagsFiledArgs;
  defaultValues: ResumeSearchForm.ResumeSearchQuerySchema;
  onChange: SubmitHandler<ResumeSearchForm.ResumeSearchQuerySchema>;
};

const ResumeSearchArea: React.FC<ResumeSearchAreaProps> = props => {
  const { onChange } = props;
  const validateSchema = ResumeSearchForm.useResumeSearchQuery();
  const methods = useForm<ResumeSearchForm.ResumeSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const textFilterField = PrivateHelper.useTextFilterField();
  const skillTagsField = PrivateHelper.useSkillTagsField(props.skillTagsField);
  const matchingRateField = PrivateHelper.useMatchingRateField();
  const statusesField = PrivateHelper.useStatusesField();
  const resultsField = PrivateHelper.useResultsField();
  const tagsField = PrivateHelper.useTagsField(props.tagsField);

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      const fields = methods.getValues();
      onChange(fields);
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);
  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: [skillTagsField, matchingRateField, statusesField, resultsField, tagsField],
    onReset: () => {
      const resetValues: ResumeSearchForm.ResumeSearchQuerySchema = {
        skillTags: [],
        tags: [],
        matchingRate: {
          min: 0,
          max: 100,
        },
        statuses: [],
        result: null,
        textFilter: "",
      };
      methods.reset(resetValues);
    },
  };

  return (
    <FormProvider {...methods}>
      <SearchForm {...searchFormProps} />
    </FormProvider>
  );
};

ResumeSearchArea.displayName = "ResumeSearchArea";

export default ResumeSearchArea;
