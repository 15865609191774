import { useEnabledQuestionSkillTagSearch } from "@hireroo/app-helper/feature";
import { Payment } from "@hireroo/app-store/essential/employee";
import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import type { Widget } from "@hireroo/presentation";
import type { QuestionSearchForm } from "@hireroo/validator";
import * as React from "react";

import { useGenerateSkillTagsFieldProps } from "./useGenerateSkillTagsFieldProps";

const questionVariants: QuestionSearchForm.QuestionVariant[] = [
  "CHALLENGE",
  "CHALLENGE_ALGORITHM",
  "CHALLENGE_DATABASE",
  "CHALLENGE_CLASS",
  "FREEPAD",
  "PROJECT",
  "PROJECT_BACKEND",
  "PROJECT_FRONTEND",
  "PROJECT_DATA_SCIENCE",
  "PROJECT_OTHER",
  "QUIZ",
  "SYSTEM_DESIGN",
];

export type GenerateQuestionSearchForResourceEditorPropsArgs = {
  name: string;
  target: "exam" | "test" | "remote";
};

export const useGenerateProps = (args: GenerateQuestionSearchForResourceEditorPropsArgs): Widget.QuestionSearchForResourceEditorProps => {
  const currentSearchFilter = QuestionSelectFieldForResourceEditor.useCurrentQuestionSearchFilter();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const { target } = args;
  const enabledQuestionSkillTagSearch = useEnabledQuestionSkillTagSearch();
  const skillTagsFieldProps = useGenerateSkillTagsFieldProps();

  const availableVariants = React.useMemo(() => {
    if (target === "remote") {
      return questionVariants.filter(questionVariant => {
        switch (questionVariant) {
          case "CHALLENGE":
          case "CHALLENGE_ALGORITHM":
          case "CHALLENGE_DATABASE":
          case "CHALLENGE_CLASS":
            return true;
          case "FREEPAD":
          case "SYSTEM_DESIGN":
            return isAvailableFeature(`interview.create`);
          case "QUIZ":
          case "PROJECT":
          case "PROJECT_BACKEND":
          case "PROJECT_DATA_SCIENCE":
          case "PROJECT_FRONTEND":
          case "PROJECT_OTHER":
            return false;
          default:
            throw new Error(`questionVariant is unknown. ${questionVariant satisfies never}`);
        }
      });
    }

    return questionVariants.filter(questionVariant => {
      switch (questionVariant) {
        case "QUIZ":
        case "CHALLENGE":
        case "CHALLENGE_ALGORITHM":
        case "CHALLENGE_DATABASE":
        case "CHALLENGE_CLASS":
          return true;
        case "FREEPAD":
          return false;
        case "PROJECT":
        case "PROJECT_BACKEND":
        case "PROJECT_DATA_SCIENCE":
        case "PROJECT_FRONTEND":
        case "PROJECT_OTHER":
          return isAvailableFeature(`${target}.project.create`);
        case "SYSTEM_DESIGN":
          return isAvailableFeature(`${target}.systemDesign.create`);
        default:
          throw new Error(`questionVariant is unknown. ${questionVariant satisfies never}`);
      }
    });
  }, [target, isAvailableFeature]);
  return {
    disabledSearchForm: false,
    enableSkillTagFilter: enabledQuestionSkillTagSearch,
    skillTagsField: skillTagsFieldProps,
    onChange: filter => {
      QuestionSelectFieldForResourceEditor.updateCurrentQuestionSearchFilter(filter);
    },
    availableVariants: availableVariants,
    defaultValues: {
      questionVariants: currentSearchFilter.questionVariants.slice(),
      statuses: currentSearchFilter.statuses.slice(),
      difficulties: currentSearchFilter.difficulties.slice(),
      textFilter: currentSearchFilter.textFilter,
      skillTags: currentSearchFilter.skillTags.map(skillTag => ({ value: skillTag.value })),
    },
  };
};
