import { App, Company } from "@hireroo/app-store/essential/employee";
import { Resumes } from "@hireroo/app-store/page/e/resumes";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ResumeSearchAreaContainer, { ResumeSearchAreaContainerProps } from "./Container";

const TAG_SIZE = 100;

export type ResumeSearchAreaFetchContainerProps = {};

const ResumeSearchAreaFetchContainer: React.FC<ResumeSearchAreaFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = Resumes.useInitialized();
  const [result, refresh] = Graphql.useGetResumeTagsForResumesQuery({
    variables: {
      tagsInput: {
        companyId: companyId,
        withCount: true,
        size: TAG_SIZE,
        // TODO: implement once the backend is ready
        cursor: undefined,
      },
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      Resumes.setTags(result.data.resumeTagsByCompanyId);
    }
  }, [result.data]);

  React.useEffect(() => {
    Resumes.setTagRefresh(refresh);
  }, [refresh]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ResumeSearchAreaContainerProps = {};

  return <ResumeSearchAreaContainer {...containerProps} />;
};

ResumeSearchAreaFetchContainer.displayName = "ResumeSearchAreaFetchContainer";

export default withErrorBoundary(ResumeSearchAreaFetchContainer, {});
