import { QuestionTypeMap } from "@hireroo/app-definition/question";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useEnabledLeakScore, useEnabledQuestionSkillTagSearch } from "@hireroo/app-helper/feature";
import { QuestionStats, useQuestionVariantLabelMap } from "@hireroo/app-helper/question";
import * as SkillTagHelper from "@hireroo/app-helper/skill-tag";
import { Company } from "@hireroo/app-store/essential/employee";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useCurrentLanguage, useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

export type GenerateQuestionTablePropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionTablePropsArgs): Widget.QuestionTableProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const client = getGraphqlClient();
  const lang = useCurrentLanguage();
  const langCode = useLanguageCode();

  const navigate = useTransitionNavigate();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const res = QuestionsStore.useResponse();
  const pager = QuestionsStore.usePager();
  const questionVariantLabelMap = useQuestionVariantLabelMap();

  const enabledLeakScore = useEnabledLeakScore();
  const enabledQuestionSkillTagSearch = useEnabledQuestionSkillTagSearch();
  const skillTagSources = QuestionsStore.useSkillTagSources();
  const skillTagUniqueNameMap = React.useMemo(() => {
    return SkillTagHelper.generateUniqueNameMapFromPath(
      skillTagSources.map(skillTag => ({
        id: skillTag.skillTagNodeId,
        name: skillTag.name,
        path: skillTag.path,
      })),
    );
  }, [skillTagSources]);

  const rows = React.useMemo((): Widget.QuestionTableProps["rows"] => {
    return res.questionObjects.map((questionObject): Widget.QuestionTableProps["rows"][0] => {
      const question = questionObject.question;
      const version = QuestionStats.getVersion(question);
      const questionTitle = `${QuestionStats.getQuestionObjectId(question)}. ${resolveLanguage(
        question,
        lang,
        "title",
      )} (${QuestionStats.getVersion(question)})`;
      const accuracyRate = QuestionStats.getAccuracyRate(question);
      const numUses = QuestionStats.getNumUses(question);
      const averageElapsedTimeSeconds = QuestionStats.getAverageElapsedTime(question);
      const showAccuracyRate = question.__typename !== "FreepadQuestion" && numUses > 0;
      const showElapsedTime = numUses > 0;
      const leakScoreChip: Widget.QuestionTableProps["rows"][0]["leakScoreChip"] = (() => {
        if (!enabledLeakScore) {
          return undefined;
        }
        if (!question.isSupportedLeakScore) {
          return { level: "NONE", reason: "NOT_SUPPORTED" };
        }
        if (!question.leakScore) {
          return { level: "NONE", reason: "NO_DATA" };
        }
        return { level: question.leakScore.level, score: formatScore(question.leakScore.score) };
      })();
      /**
       * The list of issues does not include Archived issues, so the status only checks PUBLISHED.
       */
      const isPublishedQuestion = ((): boolean => {
        switch (question.__typename) {
          case "AlgorithmQuestion":
            return question.algorithmQuestionStatus === "PUBLISHED";
          case "FreepadQuestion":
            return question.status === "PUBLISHED";
          case "MultiChoicePackage":
            return question.status === "PUBLISHED";
          case "ProjectQuestion":
            return question.projectQuestionStatus === "PUBLISHED";
          case "SystemDesignQuestion":
            return question.systemDesignQuestionStatus === "PUBLISHED";
          default:
            throw new Error(`Question is invalid typename: ${question satisfies never}`);
        }
      })();
      const deleteButtonProps = ((): Widget.QuestionTableProps["rows"][0]["options"]["delete"] => {
        if (isPublishedQuestion) {
          return;
        }
        /** Created By Another Company */
        if (activeCompanyId !== question.companyId) {
          return {
            value: "delete",
            disabled: true,
            disabledText: t("他社企業が作成した問題は削除できません。"),
            displayName: t("削除"),
          };
        } else if (question.isOfficial && !question.isPrivate) {
          return {
            value: "delete",
            disabled: true,
            disabledText: t("公開されているHireRoo公式の問題は削除することができません"),
            displayName: t("削除"),
          };
        }
        return {
          value: "delete",
          disabled: false,
          displayName: t("削除"),
        };
      })();
      const archiveButtonProps = ((): Widget.QuestionTableProps["rows"][0]["options"]["archive"] => {
        if (!isPublishedQuestion) {
          return;
        }
        /** Created By Another Company */
        if (activeCompanyId !== question.companyId) {
          return {
            value: "archive",
            disabled: true,
            disabledText: t("他社企業が作成した問題はアーカイブできません。"),
            displayName: t("アーカイブ"),
          };
        } else if (question.isOfficial && !question.isPrivate) {
          return {
            value: "archive",
            disabled: true,
            disabledText: t("公開されているHireRoo公式の問題はアーカイブすることができません"),
            displayName: t("アーカイブ"),
          };
        }
        return {
          value: "delete",
          disabled: false,
          displayName: t("アーカイブ"),
        };
      })();

      return {
        id: `${question.__typename}-${question.id}-${version}`,
        meta: {
          title: questionTitle,
          variant: questionVariantLabelMap[questionObject.questionVariant],
          isOfficial: question.isOfficial,
          enableLanguages:
            question.__typename === "AlgorithmQuestion" ? question.supportedLanguages.map(l => languageMapForDisplay[l]) : undefined,
          mark: question.createdAtSeconds && PrivateHelper.isRecentlyCreated(question.createdAtSeconds) ? "NEW" : "NONE",
          skillTags:
            enabledQuestionSkillTagSearch && question.__typename === "ProjectQuestion"
              ? question.skillTags.map(skillTag => skillTagUniqueNameMap.get(skillTag.skillTagNodeId) || skillTag.name)
              : undefined,
        },
        leakScoreChip: leakScoreChip,
        showLeakScore: enabledLeakScore,
        difficulty: question.difficulty,
        href: (() => {
          if (question.__typename === "MultiChoicePackage") {
            return generatePath("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question.packageId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            });
          } else if (question.__typename === "AlgorithmQuestion") {
            return generatePath("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question.questionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            });
          } else if (question.__typename === "FreepadQuestion") {
            return generatePath("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question.freepadQuestionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            });
          } else if (question.__typename === "ProjectQuestion") {
            return generatePath("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question.questionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            });
          } else {
            return generatePath("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question?.questionId?.toString() ?? "",
              },
            });
          }
        })(),
        onClick: () => {
          if (question.__typename === "MultiChoicePackage") {
            navigate("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question.packageId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            });
          } else if (question.__typename === "AlgorithmQuestion") {
            navigate("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question.questionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            });
          } else if (question.__typename === "FreepadQuestion") {
            navigate("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question.freepadQuestionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            });
          } else if (question.__typename === "ProjectQuestion") {
            navigate("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question.questionId.toString(),
              },
              queryParams: {
                version: question.version,
              },
            });
          } else {
            navigate("/e/questions/:entityType/:id", {
              pathParams: {
                entityType: QuestionTypeMap[question.__typename],
                id: question?.questionId?.toString() ?? "",
              },
            });
          }
        },
        options: {
          edit: {
            value: "edit",
            disabled: activeCompanyId !== question.companyId,
            disabledText: t("他社企業が作成した問題は編集できません。"),
            displayName: t("編集"),
            onClick: () => {
              if (question.__typename === "MultiChoicePackage") {
                navigate("/e/questions/:entityType/:id/update", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.packageId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "AlgorithmQuestion") {
                navigate("/e/questions/:entityType/:id/update", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.questionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else if (question.__typename === "FreepadQuestion") {
                navigate("/e/questions/:entityType/:id/update", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.freepadQuestionId.toString(),
                  },
                  queryParams: {
                    version: question.version,
                  },
                });
              } else {
                navigate("/e/questions/:entityType/:id/update", {
                  pathParams: {
                    entityType: QuestionTypeMap[question.__typename],
                    id: question.id,
                  },
                });
              }
            },
          },
          delete: deleteButtonProps,
          archive: archiveButtonProps,
        },
        deleteQuestionDialog: {
          items: [
            {
              id: questionObject.questionObjectId,
              name: [resolveLanguage(question, lang, "title"), `(${version})`].join(" "),
              description: `${t("問題形式")}: ${questionVariantLabelMap[questionObject.questionVariant]}`,
            },
          ],
          onDelete: controller => {
            controller.setLoading(true);
            switch (question.__typename) {
              case "AlgorithmQuestion": {
                return client
                  .DeleteAlgorithmQuestion({
                    input: {
                      questionId: question.questionId,
                      questionVersion: question.version,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("DeletedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToDeleteQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              case "MultiChoicePackage": {
                return client
                  .DeleteMultiChoicePackage({
                    input: {
                      packageId: question.packageId,
                      version: question.version,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("DeletedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToDeleteQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              case "ProjectQuestion": {
                return client
                  .DeleteProjectQuestion({
                    input: {
                      questionId: question.questionId,
                      questionVersion: question.version,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("DeletedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToDeleteQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              case "SystemDesignQuestion": {
                return client
                  .DeleteSystemDesignQuestion({
                    input: {
                      questionId: question.questionId,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("DeletedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToDeleteQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              case "FreepadQuestion": {
                return client
                  .DeleteFreepadQuestion({
                    input: {
                      questionId: question.freepadQuestionId,
                      questionVersion: question.version,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("DeletedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToDeleteQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              default:
                throw new Error(`Question is unknown: ${question satisfies never}`);
            }
          },
        },
        archiveQuestionDialog: {
          items: [
            {
              id: questionObject.questionObjectId,
              name: [resolveLanguage(question, lang, "title"), `(${version})`].join(" "),
              description: `${t("問題形式")}: ${questionVariantLabelMap[questionObject.questionVariant]}`,
            },
          ],
          onArchive: controller => {
            controller.setLoading(true);
            switch (question.__typename) {
              case "AlgorithmQuestion": {
                // TODO: @ksrnnb implement archive question on server side and use it.
                return client
                  .DeleteAlgorithmQuestion({
                    input: {
                      questionId: question.questionId,
                      questionVersion: question.version,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("ArchivedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToArchiveQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              case "MultiChoicePackage": {
                return client
                  .DeleteMultiChoicePackage({
                    input: {
                      packageId: question.packageId,
                      version: question.version,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("ArchivedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToArchiveQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              case "ProjectQuestion": {
                return client
                  .DeleteProjectQuestion({
                    input: {
                      questionId: question.questionId,
                      questionVersion: question.version,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("ArchivedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToArchiveQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              case "SystemDesignQuestion": {
                return client
                  .DeleteSystemDesignQuestion({
                    input: {
                      questionId: question.questionId,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("ArchivedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToArchiveQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              case "FreepadQuestion": {
                return client
                  .DeleteFreepadQuestion({
                    input: {
                      questionId: question.freepadQuestionId,
                      questionVersion: question.version,
                    },
                  })
                  .then(() => {
                    QuestionsStore.deleteQuestion(questionObject.id);
                    Snackbar.notify({
                      severity: "success",
                      message: t2("ArchivedQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t2("FailedToArchiveQuestion", {
                        title: resolveLanguage(question, lang, "title"),
                      }),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                  })
                  .finally(() => {
                    controller.setLoading(false);
                  });
              }
              default:
                throw new Error(`Question is unknown: ${question satisfies never}`);
            }
          },
        },
        correctnessRate: QuestionStats.getQuestionStatus(question) === "PUBLISHED" && showAccuracyRate ? formatScore(accuracyRate ?? 0) : 0,
        expectedTimelimit:
          "timeLimitSeconds" in question && typeof question.timeLimitSeconds === "number"
            ? Time.formatSeconds(question.timeLimitSeconds, langCode)
            : "-",
        averageTimeToAnswer:
          QuestionStats.getQuestionStatus(question) === "PUBLISHED" && showElapsedTime
            ? `${Time.secondsToFormatMinutes(averageElapsedTimeSeconds, langCode)}`
            : "-",
        numUses: QuestionStats.getQuestionStatus(question) === "PUBLISHED" ? numUses : 0,
      };
    });
  }, [
    res.questionObjects,
    lang,
    questionVariantLabelMap,
    enabledQuestionSkillTagSearch,
    enabledLeakScore,
    activeCompanyId,
    t,
    langCode,
    skillTagUniqueNameMap,
    navigate,
    client,
    t2,
  ]);

  const sortOptions: Widget.QuestionTableProps["sortField"]["options"] = React.useMemo(() => {
    const options: Widget.QuestionTableProps["sortField"]["options"] = [
      {
        displayName: t("作成日時が新しい順"),
        value: QuestionsStore.SortFields.CREATED_AT_DESCENDING,
      },
      {
        displayName: t("作成日時が古い順"),
        value: QuestionsStore.SortFields.CREATED_AT_ASCENDING,
      },
    ];

    if (enabledLeakScore) {
      options.push(
        ...[
          {
            displayName: t("リークスコアが高い順"),
            value: QuestionsStore.SortFields.LEAK_SCORE_DESCENDING,
          },
          {
            displayName: t("リークスコアが低い順"),
            value: QuestionsStore.SortFields.LEAK_SCORE_ASCENDING,
          },
        ],
      );
    }

    options.push(
      ...[
        {
          displayName: t("平均スコアが高い順"),
          value: QuestionsStore.SortFields.ACCURACY_RATE_DESCENDING,
        },
        {
          displayName: t("平均スコアが低い順"),
          value: QuestionsStore.SortFields.ACCURACY_RATE_ASCENDING,
        },
        {
          displayName: t("使用回数が多い順"),
          value: QuestionsStore.SortFields.NUM_USES_DESCENDING,
        },
        {
          displayName: t("使用回数が少ない順"),
          value: QuestionsStore.SortFields.NUM_USES_ASCENDING,
        },
      ],
    );

    return options;
  }, [t, enabledLeakScore]);

  const isValidOptionValue = (value: string): value is QuestionsStore.SortFieldValue => {
    return sortOptions.map(o => o.value).includes(value);
  };

  return {
    rows: rows,
    resultText: t2("SearchResultCount", { count: res.count }),
    sortField: {
      options: sortOptions,
      disabled: false,
      defaultValue: pager.sortFieldValue,
      onChange: value => {
        if (isValidOptionValue(value)) {
          QuestionsStore.updateSortField(value);
        }
      },
    },
    showLeakScore: enabledLeakScore,
    showDifficulty: true,
    pagination: {
      count: res.count,
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = Number(event.target.value);
        QuestionsStore.updatePageSize(newSize);
      },
      page: pager.page,
      onPageChange: (_, newPage) => {
        QuestionsStore.updatePage(newPage);
      },
    },
  };
};
