import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DragAndDropContent, { DragAndDropContentProps } from "../DragAndDropContent/DragAndDropContent";
import ResumeDocumentTableRow, { ResumeDocumentTableRowProps } from "../ResumeDocumentTableRow/ResumeDocumentTableRow";

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type ResumeDocumentTableProps = {
  dragAndDropContent: DragAndDropContentProps;
  rows: ResumeDocumentTableRowProps[];
};

const ResumeDocumentTable: React.FC<ResumeDocumentTableProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack spacing={3}>
      <DragAndDropContent key={`drag-drop-${props.rows.length}`} {...props.dragAndDropContent} />
      {props.rows.length > 0 && (
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: "1px solid", borderColor: theme.palette["Other"]["Divider"] }}>
              <StyledTableCell>
                <Typography fontSize={12} color="textSecondary">
                  {t("名前")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize={12} color="textSecondary">
                  {t("ファイル名")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize={12} color="textSecondary">
                  {t("サイズ")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map(row => (
              <ResumeDocumentTableRow key={row.name} {...row} />
            ))}
          </TableBody>
        </Table>
      )}
    </Stack>
  );
};

ResumeDocumentTable.displayName = "ResumeDocumentTable";

export default ResumeDocumentTable;
