import { useTranslation } from "@hireroo/i18n";
import { ResumeDetailDocumentForm } from "@hireroo/validator";
import * as React from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";

import LoadingContent from "../../ms-components/Resume/LoadingContent/LoadingContent";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import ResumeDetailUploadContent, { ResumeDetailUploadContentProps } from "./parts/ResumeDetailUploadContent/ResumeDetailUploadContent";

export {
  ResumeDocumentAddDialogContext,
  ResumeDocumentAddDialogProvider,
  type ResumeDocumentAddDialogProviderProps,
  useResumeDocumentAddDialogContext,
} from "./Context";

export type ResumeDocumentAddDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose">;
  loading: boolean;
  content: ResumeDetailUploadContentProps;
  onSubmit: SubmitHandler<ResumeDetailDocumentForm.ResumeDocumentFormSchema>;
};

const ResumeDocumentAddDialog: React.FC<ResumeDocumentAddDialogProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<ResumeDetailDocumentForm.ResumeDocumentFormSchema>();
  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: t("書類をアップロード"),
    yesButton: {
      children: t("書類をアップロード"),
      disabled: props.loading || methods.watch("resumeDocuments").length === 0,
      color: "secondary",
      onClick: () => {
        methods.handleSubmit(props.onSubmit)();
      },
    },
    noButton: {
      children: t("キャンセル"),
      disabled: props.loading,
      variant: "text",
      color: "outline-only",
      onClick: () => {
        props.dialog.onClose?.();
      },
    },
    optionalContent: {
      sx: {
        padding: theme => theme.spacing(2, 3),
      },
    },
  };

  return (
    <DialogWithCloseIcon {...dialog}>
      {props.loading ? <LoadingContent /> : <ResumeDetailUploadContent {...props.content} />}
    </DialogWithCloseIcon>
  );
};

ResumeDocumentAddDialog.displayName = "ResumeDocumentAddDialog";

export default ResumeDocumentAddDialog;
