export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const NAVIGATION_HEIGHT = 64;
const BUFFER_HEIGHT = 16;

/**
 *
 * @param targetElement
 * @param extraHeight default header height
 */
export const scrollToTargetElement = (targetElement: HTMLElement, extraHeight?: number) => {
  const extra = extraHeight ?? NAVIGATION_HEIGHT + BUFFER_HEIGHT;
  const scrollTop = targetElement.offsetTop - extra;
  window.scrollTo({
    behavior: "smooth",
    top: scrollTop,
  });
};

export const scrollToTargetElementById = (targetElementId: string) => {
  const targetElement = document.getElementById(targetElementId);
  if (!targetElement) {
    return;
  }
  scrollToTargetElement(targetElement);
};
