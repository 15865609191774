import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompanySettingsContainerProps = {};

const CompanySettingsContainer: React.FC<CompanySettingsContainerProps> = () => {
  const companySettingsProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompanySettings {...companySettingsProps} />
    </ErrorBoundary>
  );
};

CompanySettingsContainer.displayName = "CompanySettingsContainer";

export default withErrorBoundary(CompanySettingsContainer, {});
