import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ResumeEditorContainerProps = {};

const ResumeEditorContainer: React.FC<ResumeEditorContainerProps> = () => {
  const resumeEditorProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.ResumeEditor {...resumeEditorProps} />
    </ErrorBoundary>
  );
};

ResumeEditorContainer.displayName = "ResumeEditorContainer";

export default withErrorBoundary(ResumeEditorContainer, {});
