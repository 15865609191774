import { DndContext } from "@dnd-kit/core";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { ResumeDocumentForm } from "@hireroo/validator";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import DragAndDropInput, { DragAndDropInputProps } from "../../../../ms-components/Resume/DragAndDropInput/DragAndDropInput";

/**
 * @example trimExtension("test.pdf") => "test"
 * @param fileName
 */
const trimExtension = (fileName: string): string => {
  return fileName.replace(/\.[^/.]+$/, "");
};

export type DragAndDropContentProps = {
  maxFileSize: string;
  maxFileCount: number;
};

const DragAndDropContent: React.FC<DragAndDropContentProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const methods = useFormContext<ResumeDocumentForm.ResumeDocumentFormSchema>();
  const resumeDocuments = useFieldArray({ control: methods.control, name: "resumeDocuments" });
  const dragDropInput: DragAndDropInputProps = {
    onSelectedFiles: files => {
      files.forEach(file => {
        if (file.type === "application/pdf") {
          resumeDocuments.append({
            id: `${file.name}-${crypto.randomUUID()}`,
            name: trimExtension(file.name),
            fileName: file.name,
            size: file.size,
            rawFile: file,
          });
        } else {
          console.warn(`file ${file.name} was not not pdf`);
        }
      });
    },
  };
  return (
    <Stack spacing={1.5}>
      <DndContext>
        <DragAndDropInput {...dragDropInput} />
      </DndContext>
      <Stack>
        <Typography fontSize={12} color="textSecondary">
          {t("※")}
          {t2("MaxFileSize", { size: props.maxFileSize })}
        </Typography>
        <Typography fontSize={12} color="textSecondary">
          {t("※")}
          {t2("MaxUploadFileCount", { count: props.maxFileCount })}
        </Typography>
        <Typography fontSize={12} color="textSecondary">
          {t("※")}
          {t("ファイル形式はPDFのみ対応しています。")}
        </Typography>
      </Stack>
    </Stack>
  );
};

DragAndDropContent.displayName = "DragAndDropContent";

export default DragAndDropContent;
