import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateResumeEditorPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ResumeEditorContainerProps = GenerateResumeEditorPropsArgs;

const ResumeEditorContainer: React.FC<ResumeEditorContainerProps> = props => {
  const resumeEditorProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ResumeEditor {...resumeEditorProps} />
    </ErrorBoundary>
  );
};

ResumeEditorContainer.displayName = "ResumeEditorContainer";

export default withErrorBoundary(ResumeEditorContainer, {});
