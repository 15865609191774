import { useFailureReasonMap, useInvalidMulterUploadStatusMap } from "@hireroo/app-definition/resume";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { formatFileSize } from "@hireroo/app-helper/files";
import { createContentIncludedFormData } from "@hireroo/app-helper/form-data";
import { ResumesIdDetail } from "@hireroo/app-store/page/e/resumes_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { ContentApi, ErrorResponse, getContentApiClient } from "@hireroo/rest-api/client";
import { AddResumeFilesSchema, ResumeDetailDocumentForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import * as PrivateHelper from "./privateHelper";

type Row = Widget.ResumeDocumentAddDialogProps["content"]["rows"][number];

export type GenerateResumeDocumentAddDialogPropsArgs = {
  open: boolean;
  onChangeOpen: (open: boolean) => void;
};

export const useGenerateProps = (args: GenerateResumeDocumentAddDialogPropsArgs): Widget.ResumeDocumentAddDialogProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { open, onChangeOpen } = args;

  const [loading, setLoading] = React.useState(false);
  const contentApiClient = getContentApiClient();
  const resume = ResumesIdDetail.useResume();

  const methods = useFormContext<ResumeDetailDocumentForm.ResumeDocumentFormSchema>();
  const resumeDocuments = methods.watch("resumeDocuments");
  const failureReasonMap = useFailureReasonMap();
  const invalidMulterUploadStatusMap = useInvalidMulterUploadStatusMap();

  const rows = React.useMemo((): Row[] => {
    return resumeDocuments.map((resumeDoc): Row => {
      return {
        id: resumeDoc.id,
        fileName: resumeDoc.fileName,
        size: `${formatFileSize(resumeDoc.size)}`,
        deleteButton: {
          onClick: () => {
            methods.setValue(
              "resumeDocuments",
              resumeDocuments.filter(fields => fields.id !== resumeDoc.id),
            );
          },
        },
      };
    });
  }, [methods, resumeDocuments]);

  return {
    dialog: {
      open: open,
      onClose: () => {
        onChangeOpen(false);
      },
    },
    loading: loading,
    content: {
      rows: rows,
    },
    onSubmit: fields => {
      setLoading(true);
      const formData = createContentIncludedFormData({
        type: "MULTI",
        files: fields.resumeDocuments.map(resumeDoc => resumeDoc.rawFile),
      });
      PrivateHelper.assignAddResumeFilesRequestFormData(formData, {
        resumeMetas: fields.resumeDocuments.map(
          (resumeDoc): AddResumeFilesSchema.ResumeMetaSchema => ({
            name: resumeDoc.fileName,
            fileId: resumeDoc.id,
          }),
        ),
        resumeId: resume.resumeId,
      });

      contentApiClient
        .uploadAddResumeFiles({
          requestBody: formData,
        })
        .then(res => {
          const failedRes = res.results.filter(result => result.status === "FAILED");
          if (failedRes.length > 0) {
            Snackbar.notify({
              message: t2("MultiUploadFailed", { num: failedRes.length }),
              severity: "error",
            });
            const failedResultMap = new Map<string, ContentApi.Schemas.AddResumeFilesResponse["results"][number]>();
            failedRes.forEach(failed => {
              failedResultMap.set(failed.fileId, failed);
            });
            resumeDocuments.forEach((resumeDoc, index) => {
              const failedResult = failedResultMap.get(resumeDoc.id);
              if (failedResult) {
                methods.setError(`resumeDocuments.${index}`, {
                  type: "manual",
                  message: failureReasonMap[failedResult.failureReason ?? "UNKNOWN"],
                });
              }
            });
            methods.setValue(
              "resumeDocuments",
              resumeDocuments.filter(resumeDoc => failedResultMap.has(resumeDoc.id)),
            );
          } else {
            Snackbar.notify({
              message: t("書類をアップロードしました。"),
              severity: "success",
            });
            ResumesIdDetail.refresh();
            onChangeOpen(false);
          }
        })
        .catch(async error => {
          if (error instanceof ErrorResponse) {
            const errorJson = await error.json((obj): obj is ContentApi.Response$uploadAddResumeFiles$Status$400["application/json"] => {
              return "errorStatus" in obj && typeof obj.errorStatus === "string";
            });
            Snackbar.notify({
              message: t("書類のアップロードに失敗しました。") + invalidMulterUploadStatusMap[errorJson.errorStatus],
              severity: "error",
            });
            return;
          }

          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類のアップロードに失敗しました。"));
          Snackbar.notify({
            message: errorNotification.message,
            severity: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  };
};
