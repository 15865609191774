import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import * as React from "react";

const CircularProgressWrapper = styled(Box)`
  position: relative;
  width: 120px;
  height: 120px;
`;

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const BackgroundCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette["Gray/Shades"].p30,
  opacity: "0.16",
  position: "absolute",
}));

export type PieChartProps = {
  className?: string;
  /** 0 - 100 */
  value: number;
};

const PieChart: React.FC<PieChartProps> = props => {
  const circularProgressProps: CircularProgressProps = {
    variant: "determinate",
    value: props.value,
    size: 120,
    thickness: 1.5,
  };
  return (
    <CircularProgressWrapper className={props.className}>
      <BackgroundCircularProgress
        variant="determinate"
        size={circularProgressProps.size}
        thickness={circularProgressProps.thickness}
        value={100}
      />
      <StyledCircularProgress {...circularProgressProps} />
    </CircularProgressWrapper>
  );
};

PieChart.displayName = "PieChart";

export default PieChart;
