import { Payment, Role } from "@hireroo/app-store/essential/employee";
import { useTranslation } from "@hireroo/i18n";
import appRoutes from "@hireroo/router/config";

/**
 * Extract Pattern: `/e/*`
 */
export type RoutePath = Extract<keyof typeof appRoutes, `/e/${string}`>;

const { ADMIN_ONLY_PERMISSION, ADMIN_MANAGER_PERMISSION, ADMIN_MANAGER_ENGINEER_PERMISSION, ADMIN_MANAGER_ENGINEER_EMPLOYEE_PERMISSION } = Role;

export const RoutePathPermission: Record<RoutePath, Role.Permission | null> = {
  "/e/home": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/settings/user": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/settings/company": ADMIN_ONLY_PERMISSION,
  "/e/settings/payment": ADMIN_ONLY_PERMISSION,
  "/e/settings/billing/overview": ADMIN_ONLY_PERMISSION,
  "/e/settings/billing/usages": ADMIN_ONLY_PERMISSION,
  "/e/settings/contract/create": ADMIN_ONLY_PERMISSION,
  "/e/settings/payment/method/update": ADMIN_ONLY_PERMISSION,
  "/e/settings/plan/update": ADMIN_ONLY_PERMISSION,
  "/e/settings/plan/list": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/settings/buy/selections": ADMIN_ONLY_PERMISSION,
  "/e/settings/billing/invoices": ADMIN_ONLY_PERMISSION,
  "/e/settings/order/history": ADMIN_ONLY_PERMISSION,
  "/e/settings/company/profile": ADMIN_ONLY_PERMISSION,
  "/e/settings/company/security": ADMIN_ONLY_PERMISSION,
  "/e/settings/company/integration/sonar": ADMIN_ONLY_PERMISSION,
  "/e/settings/company/notification/slack": ADMIN_ONLY_PERMISSION,
  "/e/settings/company/employee": ADMIN_ONLY_PERMISSION,
  "/e/settings/company/talent": ADMIN_ONLY_PERMISSION,
  "/e/settings/company/group/employee": ADMIN_ONLY_PERMISSION,
  "/e/settings/company/skillmap": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/screenings": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/screenings/:id/detail": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/screenings/create": ADMIN_MANAGER_PERMISSION,
  "/e/screenings/:id/update": ADMIN_MANAGER_PERMISSION,
  "/e/screenings/tests": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/screenings/tests/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/screenings/tests/:id/detail": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/screenings/tests/:id/review": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/interviews": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/interview-groups/list": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/interviews/create": ADMIN_MANAGER_PERMISSION,
  "/e/interviews/:id/update": ADMIN_MANAGER_PERMISSION,
  "/e/interview-groups/create": ADMIN_MANAGER_PERMISSION,
  "/e/interview-groups/:id/update": ADMIN_MANAGER_PERMISSION,
  "/e/interviews/:id/review": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/interviews/:id/detail": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/remotes": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/remotes/create": ADMIN_MANAGER_PERMISSION,
  "/e/remotes/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/remotes/:id/update": ADMIN_MANAGER_PERMISSION,
  "/e/remotes/:id/evaluate": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/remotes/:id/detail": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/resumes": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/resumes/:id/update": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/resumes/:id/detail": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/evaluations/groups": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/evaluations/metrics": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/evaluations/groups/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/evaluations/metrics/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/invitations/:id": ADMIN_MANAGER_ENGINEER_EMPLOYEE_PERMISSION,
  "/e/templates/create": ADMIN_MANAGER_PERMISSION,
  "/e/templates/:id/update": ADMIN_MANAGER_PERMISSION,
  "/e/templates/remote/create": ADMIN_MANAGER_PERMISSION,
  "/e/templates/remote/:id/update": ADMIN_MANAGER_PERMISSION,
  "/e/questions/challenge/create": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/challenge/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/challenge/:id/update": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/project/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/quiz/create": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/:entityType/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/:entityType/:id/update": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/quiz/:id/update": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/quiz/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/systemdesign/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/freepad/create": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/freepad/:id/update": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/freepad/:id": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/packages": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/questions/packages/:id/detail": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/assessments": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/assessments/create": ADMIN_MANAGER_PERMISSION,
  "/e/assessments/:id/update": ADMIN_MANAGER_PERMISSION,
  "/e/assessments/:id/detail": ADMIN_MANAGER_ENGINEER_PERMISSION,
  "/e/exams/:id/review": ADMIN_MANAGER_ENGINEER_PERMISSION,
};

type RequirePlanMessage = {
  featureName: string;
  isAvailable: boolean;
};

type RoutePathPlan = { [key in RoutePath]?: RequirePlanMessage };

export const useValidateCurrentPlan = (pattern?: RoutePath): RequirePlanMessage | "AVAILABLE" => {
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const { t } = useTranslation();
  const routePathPlan: RoutePathPlan = {
    "/e/remotes": {
      featureName: t("インタビュー"),
      isAvailable: isAvailableFeature("interview.read"),
    },
    "/e/remotes/create": {
      featureName: t("インタビュー"),
      isAvailable: isAvailableFeature("interview.create"),
    },
    "/e/remotes/:id/update": {
      featureName: t("インタビュー"),
      isAvailable: isAvailableFeature("interview.edit"),
    },
    "/e/remotes/:id/detail": {
      featureName: t("インタビュー"),
      isAvailable: isAvailableFeature("interview.read"),
    },
    "/e/questions/challenge/create": {
      featureName: `${t("問題作成")} ${t("コーディング形式")})`,
      isAvailable: isAvailableFeature("question.challenge.create"),
    },
    "/e/questions/challenge/:id/update": {
      featureName: `${t("問題作成")} ${t("コーディング形式")})`,
      isAvailable: isAvailableFeature("question.challenge.edit"),
    },
    "/e/questions/quiz/create": {
      featureName: `${t("問題作成")} ${t("クイズ形式")})`,
      isAvailable: isAvailableFeature("question.quiz.create"),
    },
    "/e/questions/quiz/:id/update": {
      featureName: `${t("問題作成")} ${t("クイズ形式")})`,
      isAvailable: isAvailableFeature("question.quiz.edit"),
    },
    "/e/questions/freepad/create": {
      featureName: `${t("問題作成")} ${t("ライブコーディング")})`,
      isAvailable: isAvailableFeature("question.freepad.create"),
    },
    "/e/questions/freepad/:id/update": {
      featureName: `${t("問題作成")} ${t("ライブコーディング")})`,
      isAvailable: isAvailableFeature("question.freepad.edit"),
    },
    "/e/evaluations/groups": {
      featureName: t("評価指標管理"),
      isAvailable: isAvailableFeature("test.technical-comment.create"),
    },
    "/e/evaluations/metrics": {
      featureName: t("評価指標管理"),
      isAvailable: isAvailableFeature("test.technical-comment.create"),
    },
  };
  if (!pattern) {
    return "AVAILABLE";
  }
  const requirePattern = routePathPlan[pattern];
  if (!requirePattern || requirePattern?.isAvailable) {
    return "AVAILABLE";
  }
  return requirePattern;
};
