import { ShowingTarget } from "@hireroo/app-helper/entity";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";

import { useGenerateAnswerSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateAnswerSectionProps";
import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateHeaderProps";
import { useGeneratePerformanceDetailSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGeneratePerformanceDetailSectionProps";
import { useGeneratePlaybackSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGeneratePlaybackSectionProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateQuestionDetailProps";
import { GenerateWorkspaceNotificationSectionPropsArgs } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateWorkspaceNotificationSectionProps";
import { useGenerateTestCaseSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useTestCaseSectionProps";
import TotalScoreRankVisualizerInitialContainer from "../../shared/TotalScoreRankVisualizer/InitialContainer";

export type GenerateProjectTestReportPropsArgs = {
  showingTargets: ShowingTarget[];
  kindOfReview: GenerateWorkspaceNotificationSectionPropsArgs["kindOfReview"];
  testId: string;
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  appInitialized: boolean;
};

export const useGenerateProps = (args: GenerateProjectTestReportPropsArgs): Widget.ProjectTestReportProps => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submissionStatus = hooks.useCurrentSubmissionStatus();
  const submission = hooks.useCurrentSubmission();
  const projectVersion = hooks.useProjectVersion();
  const headerProps = useGenerateHeaderProps(args);
  const entityScoreBoardProps = useGenerateEntityScoreBoardProps({
    ...args,
    showingTargets: [
      args.showingTargets.includes("SCORE") && ("SCORE" as const),
      args.showingTargets.includes("RANK") && ("RANK" as const),
    ].flatMap(v => (v ? [v] : [])),
  });
  const testCaseSectionProps = useGenerateTestCaseSectionProps({
    ...args,
    showScore: args.showingTargets.includes("SCORE"),
  });
  const answerSectionProps = useGenerateAnswerSectionProps({
    ...args,
    showingTargets: args.showingTargets.includes("STATISTICS") ? ["AVERAGE_VALUE"] : [],
  });
  const performanceDetailSectionProps = useGeneratePerformanceDetailSectionProps({
    ...args,
    showScore: args.showingTargets.includes("SCORE"),
  });
  const playbackSectionProps = useGeneratePlaybackSectionProps({ ...args, pause: args.appInitialized });
  const questionDetailProps = useGenerateQuestionDetailProps({
    entityId: args.entityId,
    showAnswer: args.showingTargets.includes("ANSWER"),
    showArchivedMark: false,
  });

  return {
    header: headerProps,
    answerDetailSection: answerSectionProps,
    entityScoreBoard: entityScoreBoardProps,
    StatisticsContent: args.showingTargets.includes("STATISTICS") && (
      <TotalScoreRankVisualizerInitialContainer uniqueKey={args.uniqueKey} score={submission?.totalScore ?? 0} enableRank />
    ),
    testCaseSection: submissionStatus === "EVALUATED" ? testCaseSectionProps : undefined,
    // We show playback section only for V1 and V3 project
    playbackSection: projectVersion !== "V2" ? playbackSectionProps : undefined,
    // Candidate cannot see workspace notification
    workspaceNotificationSection: undefined,
    performanceDetailSection: submissionStatus === "EVALUATED" ? performanceDetailSectionProps : undefined,
    questionDetail: questionDetailProps,
  };
};
