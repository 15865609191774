import { useEnabledResumeService } from "@hireroo/app-helper/feature";
import { Payment } from "@hireroo/app-store/essential/employee";
import { App } from "@hireroo/app-store/essential/shared";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";
import { useLocation } from "react-router";

import CompanySelectContainer from "./parts/CompanySelect/Container";

export type GenerateEmployeeSideBarContentPropsArgs = {};

type MenuItem = Widget.EmployeeSideBarContentProps["menuItems"][0];

export const useGenerateProps = (_args: GenerateEmployeeSideBarContentPropsArgs): Widget.EmployeeSideBarContentProps => {
  const { t } = useTranslation();
  const mode = EmployeeSideBarContent.useMode();

  const navigate = useTransitionNavigate();

  const isAvailableFeature = Payment.useIsAvailableFeature();
  const location = useLocation();
  const enableTalentScore = App.useEnableTalentScore();
  const enabledResumeService = useEnabledResumeService();

  const menuItems = React.useMemo((): Widget.EmployeeSideBarContentProps["menuItems"] => {
    const baseMenuItems: MenuItem[] = [
      {
        id: "HOME",
        children: t("ホーム"),
        startIcon: "HOME",
        href: generatePath("/e/home"),
        onClick: () => {
          navigate("/e/home");
        },
      },
    ];
    if (enabledResumeService) {
      baseMenuItems.push({
        id: "RESUME",
        children: t("書類選考一覧"),
        startIcon: "DOCUMENT",
        href: generatePath("/e/resumes"),
        onClick: () => {
          navigate("/e/resumes");
        },
      });
    }
    const screeningItem: MenuItem[] = [
      {
        id: "SCREENINGS",
        href: generatePath("/e/screenings"),
        onClick: () => {
          navigate("/e/screenings");
        },
        title: t("コーディングテスト"),
        startIcon: "MONITOR",
        children: [
          {
            id: "SCREENINGS",
            children: t("コーディングテスト一覧"),
            href: generatePath("/e/screenings"),
            onClick: () => {
              navigate("/e/screenings");
            },
          },
          {
            id: "SCREENINGS_TESTS",
            children: t("候補者一覧"),
            href: generatePath("/e/screenings/tests"),
            onClick: () => {
              navigate("/e/screenings/tests");
            },
          },
          {
            id: "QUESTIONS",
            children: t("問題一覧"),
            href: generatePath("/e/questions"),
            onClick: () => {
              navigate("/e/questions");
            },
          },
          {
            id: "QUESTIONS_PACKAGES",
            children: t("問題集一覧"),
            href: generatePath("/e/questions/packages"),
            onClick: () => {
              navigate("/e/questions/packages");
            },
          },
        ],
      },
    ];
    baseMenuItems.push(...screeningItem);

    if (isAvailableFeature("interview.read")) {
      baseMenuItems.push({
        id: "REMOTES",
        title: t("リモートインタビュー"),
        href: generatePath("/e/remotes"),
        onClick: () => {
          navigate("/e/remotes");
        },
        startIcon: "VIDEOCAM",
        children: [
          {
            id: "INTERVIEWS",
            children: t("インタビュー一覧"),
            href: generatePath("/e/remotes"),
            onClick: () => {
              navigate("/e/remotes");
            },
          },
          {
            id: "QUESTIONS_WITH_FREEPAD",
            children: t("問題一覧"),
            href: generatePath("/e/questions", { queryParams: { questionVariant: "FREEPAD" } }),
            onClick: () => {
              navigate("/e/questions", { queryParams: { questionVariant: "FREEPAD" } });
            },
          },
        ],
      });
    }

    if (isAvailableFeature("test.technical-comment.create")) {
      baseMenuItems.push({
        id: "EVALUATIONS_GROUP",
        href: generatePath("/e/evaluations/groups"),
        onClick: () => {
          navigate("/e/evaluations/groups");
        },
        title: t("評価指標管理"),
        startIcon: "REGISTRATION",
        children: [
          {
            id: "EVALUATIONS_GROUPS",
            children: t("評価指標一覧"),
            href: generatePath("/e/evaluations/groups"),
            onClick: () => {
              navigate("/e/evaluations/groups");
            },
          },
          {
            id: "EVALUATIONS_METRICS",
            children: t("評価項目一覧"),
            href: generatePath("/e/evaluations/metrics"),
            onClick: () => {
              navigate("/e/evaluations/metrics");
            },
          },
        ],
      });
    }

    if (enableTalentScore && isAvailableFeature("assessment.read")) {
      baseMenuItems.push({
        id: "ASSESSMENTS",
        children: t("タレントスコア"),
        startIcon: "ASSIGNMENT",
        href: generatePath("/e/assessments"),
        onClick: () => {
          navigate("/e/assessments");
        },
      });
    }

    return baseMenuItems;
  }, [t, enabledResumeService, isAvailableFeature, enableTalentScore, navigate]);

  return {
    mode: mode,
    CompanySelect: <CompanySelectContainer />,
    menuItems: menuItems,
    //TODO: move to another widget
    remainSelections: 88,
    createButton: {
      onClick: () => {
        if (location.pathname.includes("interviews")) {
          navigate("/e/interviews/create");
        } else if (location.pathname.includes("assessments")) {
          navigate("/e/assessments/create");
        } else if (location.pathname.includes("remotes")) {
          navigate("/e/remotes/create");
        } else {
          // create interview by default
          navigate("/e/interviews/create");
        }
      },
    },
    //TODO: move to another widget
    addButton: {},
  };
};
