import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";
import SplitButton, { SplitButtonProps } from "../SplitButton/SplitButton";
import ScrollableTagList, { ScrollableTagListProps } from "./parts/ScrollableTagList/ScrollableTagList";
import TextFilter, { TextFilterProps } from "./parts/TextFilter/TextFilter";

type FieldValue = {
  value: string;
};

type SelectableTag = ScrollableTagListProps["selectableTags"][number];

type Tag = {
  label: string;
};
type TagId = string;

export type TagSearchSelectFieldProps = {
  name: string;
  title: string;
  textFilter: Pick<TextFilterProps, "onSubmit" | "placeholder">;
  scrollableTags: Pick<ScrollableTagListProps, "onReachedEnd" | "loading" | "size">;
  selectableTags: FieldValue[];
  tagSources: Record<TagId, Tag>;
  onChange?: (fields: FieldValue[]) => void;
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
  splitButton?: Pick<SplitButtonProps, "onOpen" | "onClose">;
};

const TagSearchSelectField: React.FC<TagSearchSelectFieldProps> = props => {
  const { t } = useTranslation();
  const { onChange } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue[]>>({
    name: props.name,
  });
  const [tags, setTags] = React.useState<FieldValue[]>(field.value);

  const resetField = React.useCallback(() => {
    setTags([]);
    onChange?.([]);
    field.onChange([]);
  }, [field, onChange]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setTags([]);
      onChange?.([]);
    });
    return () => {
      stop();
    };
  }, [onChange, onClearFields]);

  const splitButtonProps: SplitButtonProps = {
    ...props.splitButton,
    title: props.title,
    disabled: props.disabled,
    status: tags.length > 0 ? "ACTIVE" : "INACTIVE",
    label: {
      children:
        tags.length === 0 ? props.title : [props.title, tags.map(t => props.tagSources[t.value]?.label ?? t.value).join(", ")].join(": "),
    },
    onApply: () => {
      props.onChange?.(tags);
      field.onChange(tags);
    },
    onReset: () => {
      resetField();
    },
    variant: props.variant,
  };

  const textFilter: TextFilterProps = {
    ...props.textFilter,
    placeholder: props.textFilter.placeholder ?? t("タグを検索"),
    disabled: props.disabled,
  };

  const handleSelect = (tag: FieldValue) => {
    setTags(prevTags => {
      if (prevTags.find(prevTag => prevTag.value === tag.value)) {
        return prevTags.filter(prevTag => prevTag.value !== tag.value);
      }
      return prevTags.concat(tag);
    });
  };
  const scrollableTagList: ScrollableTagListProps = {
    ...props.scrollableTags,
    selectableTags: props.selectableTags.map((tag): SelectableTag => {
      const exists = tags.findIndex(target => target.value === tag.value) !== -1;
      const tagSource = props.tagSources[tag.value];
      const displayName = tagSource?.label ?? tag.value;
      return {
        label: displayName,
        onClick: () => {
          handleSelect(tag);
        },
        color: exists ? "primary" : "default",
        tooltip: displayName.length > 30 ? displayName : "",
      };
    }),
  };

  return (
    <SplitButton {...splitButtonProps}>
      <Box px={1.5} py={0.5}>
        <FormControl size="small">
          <Stack mt={1} width={400} spacing={2}>
            <TextFilter {...textFilter} />
            <ScrollableTagList {...scrollableTagList} />
          </Stack>
        </FormControl>
      </Box>
    </SplitButton>
  );
};

TagSearchSelectField.displayName = "TagSelectField";

export default TagSearchSelectField;
