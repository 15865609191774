import { Auth } from "@hireroo/app-store/essential/employee";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ResumeDocumentUploadDialogContainer, { ResumeDocumentUploadDialogContainerProps } from "./Container";

export type ResumeDocumentUploadDialogInitialContainerProps = ResumeDocumentUploadDialogContainerProps;

const ResumeDocumentUploadDialogInitialContainer: React.FC<ResumeDocumentUploadDialogInitialContainerProps> = props => {
  const currentUser = Auth.useCurrentUser();
  const providerProps: Widget.ResumeDocumentUploadDialogProviderProps = {
    defaultValues: {
      resumeDocuments: [],
      isPublic: true,
      tags: [],
      viewers: [],
      editorUid: currentUser.uid,
    },
  };

  return (
    <ErrorBoundary>
      <Widget.ResumeDocumentUploadDialogProvider {...providerProps}>
        <ResumeDocumentUploadDialogContainer {...props} />
      </Widget.ResumeDocumentUploadDialogProvider>
    </ErrorBoundary>
  );
};

ResumeDocumentUploadDialogInitialContainer.displayName = "ResumeDocumentUploadDialogInitialContainer";

export default withErrorBoundary(ResumeDocumentUploadDialogInitialContainer, {});
