import { useTranslation } from "@hireroo/i18n";
import { ResumeEditorForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";

const Wrapper = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  width: "100%",
  textOverflow: "ellipsis",
}));

const Header = styled(Paper)(() => ({
  height: 56,
  flexShrink: 0,
  width: "100%",
  paddingLeft: "16px",
  paddingRight: "16px",
  boxShadow: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Content = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  position: "relative",
}));

const ScrollableArea = styled(Box)(() => ({
  overflowX: "hidden",
  overflowY: "auto",
  width: "800px",
  margin: "0 auto",
}));

const StyledButton = styled(Button)(() => ({
  textTransform: "none",
  whiteSpace: "nowrap",
  flexShrink: 0,
}));

export type LayoutProps = {
  className?: string;
  children: React.ReactNode;
  submitButton: Pick<ButtonProps, "disabled">;
  onSubmit: SubmitHandler<ResumeEditorForm.ResumeEditorFormSchema>;
  cancelButton: Pick<ButtonProps, "onClick" | "disabled">;
};

const Layout: React.FC<LayoutProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<ResumeEditorForm.ResumeEditorFormSchema>();

  const { onSubmit } = props;
  const title = methods.watch("name");

  const cancelButton: ButtonProps = {
    ...props.cancelButton,
    children: t("キャンセル"),
    color: "secondary",
    variant: "text",
  };

  const submitButton: ButtonProps = {
    ...props.submitButton,
    children: t("更新"),
    variant: "contained",
    color: "secondary",
    onClick: () => {
      methods.handleSubmit(onSubmit)();
    },
  };

  return (
    <Wrapper className={props.className}>
      <Header>
        <Stack direction="row" alignItems="center" sx={{ overflow: "hidden" }}>
          <StyledTitle>{title}</StyledTitle>
        </Stack>
        <Stack spacing={2} direction="row">
          <StyledButton key="cancel" {...cancelButton} />
          <StyledButton key="submit" {...submitButton} />
        </Stack>
      </Header>
      <Content>
        <Stack direction="row" width="100%" height="100%">
          <ScrollableArea>{props.children}</ScrollableArea>
        </Stack>
      </Content>
    </Wrapper>
  );
};

Layout.displayName = "Layout";

export default Layout;
