import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DragAndDropContent from "./parts/DragAndDropContent/DragAndDropContent";
import ResumeDocumentTableRow, { ResumeDocumentTableRowProps } from "./parts/ResumeDocumentTableRow/ResumeDocumentTableRow";

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type ResumeDetailUploadContentProps = {
  rows: Omit<ResumeDocumentTableRowProps, "errorMessageName">[];
};

const ResumeDetailUploadContent: React.FC<ResumeDetailUploadContentProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack spacing={3}>
      <DragAndDropContent />
      {props.rows.length > 0 && (
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: "1px solid", borderColor: theme.palette["Other"]["Divider"] }}>
              <StyledTableCell>
                <Typography fontSize={12} color="textSecondary">
                  {t("ファイル名")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize={12} color="textSecondary">
                  {t("サイズ")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, index) => (
              <ResumeDocumentTableRow key={row.id} {...row} errorMessageName={`resumeDocuments.${index}`} />
            ))}
          </TableBody>
        </Table>
      )}
    </Stack>
  );
};

ResumeDetailUploadContent.displayName = "ResumeDetailUploadContent";

export default ResumeDetailUploadContent;
