import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import ProjectContentsViewerV1Container from "./widget/ProjectContentsViewerV1/Container";
import ProjectContentsViewerV3Container from "./widget/ProjectContentsViewerV3/Container";

export type GeneratePlaybackSectionProps = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  pause: boolean;
};

export const useGeneratePlaybackSectionProps = (args: GeneratePlaybackSectionProps): Widget.ProjectTestReportProps["playbackSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const projectMajorVersion = hooks.useProjectVersion();
  const questionId = hooks.useQuestionId();
  const [mode, setMode] = React.useState<"HISTORY" | "PLAYBACK">("HISTORY");
  const appealMessage = hooks.useAppealMessage();

  const ContentViewerMap: Record<"V1" | "V2" | "V3", React.ReactNode> = {
    V1:
      typeof questionId === "number" ? (
        <ProjectContentsViewerV1Container mode={mode} projectId={args.entityId} questionId={questionId} />
      ) : null,
    V2: null,
    V3:
      typeof questionId === "number" ? (
        <ProjectContentsViewerV3Container mode={mode} projectId={args.entityId} questionId={questionId} />
      ) : null,
  };

  return {
    playbackModeSwitcher:
      // TODO: Remove this condition when playback v3 has implemented
      projectMajorVersion === "V3"
        ? undefined
        : {
            onChangeMode: mode => {
              if (mode === "HISTORY" || mode === "PLAYBACK") {
                setMode(mode);
              }
            },
          },
    ContentsViewer: ContentViewerMap[projectMajorVersion],
    appealMessageArea: appealMessage
      ? {
          body: appealMessage,
        }
      : undefined,
  };
};
