export type Language =
  | "c"
  | "csharp"
  | "python"
  | "go"
  | "typescript"
  | "javascript"
  | "php"
  | "perl"
  | "ruby"
  | "kotlin"
  | "java"
  | "sql"
  | "mysql"
  | "pgsql"
  | "html"
  | "css"
  | "scss"
  | "markdown"
  | "json"
  | "yaml"
  | "xml"
  | "dockerfile"
  | "javascriptreact"
  | "typescriptreact"
  | "rust"
  | "plaintext"
  | "vue";

export const ExtensionToLanguage: Record<string, Language> = {
  ".c": "c",
  ".cs": "csharp",
  ".py": "python",
  ".go": "go",
  ".ts": "typescript",
  ".js": "javascript",
  ".jsx": "javascriptreact",
  ".tsx": "typescriptreact",
  ".php": "php",
  ".pl": "perl",
  ".rb": "ruby",
  ".kt": "kotlin",
  ".java": "java",
  ".sql": "sql",
  ".mysql": "mysql",
  ".pgsql": "pgsql",
  ".html": "html",
  ".htm": "html",
  ".css": "css",
  ".scss": "scss",
  ".md": "markdown",
  ".json": "json",
  ".yaml": "yaml",
  ".yml": "yaml",
  ".xml": "xml",
  Dockerfile: "dockerfile",
  ".vue": "vue",
  ".rs": "rust",
};

/**
 * Check the following repository to see if LSP is supported:
 * @see https://github.com/hireroo/project-terminal/tree/docs/add-readme/dockerfiles
 * Even if LSP is implemented, if it does not work correctly and onChange does not fire, it will be treated as if LSP is not supported.
 *
 * LSPが対応しているかどうかは以下のRepositoryを確認すること
 * @see https://github.com/hireroo/project-terminal/tree/docs/add-readme/dockerfiles
 *
 * LSPが実装されていても、正しく動いていない場合、onChangeが発火しないので、LSPをサポートされていない扱いとする
 */
export const LspSupportedMap: Record<Language, boolean> = {
  go: true,
  python: true,
  typescript: true,
  javascript: true,
  php: true,
  javascriptreact: true,
  typescriptreact: true,
  vue: true,
  ruby: true,

  /** Not working */
  java: false,

  /** Unimplemented */
  c: false,
  csharp: false,
  perl: false,
  kotlin: false,
  sql: false,
  mysql: false,
  pgsql: false,
  html: false,
  css: false,
  scss: false,
  markdown: false,
  json: false,
  yaml: false,
  xml: false,
  dockerfile: false,
  rust: false,
  plaintext: false,
};

export type EditorConfig = {
  documentSelector: string[];
};

/**
 * vscodeのsettings.jsonと同等
 * @see https://code.visualstudio.com/docs/getstarted/settings
 */
export const defaultEditorSettingsJson = {
  "workbench.colorTheme": "Monokai",
  "editor.fontSize": 14,
  gopls: {
    hints: {
      assignVariableTypes: true,
      compositeLiteralFields: true,
      compositeLiteralTypes: true,
      constantValues: true,
      functionTypeParameters: true,
      parameterNames: true,
      rangeVariableTypes: true,
    },
  },
  "rust-analyzer": {
    initialization_options: {
      rust: {
        analyzerTargetDir: true,
      },
      // get more cargo-less diagnostics from rust-analyzer,
      // which might include false-positives (those can be turned off by their names)
      diagnostics: {
        experimental: {
          enable: true,
        },
      },
      // To disable the checking entirely
      // (ignores all cargo and check settings below)
      checkOnSave: false,
      // To check the `lib` target only.
      cargo: {
        allTargets: false,
      },
      // Use `-p` instead of `--workspace` for cargo check
      check: {
        workspace: false,
      },
    },
  },
};

export const LanguageToEditorConfig: Record<Language, EditorConfig> = {
  go: {
    documentSelector: ["go"],
  },
  python: {
    documentSelector: ["python"],
  },
  ruby: {
    documentSelector: ["ruby"],
  },
  javascript: {
    documentSelector: ["javascript"],
  },
  javascriptreact: {
    documentSelector: ["javascriptreact"],
  },
  typescriptreact: {
    documentSelector: ["typescript", "typescriptreact"],
  },
  typescript: {
    documentSelector: ["typescript", "typescriptreact"],
  },
  vue: {
    documentSelector: ["vue"],
  },
  html: {
    documentSelector: ["html"],
  },
  markdown: {
    documentSelector: ["md"],
  },
  json: {
    documentSelector: ["json"],
  },
  rust: {
    documentSelector: ["rust"],
  },
  c: {
    documentSelector: ["c"],
  },
  csharp: {
    documentSelector: ["c"],
  },
  php: {
    documentSelector: ["php"],
  },
  perl: {
    documentSelector: ["perl"],
  },
  kotlin: {
    documentSelector: ["kotlin"],
  },
  java: {
    documentSelector: ["java"],
  },
  sql: {
    documentSelector: ["sql"],
  },
  mysql: {
    documentSelector: ["sql"],
  },
  pgsql: {
    documentSelector: ["sql"],
  },
  css: {
    documentSelector: ["css"],
  },
  scss: {
    documentSelector: ["scss"],
  },
  yaml: {
    documentSelector: ["yaml"],
  },
  xml: {
    documentSelector: ["xml"],
  },
  dockerfile: {
    documentSelector: ["dockerfile"],
  },
  plaintext: {
    documentSelector: [],
  },
};
