import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import GeneralSummary, { GeneralSummaryProps } from "./parts/GeneralSummary/GeneralSummary";

export type ResumeGeneralProps = {
  generalSummary: GeneralSummaryProps;
};

const ResumeGeneral: React.FC<ResumeGeneralProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2} p={3}>
      <Typography fontWeight={700}>{t("一般情報")}</Typography>
      <GeneralSummary {...props.generalSummary} />
    </Stack>
  );
};

ResumeGeneral.displayName = "ResumeGeneral";

export default ResumeGeneral;
