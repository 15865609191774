import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateSkillTagListPropsArgs, useGenerateProps } from "./useGenerateProps";

export type SkillTagListContainerProps = GenerateSkillTagListPropsArgs;

const SkillTagListContainer: React.FC<SkillTagListContainerProps> = props => {
  const skillTagListProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.SkillTagList {...skillTagListProps} />
    </ErrorBoundary>
  );
};

SkillTagListContainer.displayName = "SkillTagListContainer";

export default withErrorBoundary(SkillTagListContainer, {});
