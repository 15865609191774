import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../../primitive/BaseDialog/BaseDialog";
import SkillTag, { SkillTagProps } from "../SkillTag/SkillTag";

const StyledTagWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  width: "100%",
}));

export type SkillMapDialogWithButtonProps = {
  matchedSkillTags: Omit<SkillTagProps, "kind">[];
  unmatchedSkillTags: Omit<SkillTagProps, "kind">[];
  skillMapDate: string;
};

const SkillMapDialogWithButton: React.FC<SkillMapDialogWithButtonProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const skillMapButton: ButtonProps = {
    variant: "text",
    size: "small",
    sx: {
      wordBreak: "keep-all",
    },
    color: "outline-only",
    children: t("自社スキルマップ"),
    onClick: () => {
      setOpen(true);
    },
  };

  const dialog: BaseDialogProps = {
    open: open,
    title: t("自社スキルマップ"),
    noButton: {
      color: "secondary",
      children: t("閉じる"),
      variant: "contained",
      onClick: () => {
        setOpen(false);
      },
    },
  };
  return (
    <Box>
      <Button {...skillMapButton} />
      <BaseDialog {...dialog}>
        <Stack spacing={2}>
          <Typography>{t("自社で設定したスキルマップを確認できます")}</Typography>
          <StyledTagWrapper>
            {props.matchedSkillTags.map(skillTag => (
              <SkillTag key={`${skillTag.label}`} kind="CHECKED" {...skillTag} />
            ))}
            {props.unmatchedSkillTags.map(skillTag => (
              <SkillTag key={`${skillTag.label}`} kind="DEFAULT" {...skillTag} />
            ))}
          </StyledTagWrapper>
          <Typography fontSize={12}>{props.skillMapDate}</Typography>
        </Stack>
      </BaseDialog>
    </Box>
  );
};

SkillMapDialogWithButton.displayName = "SkillMapDialog";

export default SkillMapDialogWithButton;
