import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth, Role } from "@hireroo/app-store/essential/employee";
import { Resumes } from "@hireroo/app-store/page/e/resumes";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import * as Sentry from "@sentry/react";
import * as React from "react";

type BulkListActivityBarProps = Widget.ResumeListProps["searchResultBar"]["bulkListActivityBar"];

type TagOption = BulkListActivityBarProps["tagPopper"]["form"]["multiChoiceField"]["options"][number];
type DefaultValues = BulkListActivityBarProps["tagPopper"]["form"]["defaultValues"];
type Item = Exclude<BulkListActivityBarProps["evaluation"], undefined>["dialog"]["items"][number];

export type GenerateBulkListActivityBarPropsArgs = {};

export const useGenerateBulkListActivityBarProps = (_args: GenerateBulkListActivityBarPropsArgs): BulkListActivityBarProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const client = getGraphqlClient();
  const currentUid = Auth.useCurrentUid();
  const selectedResumeIds = Resumes.useSelectedResumeIds();
  const selectedResumes = Resumes.useSelectedResumes();
  const tags = Resumes.useTags();
  const [addedTags, setAddedTags] = React.useState<string[]>([]);
  const matchingRole = Role.useMatchingRole();

  const defaultValues = React.useMemo((): DefaultValues => {
    const tagSet = new Set<string>();
    const newAddedTags = addedTags.map((tag): DefaultValues["tags"][0] => ({
      name: tag,
      status: "CHECKED",
    }));
    const indeterminateTags = selectedResumes.reduce<DefaultValues["tags"]>((all, resume) => {
      resume.tags.forEach(tag => {
        if (!tagSet.has(tag.name)) {
          all.push({
            name: tag.name,
            status: "INDETERMINATE",
          });
          tagSet.add(tag.name);
        }
      });
      return all;
    }, []);
    return {
      tags: newAddedTags.concat(indeterminateTags),
    };
  }, [addedTags, selectedResumes]);

  const defaultTags = React.useMemo((): string[] => {
    return defaultValues.tags.map(tag => tag.name);
  }, [defaultValues]);

  const tagOptions = React.useMemo((): TagOption[] => {
    const newAddedTags = addedTags.map(
      (tag): TagOption => ({
        value: tag,
        displayName: tag,
      }),
    );
    const existingTags = tags.map((tag): TagOption => {
      return {
        value: tag.name,
        displayName: tag.name,
        hasIndeterminate: defaultTags.includes(tag.name),
      };
    });
    return newAddedTags.concat(existingTags);
  }, [addedTags, tags, defaultTags]);

  const canMultiEvaluate = React.useMemo(() => selectedResumes.every(resume => resume.status === "CALCULATED"), [selectedResumes]);

  const titleTooltip = React.useMemo(() => {
    if (canMultiEvaluate) {
      if (!matchingRole.ADMIN_OR_MANAGER.matched) {
        return matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched;
      }
      return undefined;
    }
    return t("採点済みのステータスのみ評価が可能です。");
  }, [canMultiEvaluate, matchingRole.ADMIN_OR_MANAGER.matched, matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched, t]);

  return {
    selectedItemsCount: selectedResumeIds.length,
    evaluation: {
      dialog: {
        items: selectedResumes.map((resume): Item => {
          return {
            id: resume.resumeId,
            candidateName: resume.name,
            reportButton: {
              href: generateCurrentOriginUrl("/e/resumes/:id/detail", { pathParams: { id: resume.resumeId } }),
              text: t("詳細"),
            },
          };
        }),
        heading: `${t("書類選考一覧")} ${t2("SelectingItems", { nums: selectedResumeIds.length })}`,
      },
      onSubmit: (fields, controller) => {
        controller.setLoading(true);
        client
          .EvaluateMultiResumesForResumes({
            input: {
              resumeIds: selectedResumeIds.slice(),
              employeeId: currentUid,
              isPassed: fields.result === "PASSED",
              evaluationComment: fields.comment,
            },
          })
          .then(res => {
            Resumes.updateResumes(res.evaluateMultiResumes);
            Resumes.updateResumesAfterEvaluate();
            Snackbar.notify({
              severity: "success",
              message: t("書類選考の評価を完了しました。"),
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類選考の評価に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            controller.setLoading(false);
            controller.close();
          });
      },
      disabled: !canMultiEvaluate || !matchingRole.ADMIN_OR_MANAGER.matched,
      title: titleTooltip,
    },
    deleteButton: {
      disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
      title: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
    },
    deletesDialog: {
      title: t("選択された書類選考を一括削除"),
      description: t("一度削除すると元に戻すことはできません。"),
      items: selectedResumes.map(resume => {
        return {
          id: resume.resumeId,
          name: resume.name,
        };
      }),
      onSubmit: controller => {
        controller.setLoading(true);
        const targetResumeIds = selectedResumeIds.slice();
        client
          .DeleteMultiResumesForResumes({
            input: {
              resumeIds: targetResumeIds,
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("書類選考を削除しました。"),
            });
            Resumes.deleteResumes(targetResumeIds);
            Resumes.updateRefreshKey();
            controller.close();
            Resumes.clearSelectedResumeIds();
            Resumes.updateRefreshKey();
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("コーディングテストの削除に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            controller.setLoading(false);
          });
      },
    },
    tagPopper: {
      form: {
        onSubmit: (fields, controller) => {
          const addTags = fields.tags.filter(tag => tag.status === "INDETERMINATE" || tag.status === "CHECKED").map(t => t.name);
          const deleteTags = fields.tags.filter(tag => tag.status === "UNCHECKED").map(t => t.name);
          controller.setLoading(true);
          client
            .UpdateTagsToResumesForResumes({
              input: {
                resumeIds: selectedResumeIds.slice(),
                addTagNames: addTags,
                deleteTagNames: deleteTags,
              },
            })
            .then(res => {
              Snackbar.notify({
                severity: "success",
                message: t("書類選考にタグを付与しました。"),
              });
              Resumes.updateResumes(res.updateTagsToResumes);
              controller.close();
              /**
               * Refresh tag list to get new created tag
               */
              Resumes.tagRefresh();
              setAddedTags([]);
              Resumes.updateRefreshKey();
              Resumes.clearSelectedResumeIds();
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類選考のタグの付与に失敗しました。"));
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              controller.setLoading(false);
            });
        },
        onTagAdded: tagName => {
          setAddedTags(prev => [tagName, ...prev]);
        },
        multiChoiceField: {
          options: tagOptions,
        },
        defaultValues: defaultValues,
      },
    },
  };
};
