import { App, Company } from "@hireroo/app-store/essential/employee";
import { SkillMapSettings } from "@hireroo/app-store/widget/e/SkillMapSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import SkillMapSettingsContainer, { SkillMapSettingsContainerProps } from "./Container";

export type SkillMapSettingsFetchContainerProps = {};

const SkillMapSettingsFetchContainer: React.FC<SkillMapSettingsFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const companyId = Company.useStrictActiveCompanyId();
  const initialized = SkillMapSettings.useInitialized();
  const [result, refresh] = Graphql.useGetCompanySkillTagsForSkillMapSettingsQuery({
    variables: {
      companyId: companyId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      SkillMapSettings.initialize(result.data.company.skillTags);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      SkillMapSettings.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: SkillMapSettingsContainerProps = {};

  return <SkillMapSettingsContainer {...containerProps} />;
};

SkillMapSettingsFetchContainer.displayName = "SkillMapSettingsFetchContainer";

export default withErrorBoundary(SkillMapSettingsFetchContainer, {});
