import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { getDatabaseReference, getTimestamp } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ProjectProvider } from "@hireroo/project/store-v3";
import { FirebaseStorePath, HistoriesPathsModel } from "@hireroo/project-shared-utils";
import * as Sentry from "@sentry/react";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import ProjectCodingEditorV3Container, { ProjectCodingEditorV3ContainerProps } from "./Container";

export type ProjectCodingEditorV3FetchContainerProps = Omit<ProjectCodingEditorV3ContainerProps, "historiesPathsModel">;

const ProjectCodingEditorV3FetchContainer: React.FC<ProjectCodingEditorV3FetchContainerProps> = props => {
  const { uid } = props;
  const { t } = useTranslation();
  const { useEntity } = ProjectCodingEditorV3.useCreateProjectEntityHooks(props.entityId);
  const entity = useEntity();
  const entityId = entity.projectEntityId;
  const [result, startProject] = Graphql.useStartProjectForProjectCodingEditorMutation();
  const model = React.useMemo(() => {
    return new HistoriesPathsModel({
      logger: {
        error: message => {
          if (import.meta.env.VITE_MODE === "development") {
            console.error(message);
          }
        },
        debug: message => {
          if (import.meta.env.VITE_MODE === "development") {
            console.log(message);
          }
        },
        info: message => {
          if (import.meta.env.VITE_MODE === "development") {
            console.info(message);
          }
        },
      },
      rdbBasePath: `projects/${props.entityId}/questions/${props.questionId}`,
      key: FirebaseStorePath.HISTORIES_PATHS,
      database: () => getDatabaseReference("project"),
      getTimestamp,
    });
  }, [props.entityId, props.questionId]);
  React.useEffect(() => {
    if (entity.projectStatus === "CREATED" && !result.error && !result.fetching && props.isCandidate) {
      startProject({
        input: {
          projectId: entity.projectEntityId,
          candidateId: uid,
        },
      })
        .then(res => {
          if (res.data?.startProject) {
            ProjectCodingEditorV3.initialize(res.data.startProject);
          }
        })
        .catch(error => {
          Sentry.captureException(error);
        });
    }
  }, [entity, uid, result, entityId, t, startProject, props.isCandidate]);

  React.useEffect(() => {
    return () => {
      model.dispose();
    };
  }, [model]);

  if (entity.projectStatus === "CREATED" || result.fetching || !model) {
    return <Widget.Loading kind="CENTER_%" color="secondary" />;
  }
  const containerProps: ProjectCodingEditorV3ContainerProps = {
    ...props,
    historiesPathsModel: model,
  };
  return (
    <ErrorBoundary>
      <ProjectProvider uniqueKey={props.entityId.toString()}>
        <ProjectCodingEditorV3Container {...containerProps} />
      </ProjectProvider>
    </ErrorBoundary>
  );
};

ProjectCodingEditorV3FetchContainer.displayName = "ProjectCodingEditorV3FetchContainer";

export default withErrorBoundary(ProjectCodingEditorV3FetchContainer, {});
