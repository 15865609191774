import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.resume;
};

export const useResume = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.resume) {
    throw new Error("resume is not initialized");
  }
  return snapshot.resume;
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.dialogStatus;
};

export const useSelectedResumeItem = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedResumeItem;
};

export const useCurrentTab = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentTab;
};
