import * as SkillTagHelper from "@hireroo/app-helper/skill-tag";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type TagSources = Widget.QuestionSearchAreaProps["skillTagsField"]["tagSources"];

export const useGenerateSkillTagsFieldProps = (): Widget.QuestionSearchAreaProps["skillTagsField"] => {
  const { t } = useTranslation();
  const skillTagRes = QuestionsStore.useSkillTagRes();
  const currentSkillTags = QuestionsStore.useCurrentSkillTags();
  const skillTagSources = QuestionsStore.useSkillTagSources();
  const textFilter = QuestionsStore.useSkillTagTextFilter();
  const nextOffset = QuestionsStore.useSkillTagNextOffset();

  const [result, refresh] = Graphql.useGetHierarchicalSkillTagsForQuestionsQuery({
    variables: {
      input: {
        name: textFilter,
        depth: 1,
        offset: nextOffset,
        limit: QuestionsStore.SKILL_TAG_SIZE,
        withCount: false,
      },
    },
    requestPolicy: "network-only",
  });

  React.useEffect(() => {
    if (result.data) {
      QuestionsStore.updateSkillTags(result.data.hierarchicalSkillTags);
    }
  }, [result.data]);

  return {
    selectableTags: currentSkillTags.map(skillTag => ({ value: skillTag.skillTagNodeId })),
    tagSources: React.useMemo(() => {
      const skillTagUniqueNameMap = SkillTagHelper.generateUniqueNameMapFromPath(
        skillTagSources.map(skillTag => ({
          id: skillTag.skillTagNodeId,
          name: skillTag.name,
          path: skillTag.path,
        })),
      );
      return skillTagSources.reduce<TagSources>((all, skillTag) => {
        all[skillTag.skillTagNodeId] = {
          label: skillTagUniqueNameMap.get(skillTag.skillTagNodeId) || skillTag.name,
        };
        return all;
      }, {});
    }, [skillTagSources]),
    onChange: fields => {
      // Clear skillTagState fields if the field is empty
      if (fields.length === 0) {
        QuestionsStore.clearCurrentSkillTagState();
      }
    },
    splitButton: {
      onOpen: () => {
        if (!result.fetching) {
          refresh();
        }
      },
      onClose: () => {
        QuestionsStore.clearCurrentSkillTagState();
      },
    },
    textFilter: {
      placeholder: `${t("タグを検索")} ${t("関連するタグも表示されます。")}`,
      onSubmit: fields => {
        if (fields.text !== textFilter) {
          QuestionsStore.clearCurrentSkillTagState();
        }
        QuestionsStore.updateSkillTagTextFilter(fields.text);
      },
    },
    scrollableTags: {
      onReachedEnd: () => {
        if (skillTagRes?.hasNext && !result.fetching) {
          QuestionsStore.updateSkillTagNextOffset();
        }
      },
      loading: result.fetching,
    },
  };
};
