import type * as Types from "./types";

export const createDefaultSearchQuery = (): Types.SearchQuery => {
  return {
    filters: {
      difficulties: [],
      questionVariants: [],
      statuses: [],
      titleJa: "",
      titleEn: "",
      skillTagIds: [],
    },
    isDescending: false,
    page: 0,
    size: 50,
    sortMethod: "UNKNOWN",
  };
};
