import * as Graphql from "@hireroo/graphql/client/urql";

import type * as Types from "./types";

export const defaultFilters: Readonly<Types.SearchFilter> = {
  textFilter: "",
  tags: [],
  skillTags: [],
  statuses: [],
  result: null,
  matchingRate: {
    max: 100,
    min: 0,
  },
};

export const SortFields = {
  CREATED_AT_DESCENDING: "created-at-descending",
  CREATED_AT_ASCENDING: "created-at-ascending",
  EVALUATED_AT_DESCENDING: "evaluated-at-descending",
  EVALUATED_AT_ASCENDING: "evaluated-at-ascending",
  MATCHING_RATE_DESCENDING: "matching-rate-descending",
  MATCHING_RATE_ASCENDING: "matching-rate-ascending",
} satisfies Record<string, Types.SortFieldValue>;

export const SortParams = {
  [SortFields.CREATED_AT_DESCENDING]: { sortMethod: "CREATED_AT", isDescending: true },
  [SortFields.CREATED_AT_ASCENDING]: { sortMethod: "CREATED_AT", isDescending: false },
  [SortFields.EVALUATED_AT_DESCENDING]: { sortMethod: "EVALUATED_AT", isDescending: true },
  [SortFields.EVALUATED_AT_ASCENDING]: { sortMethod: "EVALUATED_AT", isDescending: false },
  [SortFields.MATCHING_RATE_DESCENDING]: { sortMethod: "MATCHING_RATE", isDescending: true },
  [SortFields.MATCHING_RATE_ASCENDING]: { sortMethod: "MATCHING_RATE", isDescending: false },
} satisfies Record<string, { isDescending: boolean; sortMethod: Graphql.ResumesByCompanyIdSortMethod }>;

export const defaultPager: Types.Pager = {
  page: 0,
  size: 50,
  offset: 0,
  sortFieldValue: SortFields.CREATED_AT_DESCENDING,
};

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  SKILL_TAG: "skillTag",
  MIN_MATCHING_RATE: "minMatchingRate",
  MAX_MATCHING_RATE: "maxMatchingRate",
  RESULT: "result",
  STATUS: "status",
  NAME: "name",
  TAG: "tag",
  SORT: "sorting",
} as const;

export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];

export const SKILL_TAG_SIZE = 40;
