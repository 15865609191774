import { ResumesIdDetail } from "@hireroo/app-store/page/e/resumes_id_detail";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ResumeDetailContainerProps = {};

const ResumeDetailContainer: React.FC<ResumeDetailContainerProps> = () => {
  const resumeDetailProps = useGenerateProps({});
  React.useEffect(() => {
    return () => {
      ResumesIdDetail.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.ResumeDetail {...resumeDetailProps} />
    </ErrorBoundary>
  );
};

ResumeDetailContainer.displayName = "ResumeDetailContainer";

export default withErrorBoundary(ResumeDetailContainer, {});
