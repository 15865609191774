import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

import ResumeTagFieldContainer from "../../../../../../widget/v2/e/ResumeTagField/Container";
import ViewerAssignFieldContainer from "../../../../../../widget/v2/e/ViewerAssignField/Container";

export type GenerateResumeEditorPropsArgs = {
  resumeId: string;
  defaultValues: Widget.ResumeEditorProps["defaultValues"];
};

export const useGenerateProps = (args: GenerateResumeEditorPropsArgs): Widget.ResumeEditorProps => {
  const { t } = useTranslation();
  const client = getGraphqlClient();
  const navigate = useTransitionNavigate();

  const [loading, setLoading] = React.useState(false);
  return {
    layout: {
      submitButton: {
        disabled: loading,
      },
      onSubmit: fields => {
        setLoading(true);
        client
          .UpdateResumeForResumesIdUpdate({
            input: {
              resumeId: args.resumeId,
              name: fields.name || undefined,
              tags: fields.tags.map(t => t.value.name),
              viewerIds: fields.viewers.reduce<Graphql.ResumeViewerInput[]>((viewerIds, reviewer) => {
                if (reviewer.value === null) {
                  return viewerIds;
                }
                switch (reviewer.value.type) {
                  case "EMPLOYEE":
                    return viewerIds.concat({
                      type: "EMPLOYEE",
                      employeeId: reviewer.value.employeeId,
                    });
                  case "EMPLOYEE_GROUP":
                    return viewerIds.concat({
                      type: "EMPLOYEE_GROUP",
                      groupId: reviewer.value.groupId,
                    });
                  case "TALENT":
                    throw new Error("Invalid viewer type talent");
                  default:
                    throw new Error(`Invalid viewer type ${reviewer.value satisfies never}`);
                }
              }, []),
            },
          })
          .then(() => {
            Snackbar.notify({
              severity: "success",
              message: t("書類選考を更新しました。"),
            });
            navigate("/e/resumes/:id/detail", { pathParams: { id: args.resumeId } });
          })
          .catch(error => {
            Sentry.captureException(error);
            const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("書類の更新に失敗しました。"));
            Snackbar.notify({
              severity: "error",
              message: errorNotification.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
      cancelButton: {
        onClick: () => {
          navigate("/e/resumes/:id/detail", { pathParams: { id: args.resumeId } });
        },
        disabled: loading,
      },
    },
    mainSection: {
      TagField: <ResumeTagFieldContainer name="tags" variant="OUTLINED" />,
      ViewerField: <ViewerAssignFieldContainer name="viewers" variant="OUTLINED" />,
    },
    defaultValues: args.defaultValues,
  };
};
