export const SupportLanguage = {
  EN: "en",
  JA: "ja",
} as const;

export type SupportLanguage = typeof SupportLanguage;
export type SupportLanguageValue = SupportLanguage[keyof typeof SupportLanguage];

export const languageMapForDisplay: Record<SupportLanguageValue, string> = {
  [SupportLanguage.EN]: "English",
  [SupportLanguage.JA]: "日本語",
};

export type TranslationContext =
  | "c/demos_id"
  | "c/interview-groups_id"
  | "c/interviews_id"
  | "c/interviews_id_detail"
  | "c/remotes_id"
  | "e/assessment_id_detail"
  | "e/evaluation_metric"
  | "e/evaluations"
  | "e/evaluations_groups_id"
  | "e/home"
  | "e/interview-groups_create"
  | "e/interview-groups_id_update"
  | "e/interview-groups_list"
  | "e/interviews"
  | "e/interviews_create"
  | "e/interviews_id"
  | "e/interviews_id_detail"
  | "e/interviews_id_review"
  | "e/interviews_update_id"
  | "e/invitations_id"
  | "e/questions"
  | "e/questions_challenge_create"
  | "e/questions_challenge_id"
  | "e/questions_challenge_id_update"
  | "e/questions_freepad_create"
  | "e/questions_freepad_id"
  | "e/questions_freepad_id_update"
  | "e/questions_project_id"
  | "e/questions_quiz_create"
  | "e/questions_quiz_id"
  | "e/questions_quiz_id_update"
  | "e/questions_systemdesign_id"
  | "e/remotes"
  | "e/remotes_create"
  | "e/remotes_id"
  | "e/remotes_id_detail"
  | "e/remotes_id_evaluate"
  | "e/remotes_id_update"
  | "e/settings_company"
  | "e/settings_payment"
  | "e/settings_user"
  | "e/signin"
  | "e/signup"
  | "e/templates_create"
  | "e/templates_id_update"
  | "e/templates_remote_create"
  | "e/templates_remote_id_update"
  | "t/assessment_id_detail"
  | "reset_password"
  | "auth";
