import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useRes = () => {
  const snapshot = useSnapshotState();
  return snapshot.res;
};

export const useCompanySkillTags = () => {
  const snapshot = useSnapshotState();
  return snapshot.companySkillTags;
};

export const useSkillTagIds = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.skillTagMap.keys());
};

export const useSkillTags = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.skillTagMap.values());
};

export const useTextFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.textFilter;
};

export const useNextOffset = () => {
  const snapshot = useSnapshotState();
  return snapshot.nextOffset;
};
