import { TestResult } from "@hireroo/evaluation-result-interface";
import Stack from "@mui/material/Stack";
import * as React from "react";
import * as z from "zod";

import ResultBlock from "./parts/ResultBlock/ResultBlocks";

export type ResultBlocksProps = {
  rawData: string;
  onError: (error: z.ZodError) => void;
};

const ResultBlocks: React.FC<ResultBlocksProps> = props => {
  const result = TestResult.safeParse(JSON.parse(props.rawData));
  const { onError } = props;

  React.useEffect(() => {
    if (result.error) {
      onError(result.error);
    }
  }, [onError, result.error]);

  if (!result.success) {
    return <Stack>Invalid data</Stack>;
  }

  return (
    <Stack direction="column" spacing={2} p={2}>
      {result.data.blocks.map((block, index) => (
        <ResultBlock key={`block-${index}`} {...block} />
      ))}
    </Stack>
  );
};

ResultBlocks.displayName = "ResultBlocks";

export default ResultBlocks;
