import DiffEditor, { DiffEditorProps } from "@hireroo/code-editor/react/v2/DiffEditor";
import type * as Interface from "@hireroo/evaluation-result-interface";
import Box from "@mui/material/Box";
import * as React from "react";

export type TextDiffViewerProps = Interface.TextDiffViewer["value"];

const TextDiffViewer: React.FC<TextDiffViewerProps> = props => {
  const diffEditorProps: DiffEditorProps = {
    original: props.original,
    originalModelPath: "text-diff-original",
    modified: props.modified,
    modifiedModelPath: "text-diff-modified",
    style: {
      height: 400,
    },
  };
  return (
    <Box>
      <React.Suspense>
        <DiffEditor key="diff-editor" {...diffEditorProps} />
      </React.Suspense>
    </Box>
  );
};

TextDiffViewer.displayName = "TextDiffViewer";

export default TextDiffViewer;
