import { useTranslation } from "@hireroo/i18n";
import { SkillTagSearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../../../modules/SearchForm/SearchForm";

const StyledSearchForm = styled(SearchForm)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p8,
  "& .MuiInputBase-input": {
    padding: "0px",
  },
  padding: theme.spacing(1),
  borderRadius: "8px",
}));

export type TextFilterProps = Pick<SearchFormProps["textFilter"], "placeholder"> & {
  onSubmit: SubmitHandler<SkillTagSearchForm.SkillTagSearchFormSchema>;
};

const TextFilter: React.FC<TextFilterProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateSchema = SkillTagSearchForm.useSkillTagSearchForm();
  const methods = useForm<SkillTagSearchForm.SkillTagSearchFormSchema>({
    resolver: zodResolver(validateSchema),
    mode: "onSubmit",
    defaultValues: {
      textFilter: "",
    },
  });
  const { onSubmit, ...rest } = props;

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      onSubmit(methods.getValues());
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onSubmit]);

  const searchFormProps: SearchFormProps = {
    textFilter: {
      ...rest,
      name: "textFilter",
      variant: "standard",
      InputProps: {
        sx: {
          fontSize: "14px",
        },
        disableUnderline: true,
        startAdornment: (
          <Box mr={1} display="flex" alignItems="center">
            <SearchIcon fontSize="small" htmlColor={theme.palette["Gray/Shades"].p56} />
          </Box>
        ),
      },
      placeholder: `${t("タグを検索")} ${t("関連するタグも表示されます。")}`,
    },
    kind: "SINGLE_LINE",
    fields: [],
  };
  return (
    <FormProvider {...methods}>
      <StyledSearchForm {...searchFormProps} />
    </FormProvider>
  );
};

TextFilter.displayName = "TextFilter";

export default TextFilter;
