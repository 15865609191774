import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useSkillTag = () => {
  return z.object({
    value: z.string(),
    /**
     * This filed is used for display only
     */
    label: z.string(),
  });
};

export const useSkillMapFormSchema = () => {
  const { t } = useTranslation();
  const skillTag = useSkillTag();
  return z.object({
    skillTags: z.array(skillTag).min(1, t("スキルタグを1つ以上選択してください")),
  });
};

export type SkillMapFormSchema = z.infer<ReturnType<typeof useSkillMapFormSchema>>;
