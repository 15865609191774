import { AddResumeFilesSchema } from "@hireroo/validator";

export const assignAddResumeFilesRequestFormData = (formData: FormData, args: AddResumeFilesSchema.AddResumeFilesRequestSchema): FormData => {
  args.resumeMetas.forEach((resumeMeta, index) => {
    formData.set(`resumeMetas[${index}][name]`, resumeMeta.name);
    formData.set(`resumeMetas[${index}][fileId]`, resumeMeta.fileId);
  });
  formData.set(`resumeId`, args.resumeId);

  return formData;
};
