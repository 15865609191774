import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import BackgroundBox from "./parts/BackgroundBox/BackgroundBox";
import Heading from "./parts/Heading/Heading";
import PieChart from "./parts/PieChart/PieChart";
import ScoreLabel from "./parts/ScoreLabel/ScoreLabel";
import StatusMessage from "./parts/StatusMessage/StatusMessage";

const WIDTH = 180;
const HEIGHT = 200;

const Wrapper = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "fit-content",
  overflow: "hidden",
}));
const StyledBackgroundBox = styled(BackgroundBox)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: WIDTH,
  height: HEIGHT,
  zIndex: 1,
  borderRadius: "16px",
}));

const ContentWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  width: WIDTH,
  height: HEIGHT,
  display: "flex",
  justifyContent: "center",
  borderRadius: "16px",
  zIndex: 2,
}));

const CenteringWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: WIDTH - 32,
  height: WIDTH,
  position: "relative",
}));

const OverwrapArea = styled(Box)(() => ({
  width: WIDTH,
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

export type MatchingRatePieChartProps = {
  matchingRate: number;
  displayArea: DisplayArea;
};

type ErrorView = {
  kind: "ERROR";
  message: string;
};

type ScoringView = {
  kind: "ANALYZING";
};

type ScoreOnlyView = {
  kind: "MATCHING_RATE";
};

type DisplayArea = ScoringView | ScoreOnlyView | ErrorView;

const MatchingPieChart: React.FC<MatchingRatePieChartProps> = props => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledBackgroundBox />
      <ContentWrapper direction="column" position="relative" spacing={2}>
        <Heading />
        <CenteringWrapper>
          <OverwrapArea>
            <PieChart value={props.matchingRate} />
          </OverwrapArea>
          {props.displayArea.kind === "MATCHING_RATE" && (
            <OverwrapArea>
              <ScoreLabel score={props.matchingRate} />
            </OverwrapArea>
          )}
          {props.displayArea.kind === "ERROR" && (
            <OverwrapArea>
              <StatusMessage kind="ERROR" message={props.displayArea.message} />
            </OverwrapArea>
          )}

          {props.displayArea.kind === "ANALYZING" && (
            <OverwrapArea>
              <StatusMessage kind="INFO" message={t("採点中")} />
            </OverwrapArea>
          )}
        </CenteringWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

MatchingPieChart.displayName = "ScorePieChart";

export default MatchingPieChart;
