import { useQuestionVariantMap } from "@hireroo/app-definition/question";
import { useEnabledLeakScore } from "@hireroo/app-helper/feature";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { formatScore } from "@hireroo/formatter/score";
import * as Time from "@hireroo/formatter/time";
import { useCurrentLanguage, useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

const { QuestionsTableStore } = RemotesId;

const FieldValues = {
  CREATED_AT_DESCENDING: "CREATED_AT_DESCENDING",
  CREATED_AT_ASCENDING: "CREATED_AT_ASCENDING",
} as const;
type FieldValue = (typeof FieldValues)[keyof typeof FieldValues];

export type GenerateQuestionsTablePropsArgs = {
  onSelectQuestion: (question: RemotesId.QuestionsTableStore.NormalizedQuestion) => void;
};

type RowProps = Widget.QuestionTableForRemoteProps["rows"][0];

export const useGenerateProps = (args: GenerateQuestionsTablePropsArgs): Widget.QuestionTableForRemoteProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useCurrentLanguage();
  const langCode = useLanguageCode();
  const questions = QuestionsTableStore.useQuestions();
  const hiddenQuestionSet = QuestionsTableStore.useHiddenQuestionSet();
  const questionVariantMap = useQuestionVariantMap();

  const enabledLeakScore = useEnabledLeakScore();

  const rows = React.useMemo(
    () =>
      questions
        .filter(question => !hiddenQuestionSet.has(`${question.liveCodingQuestionType}-${question.id}-${question.version}`))
        .map((question): RowProps => {
          return {
            id: `${question.questionVariant}-${question.id}-${question.version}`,
            onClick: () => {
              args.onSelectQuestion(question);
            },
            title: [`${question.id.toString()}.`, resolveLanguage(question, lang, "title")].join(" "),
            variant: questionVariantMap[question.questionVariant],
            difficulty: question.difficulty,
            isOfficial: question.isOfficial,
            correctnessRate: formatScore(question.accuracyRate ?? 0),
            numUses: question.numUses,
            leakScoreChip: question.leakScore
              ? {
                  level: question.leakScore.level,
                  score: formatScore(question.leakScore.score),
                }
              : undefined,
            showLeakScore: enabledLeakScore,
            expectedTimelimit: question.timeLimitSeconds ? Time.formatSeconds(question.timeLimitSeconds ?? 0, langCode) : "-",
            mark: "NONE",
          };
        }),
    [args, hiddenQuestionSet, lang, langCode, questionVariantMap, questions, enabledLeakScore],
  );

  const searchQuery = QuestionsTableStore.useSearchQuery();
  const count = QuestionsTableStore.useCount();

  return {
    rows: rows,
    resultText: t2("SearchResultCount", { count: count }),
    sortField: {
      options: [
        {
          displayName: t("作成日時が古い順"),
          value: FieldValues.CREATED_AT_DESCENDING,
        },
        {
          displayName: t("作成日時が新しい順"),
          value: FieldValues.CREATED_AT_ASCENDING,
        },
      ],
      onChange: value => {
        const isDescending = (value as FieldValue) === "CREATED_AT_DESCENDING";
        QuestionsTableStore.updateSearchQuery({
          ...searchQuery,
          filters: {
            ...searchQuery.filters,
            difficulties: [...searchQuery.filters.difficulties],
            questionVariants: [...searchQuery.filters.questionVariants],
            statuses: [...searchQuery.filters.statuses],
            skillTagIds: searchQuery.filters.skillTagIds.slice(),
          },
          isDescending,
          sortMethod: "CREATED_AT",
        });
      },
    },
    showLeakScore: enabledLeakScore,
    showDifficulty: true,
    pagination: {
      count: count,
      rowsPerPage: searchQuery.size,
      onRowsPerPageChange: event => {
        const newSize = Math.trunc(Number(event.target.value));
        QuestionsTableStore.updatePageSize(newSize);
      },
      page: searchQuery.page,
      onPageChange: (_, newPage) => {
        QuestionsTableStore.updatePage(newPage);
      },
    },
  };
};
