import { useTranslation } from "@hireroo/i18n";
import { ResumeDocumentForm } from "@hireroo/validator";
import * as React from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";

import LoadingContent from "../../ms-components/Resume/LoadingContent/LoadingContent";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import { Step, useResumeDocumentUploadDialogContext } from "./Context";
import OptionalContent, { OptionalContentProps } from "./parts/OptionalContent/OptionalContent";
import ResumeDocumentTable, { ResumeDocumentTableProps } from "./parts/ResumeDocumentTable/ResumeDocumentTable";

export {
  ResumeDocumentUploadDialogContext,
  ResumeDocumentUploadDialogProvider,
  type ResumeDocumentUploadDialogProviderProps,
  useResumeDocumentUploadDialogContext,
} from "./Context";

export type ResumeDocumentUploadDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose">;
  onSubmit: SubmitHandler<ResumeDocumentForm.ResumeDocumentFormSchema>;
  optionalContent: OptionalContentProps;
  documentTable: ResumeDocumentTableProps;
  loading: boolean;
};

const ResumeDocumentUploadDialog: React.FC<ResumeDocumentUploadDialogProps> = props => {
  const { t } = useTranslation();
  const { step, updateStep, title } = useResumeDocumentUploadDialogContext();
  const methods = useFormContext<ResumeDocumentForm.ResumeDocumentFormSchema>();
  const { onSubmit } = props;
  const resumeDocuments = methods.watch("resumeDocuments");

  const yesButtonMap: Record<Step, string> = {
    DOCUMENT_LIST: t("次へ"),
    OPTIONAL: t("アップロード"),
  };
  const noButtonMap: Record<Step, string> = {
    DOCUMENT_LIST: t("キャンセル"),
    OPTIONAL: t("戻る"),
  };
  const ContentMap: Record<Step, React.ReactNode> = {
    DOCUMENT_LIST: <ResumeDocumentTable {...props.documentTable} />,
    OPTIONAL: <OptionalContent {...props.optionalContent} />,
  };

  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    title: title.length > 0 ? title : t("書類をアップロード"),
    disableEnter: true,
    yesButton: yesButtonMap[step]
      ? {
          children: yesButtonMap[step],
          onClick: async () => {
            if (step === "OPTIONAL") {
              await methods.handleSubmit(onSubmit)();
            } else {
              const isValid = await methods.trigger("resumeDocuments");
              if (isValid) {
                updateStep("OPTIONAL");
              }
            }
          },
          disabled: props.loading || (step === "DOCUMENT_LIST" && resumeDocuments.length === 0),
          color: "secondary",
        }
      : undefined,
    noButton: noButtonMap[step]
      ? {
          children: noButtonMap[step],
          onClick: () => {
            if (step === "OPTIONAL") {
              updateStep("DOCUMENT_LIST");
            } else {
              props.dialog.onClose?.();
            }
          },
          disabled: props.loading,
          variant: "text",
          color: "outline-only",
        }
      : undefined,
    optionalContent: {
      sx: {
        padding: theme => theme.spacing(2, 3),
      },
    },
  };
  return <DialogWithCloseIcon {...dialog}>{props.loading ? <LoadingContent /> : ContentMap[step]}</DialogWithCloseIcon>;
};

ResumeDocumentUploadDialog.displayName = "ResumeDocumentUploadDialog";

export default ResumeDocumentUploadDialog;
