import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { SkillMapForm } from "@hireroo/validator";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";

import SkillTagButton, { SkillTagButtonProps } from "../../../../ms-components/SkillTag/SkillTagButton/SkillTagButton";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p8,
  borderRadius: "8px",
}));

const StyledTagsWrapper = styled(Stack)(({ theme }) => ({
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  overflowY: "auto",
  width: "100%",
}));

export type SkillMapSettingsFormProps = {
  onSubmit: SubmitHandler<SkillMapForm.SkillMapFormSchema>;
  updateButton: Pick<ButtonProps, "disabled">;
};

const SkillMapSettingsForm: React.FC<SkillMapSettingsFormProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const methods = useFormContext<SkillMapForm.SkillMapFormSchema>();
  const skillTagFields = methods.watch("skillTags");

  const skillTags: SkillTagButtonProps[] = skillTagFields.map(field => {
    return {
      status: "SELECTED",
      label: field.label,
      onClick: () => {
        methods.setValue(
          "skillTags",
          skillTagFields.filter(tag => field.value !== tag.value),
        );
      },
    };
  });
  const updateButton: ButtonProps = {
    ...props.updateButton,
    onClick: () => {
      methods.handleSubmit(props.onSubmit)();
    },
    variant: "contained",
    color: "secondary",
    size: "small",
    children: t("更新"),
  };
  return (
    <StyledStack p={2} spacing={2}>
      <Stack spacing={2}>
        <Typography fontSize={12} color="textSecondary">
          {t2("SelectedItem", { count: skillTagFields.length })}
        </Typography>
        <StyledTagsWrapper direction="row">
          {skillTags.length === 0 && (
            <Typography fontSize={14} color="textSecondary">
              {t("スキルタグを選択してください。")}
            </Typography>
          )}
          {skillTags.map(skillTag => (
            <SkillTagButton key={`${skillTag.label}`} {...skillTag} />
          ))}
        </StyledTagsWrapper>
      </Stack>
      <Stack direction="row" justifyContent="end" alignItems="end">
        <Button {...updateButton} />
      </Stack>
    </StyledStack>
  );
};

SkillMapSettingsForm.displayName = "SkillMapSettingsForm";

export default SkillMapSettingsForm;
