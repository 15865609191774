import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ResumeListContainerProps = {};

const ResumeListContainer: React.FC<ResumeListContainerProps> = () => {
  const resumeListProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ResumeList {...resumeListProps} />
    </ErrorBoundary>
  );
};

ResumeListContainer.displayName = "ResumeListContainer";

export default withErrorBoundary(ResumeListContainer, {});
