import ErrorIcon from "@mui/icons-material/Error";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

type Color = "PRIMARY" | "GRAY";

export type StatusProps = {
  text: string;
  color: Color;
  errorMessage?: string;
};

const Status: React.FC<StatusProps> = props => {
  const theme = useTheme();

  const colorMap: Record<Color, string> = {
    PRIMARY: theme.palette.primary.main,
    GRAY: theme.palette.text.disabled,
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip label={props.text} size="small" sx={{ backgroundColor: colorMap[props.color], color: theme.palette.common.black }} />
      {props.errorMessage && (
        <Tooltip title={props.errorMessage}>
          <ErrorIcon color="error" fontSize="small" />
        </Tooltip>
      )}
    </Stack>
  );
};

Status.displayName = "Status";

export default Status;
