import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Chip from "@mui/material/Chip";
import { lightBlue, orange, purple, red } from "@mui/material/colors";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

type LeakScoreLevel = Exclude<LeakScoreChipProps["level"], "NONE">;

const fontColorMap: Record<LeakScoreLevel, string> = {
  LOW: "#FFFFFF",
  MEDIUM: "#000000",
  HIGH: "#FFFFFF",
  CRITICAL: "#FFFFFF",
};

const backgroundColorMap: Record<LeakScoreLevel, string> = {
  LOW: lightBlue["700"],
  MEDIUM: orange["500"],
  HIGH: red["600"],
  CRITICAL: purple["700"],
};

const displayMap: Record<LeakScoreLevel, string> = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  CRITICAL: "Critical",
};

const StyledChip = styled(Chip)(() => ({
  lineHeight: "14px",
  height: "16px",
  padding: "1px 4px 1px 4px",
  fontSize: "10px",
  ".MuiChip-label": {
    padding: 0,
  },
}));

type Reason = "NO_DATA" | "NOT_SUPPORTED";

export type LeakScoreChipProps =
  | {
      level: "NONE";
      reason: Reason;
    }
  | {
      level: "LOW" | "MEDIUM" | "HIGH" | "CRITICAL";
      score: number;
    };

const LeakScoreChip: React.FC<LeakScoreChipProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const theme = useTheme();

  if (props.level === "NONE") {
    const titleMap: Record<Reason, TooltipProps["title"]> = {
      NO_DATA: t("データ不足のためリークスコアが算出されていません。"),
      NOT_SUPPORTED: t("この問題形式はリークスコアの計算に対応していません。"),
    };
    return (
      <Tooltip title={titleMap[props.reason]}>
        <span>-</span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={t2("LeakScoreChip", { score: props.score })}
      PopperProps={{ sx: { zIndex: theme.zIndex.drawer + theme.zIndex.appBar + 1 } }}
    >
      <StyledChip label={displayMap[props.level]} sx={{ color: fontColorMap[props.level], backgroundColor: backgroundColorMap[props.level] }} />
    </Tooltip>
  );
};

LeakScoreChip.displayName = "LeakScoreChip";

export default LeakScoreChip;
