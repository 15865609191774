export const encodeFirebaseKey = (filePath: string): string => {
  return btoa(filePath).replace(/\//g, "_").replace(/\+/g, "-").replace(/=/g, "");
};

export const decodeFirebaseKey = (safePath: string): string => {
  try {
    const base64 = safePath.replace(/_/g, "/").replace(/-/g, "+");
    return atob(base64);
  } catch (error) {
    console.error(`Path : ${safePath}`);
    throw error;
  }
};
