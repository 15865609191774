import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ResumeSearchAreaContainerProps = {};

const ResumeSearchAreaContainer: React.FC<ResumeSearchAreaContainerProps> = () => {
  const resumeSearchAreaProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ResumeSearchArea {...resumeSearchAreaProps} />
    </ErrorBoundary>
  );
};

ResumeSearchAreaContainer.displayName = "ResumeSearchAreaContainer";

export default withErrorBoundary(ResumeSearchAreaContainer, {});
