import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";

export type DetailPageHeaderProps = {
  title: string;
  evaluateButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  moreButton: Omit<MoreButtonProps, "variant">;
};

const DetailPageHeader: React.FC<DetailPageHeaderProps> = props => {
  const { t } = useTranslation();
  const evaluateButton: ButtonWithTooltipProps | undefined = props.evaluateButton
    ? {
        ...props.evaluateButton,
        children: t("評価"),
        variant: "contained",
        size: "small",
      }
    : undefined;
  return (
    <Stack px={3} pt={2} direction="row" justifyContent="space-between" alignItems="center">
      <Typography fontSize={20} fontWeight={700}>
        {props.title}
      </Typography>

      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Box>{evaluateButton && <ButtonWithTooltip {...evaluateButton} />}</Box>
        <MoreButton {...props.moreButton} variant="vertical" />
      </Stack>
    </Stack>
  );
};

DetailPageHeader.displayName = "DetailPageHeader";

export default DetailPageHeader;
