import * as Graphql from "@hireroo/graphql/client/urql";
import { proxyMap, proxySet } from "valtio/utils";

import { State } from "./types";

export const initialFilters: Graphql.Filters = {
  difficulties: [],
  questionVariants: [],
  statuses: [],
  titleJa: "",
  titleEn: "",
  skillTagIds: [],
};
export const defaultState: Readonly<State> = {
  isDescending: false,
  page: 0,
  size: 50,
  sortMethod: Graphql.SortMethod.Unknown,
  filters: initialFilters,
  skillTagState: {
    res: null,
    currentSkillTagIdsSet: proxySet(),
    skillTagSources: proxyMap(),
    nextOffset: 0,
    textFilter: "",
  },
};

export const SKILL_TAG_SIZE = 40;
