import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { useTranslation } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { ContentApi, getContentApiClient } from "@hireroo/rest-api/client";
import * as Sentry from "@sentry/react";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import PDFViewerDialogContainer, { PDFViewerDialogContainerProps } from "./Container";

export type PDFViewerDialogFetchContainerProps = Omit<PDFViewerDialogContainerProps, "url">;

const PDFViewerDialogFetchContainer: React.FC<PDFViewerDialogFetchContainerProps> = props => {
  const { resumeItem } = props;
  const { t } = useTranslation();
  const contentApiClient = getContentApiClient();

  const [fetched, setFetched] = React.useState(false);
  const pdfUrlRef = React.useRef<string | null>(null);
  React.useEffect(() => {
    const fetchPdf = async (resumeItemId: string) => {
      try {
        const params: ContentApi.Parameter$getUploadedResumeItemFile = {
          resumeItemId: resumeItemId,
        };
        const res = await contentApiClient.getUploadedResumeItemFile({ parameter: params });
        pdfUrlRef.current = URL.createObjectURL(res);
      } catch (error) {
        Sentry.captureException(error);
        Snackbar.notify({
          message: t("PDFの取得に失敗しました。"),
          severity: "error",
        });
      } finally {
        setFetched(true);
      }
    };
    fetchPdf(resumeItem.id);

    return () => {
      if (pdfUrlRef.current) {
        URL.revokeObjectURL(pdfUrlRef.current);
        pdfUrlRef.current = null;
      }
    };
  }, [contentApiClient, resumeItem, t]);

  const loadingProps: Widget.PDFViewerDialogProps = {
    dialog: {
      open: true,
      onClose: props.onClose,
    },
    title: resumeItem.title,
    loading: true,
    dataSrc: "",
  };

  if (!fetched || !pdfUrlRef.current) {
    return <Widget.PDFViewerDialog {...loadingProps} />;
  }

  return (
    <ErrorBoundary>
      <PDFViewerDialogContainer {...props} url={pdfUrlRef.current} />
    </ErrorBoundary>
  );
};

PDFViewerDialogFetchContainer.displayName = "PDFViewerDialogFetchContainer";

export default withErrorBoundary(PDFViewerDialogFetchContainer, {});
