import { useTranslation } from "@hireroo/i18n";
import { ResumeEditorForm } from "@hireroo/validator";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import ResourceField from "../../../../modules/ResourceFieldV2/ResourceFieldV2";
import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";
import SelectField, { SelectFieldProps } from "../../../../primitive/InputControl/SelectField/SelectField";

type FieldName = keyof ResumeEditorForm.ResumeEditorFormSchema;

const ReservedFieldName = {
  NAME: "name",
  TAGS: "tags",
  VIEWERS: "viewers",
  IS_PUBLIC: "isPublic",
} as const satisfies Record<string, FieldName>;

const Subsection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: "none",
}));

const StyledSelectField = styled(SelectField)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "fit-content",
  backgroundColor: theme.palette.Other.FilledInputBG,
  ".MuiSelect-outlined": {
    border: "none",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    borderRadius: 8,
  },
  minWidth: 360,
}));

export type MainSectionProps = {
  TagField: React.ReactNode;
  ViewerField: React.ReactNode;
};

const MainSection: React.FC<MainSectionProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<ResumeEditorForm.ResumeEditorFormSchema>();
  const isPublic = methods.watch(ReservedFieldName.IS_PUBLIC);
  const titleFieldProps: InputControlTextFieldProps = {
    fullWidth: true,
    placeholder: t("灰屋 太郎"),
    helperText: t("候補者名など"),
  };

  const selectFieldProps: SelectFieldProps = {
    color: "secondary",
    variant: "outlined",
    items: [
      {
        text: t("リンクを知っているメンバーが閲覧可能"),
        value: true,
      },
      {
        text: t("指定されたメンバーのみ閲覧可能"),
        value: false,
      },
    ],
    onChange: event => {
      if (event.target.value === true) {
        methods.setValue("viewers", []);
      } else if (event.target.value === false) {
        const editorUid = methods.getValues("editorUid");
        if (editorUid) {
          methods.setValue("viewers", [{ value: { type: "EMPLOYEE", employeeId: editorUid, locked: true } }]);
        }
      }
    },
  };

  return (
    <Stack py={1.5} px={3} spacing={1.5}>
      <Subsection>
        <ResourceField label={t("名前")} kind="REQUIRED">
          <InputControlTextField name={ReservedFieldName.NAME} {...titleFieldProps} />
        </ResourceField>
      </Subsection>
      <Subsection>
        <ResourceField
          label={t("閲覧権限")}
          kind="NONE"
          help={{
            kind: "DESCRIPTION",
            text: t("閲覧権限の適用対象はインタビュー詳細ページが対象になります。"),
          }}
        >
          <Stack spacing={1}>
            <StyledSelectField name={ReservedFieldName.IS_PUBLIC} {...selectFieldProps} />
            {isPublic === false && props.ViewerField}
          </Stack>
        </ResourceField>
      </Subsection>
      <Subsection>
        <ResourceField
          label={t("タグ設定")}
          kind="NONE"
          help={{
            kind: "DESCRIPTION",
            text: t(
              "レポート作成時に自動付与されるタグを指定することができます。一覧画面での絞り込み検索やレポート画面で母集団を絞り込む際に利用することができます。",
            ),
          }}
        >
          {props.TagField}
        </ResourceField>
      </Subsection>
    </Stack>
  );
};

MainSection.displayName = "MainSection";

export default MainSection;
