import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export type ProjectTextInputDialogFormArgs = {
  existsList: string[];
};

export const useProjectTextInputDialogForm = (args: ProjectTextInputDialogFormArgs) => {
  const { t } = useTranslation();
  return z
    .object({
      value: z.string(),
    })
    .superRefine((obj, ctx) => {
      obj.value;
      if (args.existsList.includes(obj.value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_string,
          validation: "regex",
          path: ["value"],
          message: t("同じ名前のファイルまたは、ディレクトリが存在します。"),
        });
      }
    });
};

export type ProjectTextInputDialogFormSchema = z.infer<ReturnType<typeof useProjectTextInputDialogForm>>;
