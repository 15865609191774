import { useTranslation } from "@hireroo/i18n";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";

import BaseDialog, { BaseDialogProps } from "../../../primitive/BaseDialog/BaseDialog";

export type SubmitConfirmDialogProps = {
  open: boolean;
  onAccept: () => void;
  onClose: () => void;
};

const SubmitConfirmDialog: React.FC<SubmitConfirmDialogProps> = props => {
  const { t } = useTranslation();
  const handleOnClickYesButton = React.useCallback(() => {
    props.onAccept();
  }, [props]);
  const handleOnClickNoButton = React.useCallback(() => {
    props.onClose();
  }, [props]);
  const baseDialogProps: BaseDialogProps = {
    title: t("問題の提出を行います。よろしいですか？"),
    open: props.open,
    yesButton: {
      children: t("はい"),
      onClick: handleOnClickYesButton,
    },
    noButton: {
      children: t("閉じる"),
      onClick: handleOnClickNoButton,
    },
  };
  return (
    <BaseDialog {...baseDialogProps}>
      <DialogContentText
        // TODO: Write some text here
        children={""}
      />
    </BaseDialog>
  );
};

SubmitConfirmDialog.displayName = "SubmitConfirmDialog";

export default SubmitConfirmDialog;
