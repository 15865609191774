import { App, Payment } from "@hireroo/app-store/essential/talent";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

import { useGenerateAnswerSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateAnswerSectionProps";
import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateHeaderProps";
import { useGeneratePerformanceDetailSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGeneratePerformanceDetailSectionProps";
import { useGeneratePlaybackSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGeneratePlaybackSectionProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateQuestionDetailProps";
import { useGenerateWorkspaceNotificationSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useGenerateWorkspaceNotificationSectionProps";
import { useGenerateTestCaseSectionProps } from "../../../../props-factory/v2/view-domain/ProjectTestReport/useTestCaseSectionProps";
import TotalScoreRankVisualizerInitialContainer from "../../../v2/shared/TotalScoreRankVisualizer/InitialContainer";

export type GenerateProjectTestReportPropsArgs = {
  featureKind: "test" | "exam";
  testId: string;
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  reportSettings: Graphql.AssessmentReportSettings | null;
};

export const useGenerateProps = (args: GenerateProjectTestReportPropsArgs): Widget.ProjectTestReportProps => {
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const appStatus = App.useStatus();
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submissionStatus = hooks.useCurrentSubmissionStatus();
  const submission = hooks.useCurrentSubmission();
  const projectVersion = hooks.useProjectVersion();

  const headerProps = useGenerateHeaderProps(args);
  const entityScoreBoardProps = useGenerateEntityScoreBoardProps({
    ...args,
    showingTargets: ["SCORE", "RANK"],
  });
  const testCaseSectionProps = useGenerateTestCaseSectionProps({ ...args, showScore: true });
  const answerSectionProps = useGenerateAnswerSectionProps({
    ...args,
    showingTargets: isAvailableFeature(`${args.featureKind}.statics.read`) ? ["AVERAGE_VALUE"] : [],
  });
  const performanceDetailSectionProps = useGeneratePerformanceDetailSectionProps({ ...args, showScore: true });
  const playbackSectionProps = useGeneratePlaybackSectionProps({ ...args, pause: appStatus !== "INITIALIZED" });
  const workspaceNotificationSectionProps = useGenerateWorkspaceNotificationSectionProps({
    ...args,
    kindOfReview: "TALENT_REVIEW_EXAM",
  });
  const questionDetail = useGenerateQuestionDetailProps({
    entityId: args.entityId,
    showAnswer: args.reportSettings?.showAnswer ?? false,
    showArchivedMark: false,
  });

  return {
    header: headerProps,
    answerDetailSection: answerSectionProps,
    entityScoreBoard: entityScoreBoardProps,
    StatisticsContent: args.reportSettings?.showRelativeEvaluation && (
      <TotalScoreRankVisualizerInitialContainer uniqueKey={args.uniqueKey} score={submission?.totalScore ?? 0} enableRank readOnly />
    ),
    testCaseSection: submissionStatus === "EVALUATED" ? testCaseSectionProps : undefined,
    playbackSection: playbackSectionProps,
    // We support review environment only for V3 project
    workspaceNotificationSection: projectVersion === "V3" ? workspaceNotificationSectionProps : undefined,
    performanceDetailSection: submissionStatus === "EVALUATED" ? performanceDetailSectionProps : undefined,
    /**
     * talent will always hide open button
     */
    questionDetail: { ...questionDetail, openButton: undefined },
  };
};
