import type firebase from "firebase/compat/app";
import type firebaseAdmin from "firebase-admin";

import * as RdbKey from "./rdbKey";
import * as Validator from "./validator";

export interface Logger {
  error: (...args: unknown[]) => void;
  debug: (...args: unknown[]) => void;
  info: (...args: unknown[]) => void;
}

export type FirebaseDataSnapshot = firebase.database.DataSnapshot | firebaseAdmin.database.DataSnapshot;
export type FirebaseDatabaseReference = firebase.database.Reference | firebaseAdmin.database.Reference;
export type FirebaseDatabase = firebase.database.Database | firebaseAdmin.database.Database;

export type HistoriesPathsModelConfig = {
  getTimestamp: () => number;
  key: string;
  logger: Logger;
  rdbBasePath: string;
  database: () => FirebaseDatabase;
};

export class HistoriesPathsModel {
  private logger: Logger;
  private ref: firebaseAdmin.database.Reference | firebase.database.Reference;
  private fileIdMap: Map<string, Validator.PathHistoryValue> = new Map();
  private getTimestamp: () => number;
  /**
   * 存在するファイルでinitializedが完了したもの
   *
   * 対象ファイルが存在しなくなったらここから削除する
   */
  private initializedFilePathsSet = new Set<string>();

  private initialized = false;
  private alreadySubscribed = false;
  private disposers: (() => void)[] = [];

  constructor(private config: HistoriesPathsModelConfig) {
    this.logger = this.config.logger;
    this.logger.info("HistoriesPathsModel initialized");
    const rdbBasePath = this.config.rdbBasePath;
    const db = this.config.database();
    this.getTimestamp = this.config.getTimestamp;
    const delimiter = rdbBasePath.match(/\/$/) ? "" : "/";
    this.ref = db.ref(`${rdbBasePath}${delimiter}${this.config.key}`);
    this.logger.info(`Reference ${this.ref.toString()}`);
  }

  public getLatestFileIdByFilepath = (filepath: string): string | null => {
    const value = this.fileIdMap.get(filepath);
    if (!value) {
      return null;
    }
    if (value.op === "DELETED") {
      return null;
    }
    return value.id;
  };

  /**
   * @param filepaths watch対象のディレクトリに存在しているファイルパスのリスト
   */
  private firstSync = async (filepaths: string[]) => {
    const snapshot = await this.ref.once("value");
    const unsafeValue = snapshot.val();
    if (!unsafeValue) {
      const tasks = filepaths.map(filepath => {
        this.update(filepath, {
          op: "EXISTS",
        });
      });
      await Promise.all(tasks);
    } else {
      const validatedValue = await Validator.PathHistories.safeParseAsync(unsafeValue);
      if (validatedValue.success) {
        const filepathsSet = this.initializedFilePathsSet;
        const filepathsInRdb = Object.keys(validatedValue.data).map(rdbKey => RdbKey.decodeFirebaseKey(rdbKey));
        const filepathsInRdbSet = new Set<string>(filepathsInRdb);
        const newCreatedFilePaths = filepaths.filter(filepath => !filepathsInRdbSet.has(filepath));
        /**
         * ローカルにしかない情報から整合性を合わせる
         */
        const alignConsistencyTasksByLocalState = newCreatedFilePaths.map(newCreatedFilePath => {
          return this.update(newCreatedFilePath, {
            op: "EXISTS",
          });
        });

        /**
         * RDBにしかない情報から整合性を合わせる
         */
        const alignConsistencyTasksByRemoteState = Object.entries(validatedValue.data).map(async ([rdbKey, operations]) => {
          const realFilepath = RdbKey.decodeFirebaseKey(rdbKey);
          const lastOperation = operations.at(-1);
          if (!lastOperation) {
            return;
          }
          const fileExistsInLocal = filepathsSet.has(realFilepath);
          if (fileExistsInLocal) {
            switch (lastOperation.op) {
              case "DELETED": {
                // ローカルには存在してるのでRDBの状態を実態と合わせる
                return await this.update(realFilepath, {
                  op: "EXISTS",
                });
              }
              case "CREATED":
              case "EXISTS":
                break;
              default:
                throw new Error(`Unsupported Operation: ${lastOperation.op satisfies never}`);
            }
          } else {
            switch (lastOperation.op) {
              case "CREATED":
              case "EXISTS": {
                // すでにローカルには存在していないのでRDBの状態を実態と合わせる
                return await this.update(realFilepath, {
                  op: "DELETED",
                });
              }
              case "DELETED": {
                break;
              }
              default:
                throw new Error(`Unsupported Operation: ${lastOperation.op satisfies never}`);
            }
          }
        });
        await Promise.all([...alignConsistencyTasksByLocalState, ...alignConsistencyTasksByRemoteState]);
      } else {
        this.logger.error(`Invalid Value: ${JSON.stringify(unsafeValue)}`);
      }
    }
  };

  private initializeFileIdMap = async (): Promise<boolean> => {
    const snapshot = await this.ref.once("value");
    const unsafeValue = snapshot.val();
    const validatedValue = await Validator.PathHistories.safeParseAsync(unsafeValue);

    if (validatedValue.success) {
      const entries = Object.entries(validatedValue.data);
      for (const [rdbKey, operations] of entries) {
        const filePath = RdbKey.decodeFirebaseKey(rdbKey);
        const latestOperation = operations.at(-1);
        if (latestOperation) {
          this.fileIdMap.set(filePath, latestOperation);
        }
      }
      return true;
    } else {
      return false;
    }
  };

  /**
   * このModelでキャッシュしている`fileIdMap`の状態をRDBの状態と同期する
   */
  private syncFileIdMapFromRealtimeDatabase = async (filepath: string): Promise<"SYNCED" | "NOT_FOUND" | "INVALID"> => {
    const key = RdbKey.encodeFirebaseKey(filepath);
    const childReference = this.ref.child(key);
    const snapshot = await childReference.once("value");
    const unsafeValue = snapshot.val();
    const validatedValue = Validator.PathHistoryValues.safeParse(unsafeValue);
    if (validatedValue.success) {
      const operations = validatedValue.data;
      const latestOperation = operations.at(-1);
      if (latestOperation) {
        this.fileIdMap.set(filepath, latestOperation);
        this.logger.info(`Synced(RDB->Worker) fileIdMap("${filepath}") ${JSON.stringify(latestOperation)}`);
        return "SYNCED";
      }
      return "NOT_FOUND";
    } else {
      return "INVALID";
    }
  };

  private createUpdateStateBySnapshot = (logName: string) => async (snapshot: FirebaseDataSnapshot) => {
    if (!snapshot.key) {
      return;
    }
    const unsafeValue = snapshot.val();
    const validatedValue = await Validator.PathHistoryValues.safeParseAsync(unsafeValue);
    if (validatedValue.success) {
      const operations = validatedValue.data;
      const lastOperation = operations.at(-1);
      const filepath = RdbKey.decodeFirebaseKey(snapshot.key);
      if (lastOperation) {
        this.fileIdMap.set(filepath, lastOperation);
        this.logger.info(`Sync(RDB->Worker) [${logName}] by Last Operation for "${filepath}" | ${JSON.stringify(lastOperation)}`);
        if (lastOperation.op === "DELETED") {
          this.initializedFilePathsSet.delete(filepath);
        }
      }
    } else {
      this.logger.error(`Invalid Snapshot Value ${JSON.stringify(unsafeValue)}`);
    }
  };

  private startWatchFilePathChange = () => {
    if (this.alreadySubscribed) {
      return;
    }
    this.alreadySubscribed = true;
    const handleChildAdded = this.createUpdateStateBySnapshot("child_added");
    const handleChildChanged = this.createUpdateStateBySnapshot("child_changed");
    this.ref.on("child_added", handleChildAdded);
    this.ref.on("child_changed", handleChildChanged);
    this.disposers.push(() => {
      this.ref.off("child_added", handleChildAdded);
      this.ref.off("child_changed", handleChildChanged);
      this.alreadySubscribed = false;
    });
  };

  /**
   * Dry Run仕様
   */
  public initialize = async (filepaths: string[]) => {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    const unlockedFilepaths = filepaths.filter(filepath => !this.initializedFilePathsSet.has(filepath));
    for (const filepath of unlockedFilepaths) {
      this.initializedFilePathsSet.add(filepath);
    }
    await this.firstSync(unlockedFilepaths);
    const restored = await this.initializeFileIdMap();
    if (!restored) {
      const tasks = unlockedFilepaths.map(filepath => {
        return this.syncFileIdMapFromRealtimeDatabase(filepath);
      });
      await Promise.all(tasks);
    }
    this.startWatchFilePathChange();
  };

  public update = async (filepath: string, partialValue: Pick<Validator.PathHistoryValue, "op">) => {
    const key = RdbKey.encodeFirebaseKey(filepath);
    const childReference = this.ref.child(key);

    const oncePayload = await childReference.once("value");
    const unsafeValue = oncePayload.val();
    const result = await Validator.PathHistoryValues.safeParseAsync(unsafeValue);

    if (partialValue.op === "DELETED") {
      this.initializedFilePathsSet.delete(filepath);
    }

    this.logger.debug(`Sync(Worker->RDB) "${filepath}" (${childReference.toString()}), ${JSON.stringify(partialValue)}`);
    if (result.success) {
      const lastData = result.data.at(-1);
      const skipSameOperation = partialValue.op === lastData?.op;
      if (skipSameOperation) {
        await this.syncFileIdMapFromRealtimeDatabase(filepath);
        return;
      }
      const next = result.data.length;
      const value: Validator.PathHistoryValue = {
        ...partialValue,
        ts: this.getTimestamp(),
        id: next.toString(),
      };
      await childReference.child(next.toString()).transaction(
        () => {
          return value;
        },
        (error, completed) => {
          if (error) {
            console.error(error);
          } else if (completed) {
            this.logger.debug(`Synced(Worker->RDB) "${filepath}" (${childReference.toString()}), ${JSON.stringify(value)}`);
          }
        },
      );
    } else {
      const value: Validator.PathHistoryValue = {
        ...partialValue,
        ts: this.getTimestamp(),
        id: "0", // first
      };
      const typedValue: Validator.PathHistoryValues = [value];
      await childReference.transaction(
        () => {
          return typedValue;
        },
        (error, completed) => {
          if (error) {
            console.error(error);
          } else if (completed) {
            this.logger.debug(`Synced(Worker->RDB) "${filepath}" ${childReference.toString()}, ${JSON.stringify(typedValue)}`);
          }
        },
      );
    }
  };

  public dispose = () => {
    for (const dispose of this.disposers) {
      dispose();
    }
  };
}
