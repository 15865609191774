import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import ResumeDocumentAddDialogContainer, { ResumeDocumentAddDialogContainerProps } from "./Container";

export type ResumeDocumentAddDialogInitialContainerProps = ResumeDocumentAddDialogContainerProps;

const ResumeDocumentAddDialogInitialContainer: React.FC<ResumeDocumentAddDialogInitialContainerProps> = props => {
  return (
    <ErrorBoundary>
      <Widget.ResumeDocumentAddDialogProvider>
        <ResumeDocumentAddDialogContainer {...props} />
      </Widget.ResumeDocumentAddDialogProvider>
    </ErrorBoundary>
  );
};

ResumeDocumentAddDialogInitialContainer.displayName = "ResumeDocumentAddDialogInitialContainer";

export default withErrorBoundary(ResumeDocumentAddDialogInitialContainer, {});
