import type * as Interface from "@hireroo/evaluation-result-interface";
import Box from "@mui/material/Box";
import * as React from "react";

export type CarouselProps = Interface.Carousel["value"];

const Carousel: React.FC<CarouselProps> = _props => {
  // TODO: Implement Carousel component
  return <Box></Box>;
};

Carousel.displayName = "Carousel";

export default Carousel;
