import * as Def from "@hireroo/app-definition/resume";
import { ResumesIdDetail } from "@hireroo/app-store/page/e/resumes_id_detail";
import { unixTimeSecondsToFormat } from "@hireroo/formatter/time";
import { useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type AnalyzedSection = Exclude<Widget.ResumeDetailProps["report"]["analyzedSection"], undefined>;

type EducationItem = AnalyzedSection["educationItems"][number];
type CareerItem = AnalyzedSection["careerItems"][number];

export const useGenerateAnalyzedSectionProps = (): Widget.ResumeDetailProps["report"]["analyzedSection"] => {
  const { t: t2 } = useTranslationWithVariable();
  const resume = ResumesIdDetail.useResume();
  const resumeEmploymentMap = Def.useResumeEmploymentMap();
  const degreeMap = Def.useResumeDegreeMap();
  const educationItems = React.useMemo((): EducationItem[] => {
    return resume.educations.map((education): EducationItem => {
      const startYearMonth = education.startYearMonth ? `${education.startYearMonth.year}/${education.startYearMonth.month}` : null;
      const endYearMonth = education.endYearMonth ? `${education.endYearMonth.year}/${education.endYearMonth.month}` : null;
      return {
        title: education.institutionName,
        description: [degreeMap[education.degree], education.major].filter(v => !!v).join(" / "),
        duration: [startYearMonth, endYearMonth].filter(v => !!v).join(" - "),
      };
    });
  }, [degreeMap, resume.educations]);
  const careerItems: CareerItem[] = React.useMemo((): CareerItem[] => {
    return resume.workExperiences.map((workExperience): CareerItem => {
      const startYearMonth = workExperience.startYearMonth
        ? `${workExperience.startYearMonth.year}/${workExperience.startYearMonth.month}`
        : null;
      const endYearMonth = workExperience.endYearMonth ? `${workExperience.endYearMonth.year}/${workExperience.endYearMonth.month}` : null;
      return {
        title: workExperience.companyName,
        description: undefined,
        subSection: {
          items: workExperience.positions.map(position => {
            const startYearMonth = position.startYearMonth ? `${position.startYearMonth.year}/${position.startYearMonth.month}` : null;
            const endYearMonth = position.endYearMonth ? `${position.endYearMonth.year}/${position.endYearMonth.month}` : null;
            const employmentType = resumeEmploymentMap[position.employmentType];
            return {
              title: [position.positionName, employmentType].filter(v => !!v).join(" / "),
              duration: [startYearMonth, endYearMonth].filter(v => !!v).join(" - "),
            };
          }),
        },
        duration: [startYearMonth, endYearMonth].filter(v => !!v).join(" - "),
      };
    });
  }, [resume.workExperiences, resumeEmploymentMap]);
  const companySkillTagSet = new Set(resume.companySkillTags.map(tag => tag.skillTagId));
  const resumeSkillTagSet = new Set(resume.resumeSkillTags.map(tag => tag.skillTagId));
  return {
    extractedSection: {
      matchedSkillTags: resume.resumeSkillTags.filter(tag => companySkillTagSet.has(tag.skillTagId)).map(tag => ({ label: tag.skillTagId })),
      unmatchedSkillTags: resume.resumeSkillTags.filter(tag => !companySkillTagSet.has(tag.skillTagId)).map(tag => ({ label: tag.skillTagId })),
      skillMap: {
        matchedSkillTags: resume.companySkillTags.filter(tag => resumeSkillTagSet.has(tag.skillTagId)).map(tag => ({ label: tag.skillTagId })),
        unmatchedSkillTags: resume.companySkillTags
          .filter(tag => !resumeSkillTagSet.has(tag.skillTagId))
          .map(tag => ({ label: tag.skillTagId })),
        skillMapDate: resume.calculatedAtSeconds
          ? t2("SkillMapCalculatedDate", { date: unixTimeSecondsToFormat(resume.calculatedAtSeconds) })
          : "",
      },
    },
    careerItems: careerItems,
    educationItems: educationItems,
    externalLinks: resume.externalLinks.map(link => ({ href: link })),
  };
};
