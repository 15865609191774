import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";

const StyledButtonWithTooltip = styled(ButtonWithTooltip)(({ theme }) => ({
  borderColor: theme.palette.Other.Divider,
}));

const StyledEmployeeLayout = styled(EmployeeLayout)`
  overflow: hidden;
`;
const StyledHeaderStack = styled(Stack)(() => ({
  height: 36,
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

export type ResumeListProps = {
  layout: EmployeeLayoutProps;
  skillMapButton: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  uploadButton: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  SearchArea: React.ReactNode;
  List: React.ReactNode;
  Dialog: React.ReactNode;
};

const ResumeList: React.FC<ResumeListProps> = props => {
  const { t } = useTranslation();
  const skillMapSettingsButton: ButtonWithTooltipProps = {
    ...props.skillMapButton,
    children: t("スキルマップ設定"),
    variant: "outlined",
    startIcon: "SETTING",
    color: "outline-only",
  };
  const uploadButton: ButtonWithTooltipProps = {
    ...props.uploadButton,
    variant: "contained",
    children: t("書類をアップロード"),
    startIcon: "UPLOAD",
    color: "primary",
  };
  return (
    <StyledEmployeeLayout {...props.layout}>
      <Box p={3}>
        <StyledHeaderStack direction="row" mb={2}>
          <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5" fontWeight={700} noWrap>
                {t("書類選考一覧")}
              </Typography>
            </Box>

            <Stack direction="row" spacing={2}>
              <StyledButtonWithTooltip {...skillMapSettingsButton} />
              <ButtonWithTooltip {...uploadButton} />
            </Stack>
          </Stack>
        </StyledHeaderStack>

        <Stack spacing={2}>
          {props.SearchArea}
          {props.List}
          {props.Dialog}
        </Stack>
      </Box>
    </StyledEmployeeLayout>
  );
};

ResumeList.displayName = "ResumeList";

export default ResumeList;
