import { App } from "@hireroo/app-store/essential/employee";
import { SkillMapSettings } from "@hireroo/app-store/widget/e/SkillMapSettings";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import SkillTagListContainer, { SkillTagListContainerProps } from "./Container";

/**
 * DEPTH: 1 fixed because user can't search skill tag with depth
 * and if the depth size is big, the process will be slow.
 */
const DEPTH = 1;

export type SkillTagListFetchContainerProps = {};

const SkillTagListFetchContainer: React.FC<SkillTagListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const textFilter = SkillMapSettings.useTextFilter();
  const nextOffset = SkillMapSettings.useNextOffset();
  const [result, refresh] = Graphql.useListHierarchicalSkillTagsForSkillMapSettingsQuery({
    variables: {
      input: {
        name: textFilter,
        depth: DEPTH,
        limit: SkillMapSettings.FETCH_SIZE,
        offset: nextOffset,
        withCount: true,
      },
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data) {
      SkillMapSettings.updateRes(result.data.hierarchicalSkillTags);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: SkillTagListContainerProps = {
    loading: result.fetching,
  };

  return <SkillTagListContainer {...containerProps} />;
};

SkillTagListFetchContainer.displayName = "SkillTagListFetchContainer";

export default withErrorBoundary(SkillTagListFetchContainer, {});
