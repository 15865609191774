import { useTranslation } from "@hireroo/i18n";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type LoadingContentProps = {};

const LoadingContent: React.FC<LoadingContentProps> = _props => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" justifyContent="center" spacing={2}>
      <CircularProgress color="secondary" />
      <Typography>{`${t("ファイルアップロード中")}...`}</Typography>
    </Stack>
  );
};

LoadingContent.displayName = "LoadingContent";

export default LoadingContent;
