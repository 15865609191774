import * as z from "zod";

export const useFileField = () => {
  return z.object({
    id: z.string(),
    fileName: z.string(),
    size: z.number(),
    rawFile: z.custom<File>(),
  });
};
export type FileFieldSchema = z.infer<ReturnType<typeof useFileField>>;

export const useResumeDocumentForm = () => {
  const file = useFileField();
  return z.object({
    resumeDocuments: file.array(),
  });
};

export type ResumeDocumentFormSchema = z.infer<ReturnType<typeof useResumeDocumentForm>>;
