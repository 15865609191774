import * as z from "zod";

const useTags = () => {
  return z
    .object({
      displayName: z.string(),
    })
    .array();
};
const useSkillTags = () => {
  return z
    .object({
      value: z.string(),
    })
    .array();
};
export type SkillTags = z.infer<ReturnType<typeof useSkillTags>>;

const useMatchingRate = () => {
  return z.object({
    min: z.number(),
    max: z.number(),
  });
};

export const Status = z.union([z.literal("CALCULATING"), z.literal("CALCULATED"), z.literal("FAILED"), z.literal("EVALUATED")]);
export type StatusSchema = z.infer<typeof Status>;

export const Result = z.union([z.literal("PASSED"), z.literal("NOT_PASSED"), z.null()]);
export type ResultSchema = z.infer<typeof Result>;

export type Tags = z.infer<ReturnType<typeof useTags>>;

export const useResumeSearchQuery = () => {
  const matchingRate = useMatchingRate();
  const tags = useTags();
  const skillTags = useSkillTags();

  return z.object({
    skillTags,
    matchingRate,
    statuses: Status.array(),
    tags,
    result: Result,
    textFilter: z.string(),
  });
};

export type ResumeSearchQuerySchema = z.infer<ReturnType<typeof useResumeSearchQuery>>;

export const SortFieldValue = z.union([
  z.literal("created-at-descending"),
  z.literal("created-at-ascending"),
  z.literal("evaluated-at-descending"),
  z.literal("evaluated-at-ascending"),
  z.literal("matching-rate-descending"),
  z.literal("matching-rate-ascending"),
]);

export type SortFieldValue = z.infer<typeof SortFieldValue>;
