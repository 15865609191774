import { Resumes } from "@hireroo/app-store/page/e/resumes";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { startSubscribeListParams } from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type ResumeListContainerProps = {};

const ResumeListContainer: React.FC<ResumeListContainerProps> = () => {
  const resumeListProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = startSubscribeListParams();

    return () => {
      stop();
      Resumes.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.ResumeList {...resumeListProps} />
    </ErrorBoundary>
  );
};

ResumeListContainer.displayName = "ResumeListContainer";

export default withErrorBoundary(ResumeListContainer, {});
